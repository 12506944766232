// General Tile
//
// A general Avery tile (Previously "Industrial Tile") used in Avery.com and Avery Industrial modes..
//
// **NOTE:** Add tile utility classes with the `tile-avery` class.
//
// .img-fit - Constrains the image proportionally inside the bounds of the image area of the tile.
//
// Markup:
// <div class="row">
//   <div class="col-2">
//     <!-- Main Tile Element (wrap in an <a> to link) -->
//     <a class="text-decoration-none" href="#">
//       <div class="tile-avry {{modifier_class}}">
//         <div class="tile__img">
//           <picture>
//             <source srcset="https://picsum.photos/id/1064/900/" media="(max-width: 768px)">
//             <source srcset="https://picsum.photos/id/1064/800" media="(min-width: 769px)">
//             <img srcset="https://picsum.photos/id/1064/800" alt="">
//           </picture>
//         </div>
//         <div class="tile__block p-3">
//           <div class="tile-content tile-content--header h3 m-0">
//             <span>Ultra Duty GHS</span>
//           </div>
//           <div class="tile-content tile-content--crop-text-14px-4-line text-color-ind-gray mb-2">
//             <span>Here's a little description of this label. Highlight the features. Short and sweet.</span>
//           </div>
//           <div class="tile-content content-footer">
//             <span class="fnt-sze-12 lh-normal text-uppercase pr-2">Starting at</span>
//             <strong class="fnt-sze-14 lh-normal">$27.00 (USD)</strong>
//           </div>
//         </div>
//       </div>
//     </a>
//   </div>
// </div>
//
// Styleguide tiles.general-tile

/* ==========================================================================
   AVERY GENERAL TILE
   ========================================================================== */

// TODO: Phase out the `tile-avry-inds` class.
.tile-avry,
.tile-avry-inds {
  /**
   * Tile Base
   */
  @include tile-container-style($config: (
    border-radius: rem(3)
  ));
  // Note: Height minus the BS4 row/col gutter spacing. `$grid-gutter-width` is a Bootstrap 4 variable.
  height: calc(100% - #{$grid-gutter-width});
  display: flex;
  flex-direction: column;
  background: $main-white;
  // NOTE: Margin Bottom to compensate from the subtracted `grid-gutter-width` in the height [calc(100% - #{$grid-gutter-width})].
  margin-bottom: $grid-gutter-width;
  overflow: hidden;
  text-align: left;

  /**
   * Tile Image Area
   */
  .tile__img {
    max-height: none;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    align-self: auto;
    justify-content: center;
    overflow: hidden;
    position: relative;

    // Applies 4:3 ratio structure
    &:after {
      content: '';
      display: block;
      width: 100%;
      padding-top: 75%; // 4:3 (4 / 3 = 0.75)
    }

    img {
      display: block;
      height: auto;
      max-width: 100%;
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  /**
   * Tile Content Area
   */
  .tile__block {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .tile-content {
      flex: 0 0 auto;
    }

    /* Tile Block Head Styles */
    // TODO: Determine if `.content--header` is needed...
    // & .tile-content.content--header {}

    /* Tile Block Foot Styles */
    & .tile-content.content-footer {
      margin-top: auto;
    }
  }

  /**
   * Tile Specific Header Styles
   *
   * NOTE: All tiles to use these header styles
   */
  h3, .h3 {
    @include media-breakpoint-only(xs) {
      font-size: rem(16);
      letter-spacing: rem(0.18);
      line-height: 1;
    }
    @include media-breakpoint-only(sm) {
      font-size: rem(20);
      letter-spacing: rem(0.23);
      line-height: 1.2;
    }
  }
  h5, .h5 {
    @include media-breakpoint-only(xs) {
      font-size: rem(16);
      letter-spacing: rem(0.66);
      line-height: 1;
    }
  }
  h6, .h6 {
    @include media-breakpoint-only(xs) {
      font-size: rem(12);
      letter-spacing: rem(0.58);
      line-height: 1;
    }
  }
}

/* ==========================================================================
   AVERY GENERAL TILE UTILITIES - TILE IMAGE
   ========================================================================== */

// Keeps img fit within image bounds while retaining proportional sizes.
// TODO: Phase out the `tile-avry-inds` class.
.tile-avry.img-fit,
.tile-avry-inds.img-fit {
  /**
   * Tile Image Area
   */
  .tile__img {
    $img-fit-spacing: map-get($spacers, 3);

    @include media-breakpoint-up(sm) {
      height: auto;
      flex: 0 0 auto;
      padding: 0;
    }

    img {
      height: 100%;
      padding: $img-fit-spacing;

      @include media-breakpoint-up(sm) {
        max-width: none;
        max-height: 100%;
      }
    }
  }
}

// TODO: Disabled for now, as it was requested to have grid view from XS ~ XL (Above). Will see if this is needed...
// Utility Class to Switch Tile into list view for 'XS' Breakpoint
// .tile-avry.list-xs-only,
// .tile-avry-inds.list-xs-only {
//   flex-direction: row;
//   // Tablet ~ Desktop
//   @include media-breakpoint-up(sm) {
//     flex-direction: column;
//   }
//   /**
//    * Tile Image Area
//    */
//   .tile__img {
//     flex: 1 1 50%;
//     align-self: center;
//     // Tablet ~ Desktop
//     @include media-breakpoint-up(sm) {
//       flex: 0 0 auto;
//       align-self: auto;
//     }
//   }
//   /**
//    * Tile Content Area
//    */
//   .tile__block {
//     flex: 1 1 50%;
//     // Tablet ~ Desktop
//     @include media-breakpoint-up(sm) {
//       flex: 1 1 auto;
//     }
//   }
// }

/* ==========================================================================
   AVERY GENERAL TILE UTILITIES - TILE CONTENT
   ========================================================================== */

// Utility - Tile Content -- Crop
//
// Crops Tile content height. Add following classes with `.tile-content`
//
// .tile-content--crop-h3-2-line - Crops the tile content `h3` to **2 lines**.
// .tile-content--crop-h5-3-line - Crops the tile content `h5` to **3 lines**.
// .tile-content--crop-text-14px-4-line - Crops the tile content `font-size: 14px` to **4 lines**.
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide tiles.general-tile.utilties-tile-content-crop

.tile-content--crop-h3-2-line,
.tile-content--crop-h5-3-line,
.tile-content--crop-text-14px-4-line {
  overflow: hidden;
}
.tile-content--crop-h3-2-line {
  height: 50px !important;
}
.tile-content--crop-h5-3-line {
  height: 50px !important;
}
.tile-content--crop-text-14px-4-line {
  height: 62px !important;
  font-size: 14px;
  line-height: 1.1 !important;
}
