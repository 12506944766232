// Option Box
//
// **Seen In:** Template Detail Page, Help Page(s) (Contact Us Boxes)
//
// styleguide option-box

/* ==========================================================================
   COMMON OPTION-BOX STYLES
   ========================================================================== */

// Option Box - Template Detail
//
// Markup:
// <div class="option-box">
//   <div class="row">
//     <div class="col-4 col-sm-5 col-md-12">
//       <div class="option-box-img">
//         <img class="tile-img img-fluid img-fluid-height" src="https://dev-cms.avery.com/assets/svg/templates/template-details/browse.svg" />
//       </div>
//     </div>
//     <div class="col-8 col-sm-7 col-md-12 text-left text-md-center">
//       <p class="option-box-label">Browse Avery Designs</p>
//       <p class="option-box-description">Get inspired</p>
//       <button class="btn btn-avry btn-action-green">Choose a design</button>
//     </div>
//   </div>
// </div><!-- /.option-box -->
//
// styleguide option-box.template-detail

.option-box {
  .option-box-img {
    display: flex;
    height: 100px;
    align-content: center;
    justify-content: center;
    margin-bottom: rem(10);

    @include media-breakpoint-only(sm) {
      justify-content: flex-end;
    }

    & img {
      max-width: 95px;
      width: 100%;
      height: 100%;
    }
  }

  .option-box-label {
    font-size: rem(18);
    // TODO: use actual bold font.
    font-weight: 500;
    margin-bottom: rem(8);
  }
}

/* ==========================================================================
   COMMON OPTION-BOX STYLES
   ========================================================================== */


