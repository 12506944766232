/* ==========================================================================
   FILTER PAGE(S) Tile Item Group
   ========================================================================== */

.filtered-items {
  @include tile-item-group();
}

/* ==========================================================================
   FILTER PAGE(S) filter content

   NOTE: Pages that have the filter page structure, (such as: Product Filter page, Template Filter Page).
   ========================================================================== */
.filter-container.filter-container-sidebar {
  margin-top: rem(-37); // NOTE: This aligns the sidebar line with the main filter page results content.
}

.filter-container {
  .filter-set {
    font-size: rem(13);
    margin-bottom: rem(20);
  }
}
