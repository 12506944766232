/* ==========================================================================
   COMMON SWIPER STYLES

   NOTE: This file modifies base 'Swiper' Component Styles (https://www.npmjs.com/package/angular2-useful-swiper)
   ========================================================================== */

$max-sm-img-height: 300px;

.swiper-container-vertical{
  height: 100%;
  overflow: hidden;
}

.swiper-container-horizontal {
  overflow: hidden;
}

.swiper-img {
  display: block;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    max-height: $max-sm-img-height;
  }
}

.avery-swiper-pagination{
  width:100%;
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet-active {
  background: $base-blue;
}

.swiper-pagination-bullet {
  margin-left: 5px;
  width: 10px;
  height: 10px;
}

.swiper-prev-button-vertical{
  position: absolute;
  left: 40%;
  top: -15px;
  z-index: 10;
  color: #979797;
  border: solid 0.7px #979797;
  border-radius: 50%;
  padding: 5px;
  background-color: white;
  svg{
    width: 21px;
    height: 21px;
  }
  .hidden{
    visibility: hidden;
  }
}

.swiper-next-button-vertical{
  position: absolute;
  left: 40%;
  bottom: -15px;
  z-index: 10;
  color: #979797;
  border: solid 0.7px #979797;
  border-radius: 50%;
  padding: 5px;
  background-color: white;
  svg{
    width: 21px;
    height: 21px;
  }
  .hidden{
    visibility: hidden;
  }
}

.swiper-button-disabled{
  opacity: 0.4;
  border: solid 0.7px #979797
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next
{
  &:after {
    font-size: rem(22);
    position: absolute;
    color: #000;
    font-weight: bolder;
  }
}
