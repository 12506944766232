@use "sass:math";

/* ==========================================================================
   Load More Items

   TODO: When/If the Load more Component becomes modular, we can put these styles back to the component level.

   SEEN ON: Filter Page, Template Filter Page, My Account Page(s), etc.
   ========================================================================== */

// Load More - Base
//
// Load more with Quantity Item display with a load more button
//
// **IMPORTANT:** When using the Markup snippet below, you might notice that the Filter Header takes up the whole space of the page. Simply wrap the element inside a `<div class="container container-fluid-sm-down"> ... </div>` to be as wide as the page container.
//
// Markup:
// <div class="load-more-items text-center text-md-left">
//   <div class="row align-items-center">
//     <div class="col-12 col-md-4">
//       <div class="results-show-qty">
//         <span class="label-qty-showing">Showing 1-12 of 270</span>
//       </div><!-- /.results-show-qty -->
//     </div>
//     <div class="col-12 col-md-4 text-md-center">
//       <div class="load-more-action text-md-center">
//         <button class="btn btn-avry btn-load-more">
//           Show More
//         </button>
//       </div><!-- /.load-more-action -->
//     </div>
//   </div>
// </div><!-- /.load-more-items -->
//
// styleguide filters.load-more-base

// Load More - Show All
//
// Load more with Quantity Item display with a load more button and Show All link
//
// **IMPORTANT:** When using the Markup snippet below, you might notice that the Filter Header takes up the whole space of the page. Simply wrap the element inside a `<div class="container container-fluid-sm-down"> ... </div>` to be as wide as the page container.
//
// Markup:
// <section class="load-more-items text-center text-md-left">
//   <div class="row align-items-center">
//     <div class="col-12 col-md-4 col-lg-3">
//       <div class="results-show-qty">
//         <span class="label-qty-showing">Showing 1-12 of 270</span>
//       </div><!-- /.results-show-qty -->
//     </div>
//     <div class="col-12 col-md-4 col-lg-3">
//       <div class="load-more-action">
//         <button class="btn btn-avry btn-load-more">
//           Show 12 More
//         </button>
//       </div><!-- /.load-more-action -->
//     </div>
//     <div class="col-12 col-md-4 col-lg-3">
//       <div class="load-all-link">
//         <a class="text-link">
//           Show All 270
//         </a>
//       </div><!-- /.load-all-link -->
//     </div>
//   </div>
// </section><!-- /.load-more-items -->
//
// styleguide filters.load-more-show-all

.load-more-items {
  padding: rem(20) 0;

  .results-show-qty, .load-more-action, .load-all-link {
    @include media-breakpoint-down(sm) {
      margin: rem(math.div($grid-gutter-width, 2)) 0;
    }
  }

  .load-more-action .btn {
    min-width: 200px;

    @include media-breakpoint-up(md) {
      min-width: 150px;
    }
  }
}
