/* ==========================================================================
   SUB MENU SECTION/COMPONENT
   ========================================================================== */

// Sub-Menu - Main
//
// Global Sub-menu element that appears at the bottom of the Main Mega Menu Navigation.
//
// Markup:
// <section class="sub-menu-container bg-charcoal">
//   <div class="container container-fluid-sm-down">
//     <nav class="sub-menu smooth-scroll">
//       <div class="d-flex full-height align-items-center">
//         <a href="/blank">
//           <span class="sub-menu-label">Blank Labels</span>
//         </a>
//         <ul>
//           <li class="">
//             <a href="/category/shape">Shop by Shape</a>
//           </li>
//           <li class="">
//             <a href="/category/usage">Shop by Use</a>
//           </li>
//           <li class="">
//             <a href="/category/selectedMaterial">Shop by Material</a>
//           </li>
//           <li>
//             <a href="/custom-printing">Custom Printed Labels</a>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   </div>
// </section>
//
// Styleguide header.sub-menu-main

$submenu-height: 48px;
$submenu-no-items-height: 3px;
$submenu-spacing: 30px;

.sub-menu-container {
  min-height: $submenu-no-items-height;
  max-height: $submenu-height;
  position: relative;
  overflow-y: hidden;

  @include media-breakpoint-down(sm) {
    margin-bottom: rem(15);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      min-width: 30px;
      min-height: $submenu-height;
      background: -webkit-linear-gradient(
        left,
        rgba($charcoal-black, 0) 0%,
        rgba($charcoal-black, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba($charcoal-black, 0) 0%,
        rgba($charcoal-black, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba($charcoal-black, 0) 0%,
        rgba($charcoal-black, 1) 100%
      );
      z-index: 10;
    }
  }

  .container {
    @include media-breakpoint-down(sm) {
      overflow-x: auto;
    }
  }
}

.sub-menu {
  height: $submenu-height;
  color: $main-white;
  overflow-y: hidden;

  // @include media-breakpoint-down(sm) {
  //   width: map-get($grid-breakpoints, sm);
  // }

  ul {
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin: 0 rem($submenu-spacing) 0 0;
      font-size: rem(16);

      &.active {
        position: relative;
      }

      &.active:after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  a {
    color: $main-white;
    white-space: nowrap;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .sub-menu-label {
    display: inline-block;
    // TODO: Adjust Left Margin with Mega Menu.
    margin: 0 rem($submenu-spacing) 0 0;
    font-size: rem(20);
    // TODO: Use actual Bold font
    font-weight: bold;
  }
}
