// Table
//
// styleguide table

/* ==========================================================================
   GLOBAL TABLE
   ========================================================================== */

// Global Table
//
// Table with Base Avery Styling
//
// Markup:
// <div class="table-avry">
//   <table>
//     <tr>
//       <th></th>
//       <th>Tear Resistant</th>
//       <th>Weather Resistant</th>
//       <th>UV Resistant</th>
//       <th>Temperature Resistant</th>
//     </tr>
//     <tr>
//       <td><a href="//www.avery.com/">UltraDuty GHS Chemical Labels</a></td>
//       <td>X</td>
//       <td>X</td>
//       <td>X</td>
//       <td>X</td>
//     </tr>
//     <tr>
//       <td><a href="//www.avery.com/">Easy Align Self-Laminating ID Labels</a></td>
//       <td>X</td>
//       <td>X</td>
//       <td>X</td>
//       <td></td>
//     </tr>
//     <tr>
//       <td><a href="//www.avery.com/">Durable ID Labels</a></td>
//       <td>X</td>
//       <td>X</td>
//       <td></td>
//       <td></td>
//     </tr>
//     <tr>
//       <td><a href="//www.avery.com/">Durable Multi-Surface ID Labels</a></td>
//       <td>X</td>
//       <td>X</td>
//       <td>X</td>
//       <td></td>
//     </tr>
//   </table>
// </div>
//
// Styleguide table.table-avry

.table-avry {
  overflow: auto;

  table {
    @include media-breakpoint-down(md) {
      width: map-get($container-max-widths, 'xl');
    }

    > tbody tr {
      border-top: 1px solid $base-border-color;

      &:nth-child(odd) {
        background: $brownish-gray;
      }

      &:nth-child(even) {
        background: $main-white;
      }

      &:last-child {
        border-bottom: 1px solid $base-border-color;
      }

      th,
      td {
        text-align: center;
        padding: rem(10);
        border-right: 1px solid $base-border-color;

        &:last-child {
          border-right: none;
        }
      }

      th {
        background: $light-powder-blue;
      }

      // first column
      th:first-child,
      td:first-child {
        text-align: left;
      }
    }
  }
}

/* ==========================================================================
   GLOBAL TABLE SMALL

   NOTE: Table that with content in smaller font size(s)
   ========================================================================== */

.table-avry.table-sm table {
  > tbody tr {
    th,
    td {
      font-size: rem(13);
      line-height: 1.5;
    }
  }
}

/* ==========================================================================
   GRAY SIMPLE TABLE
   ========================================================================== */

// Simple Gray Table
//
// A table with a base color scheme. Usually seen in WePrint pages.
//
// Markup:
// <div class="table-simple-gray-avry">
//   <table>
//     <tr>
//       <th>Quantity</th>
//       <th>One-Sided</th>
//       <th>Two-Sided</th>
//     </tr>
//     <tr>
//       <td>250</td>
//       <td>$22.50</td>
//       <td>$32.50</td>
//     </tr>
//     <tr>
//       <td>500</td>
//       <td>
//         <div class="text-crossed-out">$45.00</div>
//         $35.50
//       </td>
//       <td>
//         <div class="text-crossed-out">$65.00</div>
//         $50.00
//       </td>
//     </tr>
//     <tr>
//       <td>750</td>
//       <td>
//         <div class="text-crossed-out">$67.50</div>
//         $252.00
//       </td>
//       <td>
//         <div class="text-crossed-out">$97.50</div>
//         $75.00
//       </td>
//     </tr>
//     <tr>
//       <td>1000</td>
//       <td>
//         <div class="text-crossed-out">$135.00</div>
//         $90.00
//       </td>
//       <td>
//         <div class="text-crossed-out">$195.00</div>
//         $135.00
//       </td>
//     </tr>
//     <tr>
//       <td>1500</td>
//       <td>
//         <div class="text-crossed-out">$67.50</div>
//         $90.00
//       </td>
//       <td>
//         <div class="text-crossed-out">$97.50</div>
//         $135.00
//       </td>
//     </tr>
//   </table>
// </div>
//
// Styleguide table.table-simple-gray-avry
.table-simple-gray-avry {
  table {
    width: 100%;

    > tbody tr {
      &:nth-child(odd) {
        background: $faint-gray;
      }

      &:nth-child(even) {
        background: $main-white;
      }
    }

    th,
    td {
      padding: rem(10);
    }
  }
}
