/* ==========================================================================
   TILE SKELETON ITEM (Grid) ONLY - Utilizes Bootstrap's Card Component
   ========================================================================== */

// Skeleton Tile - Grid
//
// A Grid Tile used in place of the official grid tiles until it is finished loading.
//
// Used in Product Filter and Template Filter tiles.
//
// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//
// **NOTE:** Grid items are **NOT** display for breakpoints **SM** and below.
//
// Markup:
// <div class="tile-product-filter-item tile-item tile-grid-item tile-skeleton-grid-item tile-skeleton-item"  style="max-width: 208px; margin: 0 auto;">
//   <div class="card tile-container">
//     <div class="card-header">
//       <div class="flexible-img flexible-img-vertical">
//         <div class="skeleton-img"></div>
//         <!-- <img _ngcontent-c26="" class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/products/labels/72782-05160-p39t"> -->
//       </div>
//     </div>
//     <div class="card-body tile-block">
//       <ul class="skeleton-line-items">
//         <li></li><li></li><li></li><li></li><li></li>
//       </ul>
//     </div>
//   </div>
// </div>
//
// styleguide tiles.skeleton-grid

/* ==========================================================================
   TILE SKELETON ITEM (List) ONLY - Utilizes Bootstrap's Row
   ========================================================================== */

// Skeleton Tile - List
//
// A List Tile used in place of the official list tiles until it is finished loading.
//
// Used in Product Filter and Template Filter tiles.
//
// Markup:
// <div class="container mx-auto">
//   <div class="row">
//     <div class="col-12">
//     <div class="tile-product-filter-item tile-item tile-list-item tile-skeleton-list-item tile-skeleton-item">
//       <div class="tile-container">
//         <div class="row no-gutters full-height">
//           <div class="col-5 col-sm-3 col-md-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//             <div class="flexible-img flexible-img-horizontal">
//               <div class="skeleton-img"></div>
//             </div>
//           </div>
//           <div class="col-6 full-height gutter-top gutter-bottom col-list-item-detail">
//             <ul class="skeleton-line-items">
//               <li></li><li></li><li></li><li></li><li></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide tiles.skeleton-list

.tile-skeleton-item {
  .skeleton-img {
    width: 100%;
    height: 100%;
    background: $faint-gray;
  }

  .skeleton-line-items {
    padding: 0;

    li {
      display: block;
      width: 35%;
      height: 20px;
      background: $faint-gray;
      margin-bottom: rem(10);

      &:nth-child(even) {
        width: 60%;
      }

      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: 20%;
      }
    }
  }
}

.tile-product-filter-item.tile-grid-item.tile-skeleton-grid-item .tile-container {
  height: 469px;

  @include media-breakpoint-up(lg) {
    height: 477px;
  }

  @include media-breakpoint-up(xl) {
    height: 509px;
  }
}
