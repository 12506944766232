@use "sass:math";

// Utilities
//
// Styleguide utilities

// Disables auto formating of telephone numbers that some browsers performs (i.e. Safari iOS)
a[href^='tel'] {
  text-decoration: inherit;
  color: inherit;
}

/* ==========================================================================
   UTILITIES/UTILITY CLASSES - BOOTSTRAP

   NOTE: Extends/Modifies Boostrap's styles.
   ========================================================================== */

// Remove column padding
[class*='col-'].no-col-padding {
  padding: 0 !important;
}

// 5 Columns
//
// How to use: For columns, add .col-*-2 with .col-of-5
//
// NOTE: This is allows for a 5 column layout with bootstrap
// IMPORTANT: This is experimental and not native to Bootstrap 4, expect side affects.
//
// DEPRECATED: Use bootstrap 4 `col` classes 5 times to get a 5 column layout.
.col-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xl-2 {
  &.col-of-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

// text-truncate for <ul>
ul.text-truncate {
  padding-left: 0 !important;
}
ul li.text-truncate {
  &:before {
    content: '\2022';
    display: inline-block;
    margin-right: rem(10);
  }
}

/* ==========================================================================
   ACCENT DASH
   ========================================================================== */

// Accent Dash
//
// A visual element intended to put emphasis in an area, commonly a section of content.
//
// **NOTE:** Below is a short sample list but you can use the <a href="/section-utilities.html#kssref-utilities-background-colors">background utilities</a> to modify the background of the accent dash.
//
// Also supports <a href="/section-industrial-utilities.html#kssref-industrial-utilities-background-colors">industrial</a> utility background color utilities
//
// .bg-header-blue - Using the `.bg-header-blue` background color utility.
// .bg-green - Using the `.bg-green` background utility.
//
// Markup:
// <div class="accnt-dash {{modifier_class}}"></div>
//
// styleguide utilities.accnt-dash

.accnt-dash {
  // NOTE: Mainly an `inline-block` element because it is mainly grouped with text element(s).
  display: inline-block;
  width: 70px;
  height: 6px;
  border-radius: rem(3);
  background-color: $main-black;
}

/* ==========================================================================
   UTILITIES/UTILITY CLASSES - GLOBAL
   ========================================================================== */

// Apply Height of Parent
// TODO: Depracate... use BS4 .h-100 class instead.
.full-height {
  height: 100%;
}

// No Min Height
//
// Utility to remove min-height of element (`.min-h-none`).
//
// Styleguide utilities.min-h-none

.min-h-none {
  // NOTE: Did not find this utility in BS4.
  min-height: 0 !important;
}

// Background Color Utilities
//
// Add these utility classes to add background colors to your element(s).
//
// NOTE: These colors are based on the Avery Website color palette.
//
// .bg-black - Black Background
// .bg-white - White Background
// .bg-almost-white - Almost White Background
// .bg-charcoal - Charcoal Background
// .bg-gray - Gray Background
// .bg-bold-gray - Bold Gray Background
// .bg-faint-gray - Faint Gray Background
// .bg-medium-gray - Medium Gray Background
// .bg-light-gray - Light Gray Background
// .bg-tan - Tan Background
// .bg-container-blue - Container Blue Background
// .bg-deep-blue - Deep Dlue background
// .bg-light-blue - Light Blue Background
// .bg-powder-blue - Powder Blue Background
// .bg-mint-green - Mint Green Background
// .bg-green - Green Background
// .bg-header-blue - Header Blue Background
//
// Markup:
// <div class="p-3 my-2 w-100 {{modifier_class}}" style="color: #FFA31F;">Simply add this `.{{modifier_class}}` class to your element.</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide utilities.background-colors

// Background Colors
.bg-black {
  // Black
  background-color: $main-black !important;
}
.bg-white {
  // White
  background-color: $main-white !important;
}
.bg-almost-white {
  background-color: $almost-white !important;
}
.bg-charcoal {
  // Charcoal
  background-color: $charcoal-black !important;
}
.bg-gray {
  // Gray
  background-color: $brownish-gray !important;
}
.bg-bold-gray {
  background-color: $bold-gray !important;
}
.bg-faint-gray {
  background-color: $faint-gray !important;
}
.bg-medium-gray {
  background-color: $gray-medium !important;
}
.bg-light-gray {
  // bootstrap Gray
  background-color: $light-gray !important;
}
.bg-tan {
  // Tan
  background-color: $brownish-gray !important;
}
.bg-container-blue {
  background-color: $container-blue !important;
}
.bg-light-blue {
  background-color: $washed-blue !important;
}
.bg-deep-blue {
  background-color: $deep-blue !important;
}
.bg-sky-blue {
  background-color: $sky-blue !important;
}
.bg-powder-blue {
  background-color: $powder-blue !important;
}
.bg-light-powder-blue {
  background-color: $light-powder-blue !important;
}
.bg-light-orange {
  background-color: $light-orange !important;
}
.bg-mint-green {
  background-color: $mint-green !important;
}
.bg-green {
  background-color: $neon-green !important;
}
// .bg-header-blue {
//   background-color: $header-blue !important;
// }

// Borders
//
// Border style with applied Avery global styles.
//
// You can use the border class with sm, md, lg, and xl modifiers.
//
// ie. border border-md-0 border-lg-top
//
// .border-avry - default (1px)
// .border-avry-2 - 2px
// .border-avry-3 - 3px
// .border-avry-4 - 4px
//
// Markup:
// <div class="p-3 my-2 w-50 border {{modifier_class}}">`.{{modifier_class}}` class.</div>
//
// hidedefaultstyling: true
//
// Styleguide utilities.borders

.border-avry {
  border-top: 1px solid $base-border-color;
}
.border-avry-2 {
  border-top: 2px solid $base-border-color;
}
.border-avry-3 {
  border-top: 3px solid $base-border-color;
}
.border-avry-4 {
  border-top: 4px solid $base-border-color;
}

@include media-breakpoint-up(sm) {
  .border-sm {
    border: 1px solid $base-border-color;
  }
  .border-sm-top {
    border-top: 1px solid $base-border-color;
  }
  .border-sm-right {
    border-right: 1px solid $base-border-color;
  }
  .border-sm-bottom {
    border-bottom: 1px solid $base-border-color;
  }
  .border-sm-left {
    border-left: 1px solid $base-border-color;
  }
  .border-sm-0 {
    border: 0;
  }
}

@include media-breakpoint-up(md) {
  .border-md {
    border: 1px solid $base-border-color;
  }
  .border-md-top {
    border-top: 1px solid $base-border-color;
  }
  .border-md-right {
    border-right: 1px solid $base-border-color;
  }
  .border-md-bottom {
    border-bottom: 1px solid $base-border-color;
  }
  .border-md-left {
    border-left: 1px solid $base-border-color;
  }
  .border-md-0 {
    border: 0;
  }
}

@include media-breakpoint-up(lg) {
  .border-lg {
    border: 1px solid $base-border-color;
  }
  .border-lg-top {
    border-top: 1px solid $base-border-color;
  }
  .border-lg-right {
    border-right: 1px solid $base-border-color;
  }
  .border-lg-bottom {
    border-bottom: 1px solid $base-border-color;
  }
  .border-lg-left {
    border-left: 1px solid $base-border-color;
  }
}
@include media-breakpoint-up(xl) {
  .border-xl {
    border: 1px solid $base-border-color;
  }
  .border-xl-top {
    border-top: 1px solid $base-border-color;
  }
  .border-xl-right {
    border-right: 1px solid $base-border-color;
  }
  .border-xl-bottom {
    border-bottom: 1px solid $base-border-color;
  }
  .border-xl-left {
    border-left: 1px solid $base-border-color;
  }
  .border-xl-0 {
    border: 0;
  }
}

// Borders - Color
//
// Border color styles
//
// .bdr-lghtgray - Light Gray Border
// .bdr-mediumgray - Medium Gray Border
// .bdr-whte - White Border
//
// Markup:
// <div class="p-3 my-2 w-50 border {{modifier_class}}">`.{{modifier_class}}` class.</div>
//
// hidedefaultstyling: true
//
// Styleguide utilities.borders-color

.bdr-lghtgray {
  border-color: $light-gray !important;
}

.bdr-mediumgray {
  border-color: $gray-medium !important;
}

.bdr-whte {
  border-color: $main-white !important;
}

// Divider Directional - Top/Right/Bottom/Left
//
// Simply add **.top-divider**, **.right-divider**, **.bottom-divider**, or **.left-divider** class to your HTML element to add the corresponding divider.
//
// .top-divider - Top Divider
// .right-divider - Right Divider
// .bottom-divider - Bottom Divider
// .left-divider - Bottom Divider
//
// Markup:
// <div class="p-3 my-2 w-50 bg-light-gray {{modifier_class}}">Simply add this `.{{modifier_class}}` class to your element.</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide utilities.divider-line-directional-top-right-bottom-left

.top-divider {
  border-top: solid $divider-weight $divider-color;
  border-radius: 0;
}
.right-divider {
  border-right: solid $divider-weight $divider-color;
  border-radius: 0;
}
.bottom-divider {
  border-bottom: solid $divider-weight $divider-color;
  border-radius: 0;
}
.left-divider {
  border-left: solid $divider-weight $divider-color;
  border-radius: 0;
}

// Divider Line
//
// Simply a Hair Line divider.
//
// NOTE: This is an independent border element. It is not added to an element, like the 'Top/Bottom Divider'.
//
// Markup:
// <div class="divider-line container container-fluid-sm-down">
//   <hr />
// </div>
//
// Styleguide utilities.divider-line

.divider-line {
  @include divider-base();
}

// Divider Bold Round
//
// A bold rounded divider.
//
// NOTE: This is an independent border element. It is not added to an element, like the 'Top/Bottom Divider'.
//
// Markup:
// <div class="divider-bold-round container container-fluid-sm-down">
//   <hr />
// </div>
//
// Styleguide utilities.divider-line-bold-round

.divider-bold-round {
  @include divider-base();

  hr {
    border-top: 5px solid $main-black;
    border-radius: rem(6);
  }
}

// Divider Dotted
//
// A dotted divider.
//
// NOTE: This is an independent border element. It is not added to an element, like the 'Top/Bottom Divider'.
//
// Markup:
// <div class="divider-dotted container container-fluid-sm-down">
//   <hr />
// </div>
//
// Styleguide utilities.divider-line-dotted

.divider-dotted {
  @include divider-base();

  hr {
    border-top: 1px dotted $divider-color;
  }
}

// Img Height Fluid
.img-fluid.img-fluid-height {
  max-height: 100%;
}

// Single row of options
// FIXME: deprecate, too specific, just use bootstrap's spacer classes.
.options-bar {
  padding: 15px;
}

// Flexible Img Container - Sets <img> child to be snuggly fit centered and vertically middle of this container.
//
// NOTE: The parent element of this '.flexible-img' should have a defined height.
.flexible-img {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  // If content is more of a portrait or Grid like layout.
  &.flexible-img-vertical {
    @include media-breakpoint-up(md) {
      padding: math.div($gutter-base, 2);
    }

    img {
      flex: 0 0 auto;
    }
  }

  // If content is more of a landscape or list like layout.
  &.flexible-img-horizontal {
    padding-left: rem(math.div($gutter-base, 2));
    padding-right: rem(math.div($gutter-base, 2));

    img {
      flex: 0 0 auto;
      padding: 0;
    }
  }
}

// No Select Text/Element
//
// Disables Cursor select/highlighting using mouse. Add `.user-no-select` class to element.
//
// markup:
// <p><strong>Can Select -</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et rhoncus urna, sit amet pretium velit. Pellentesque nec vehicula eros, nec vulputate quam. Ut lacinia non sem a semper. Maecenas consequat est quam, eu facilisis dui dapibus vel. Proin commodo volutpat porta. Nam ullamcorper purus magna, eget fermentum dui sodales vitae. Nullam non erat eget nulla malesuada iaculis blandit in nunc. Nam id velit facilisis, consequat justo id, molestie urna. Nam ac ligula elementum, dictum nisi eget, lacinia risus. Praesent posuere dictum dolor, non congue mauris facilisis non. Donec urna massa, finibus ac tempus nec, imperdiet ac arcu. Aenean dapibus at diam nec tempor. Nunc fermentum magna risus, quis tincidunt quam congue eu. Vivamus et odio fringilla, cursus lacus eu, placerat felis.</p>
// <p class="user-no-select"><strong>Can NOT Select -</strong> Fusce vitae nulla quis quam elementum mollis. Nam lectus sem, imperdiet nec mattis eu, mattis ut lectus. Vivamus vulputate massa vel ligula congue, vel malesuada libero iaculis. Praesent tempor arcu sit amet metus sollicitudin, ac consequat diam iaculis. Etiam fringilla scelerisque faucibus. Duis vitae dui sed erat venenatis scelerisque. Etiam tempor iaculis dolor eu bibendum. Proin placerat, urna nec gravida rutrum, sapien enim tristique massa, ut commodo quam arcu vitae diam. Donec et vehicula erat. Aliquam ut nulla egestas, venenatis leo eget, fringilla tellus. Pellentesque nec tempor erat. Interdum et malesuada fames ac ante ipsum primis in faucibus. In consequat lacus ac vulputate congue. Proin ac quam maximus erat tincidunt cursus egestas non ex. Pellentesque nunc est, efficitur sit amet metus et, tincidunt iaculis risus. Donec lorem erat, porttitor non massa pulvinar, dapibus ultricies dui.</p>
//
// styleguide utilities.no-select

.user-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// View Toggle
.view-toggle {
  font-size: rem(18);
  cursor: pointer;

  &:first-child {
    margin-right: rem(10);
  }

  &.active {
    color: $base-blue;
  }
}

/* ==========================================================================
   LIST LINKS
   ========================================================================== */

// List Links
//
// A list set that displays only links.
//
// markup:
// <ul class="list-links">
//   <li><a href="" class="text-link">Rectangle Labels</a></li>
//   <li><a href="" class="text-link">Round Labels</a></li>
//   <li><a href="" class="text-link">Oval Labels</a></li>
//   <li><a href="" class="text-link">Square Labels</a></li>
//   <li><a href="" class="text-link">Arched Labels</a></li>
// </ul>
//
// styleguide utilities.list-links
.list-links {
  padding: 0;
  list-style: none;

  li {
    padding: rem(3) 0;
  }
}

.no-list-style {
  list-style: none !important;
}

/* ==========================================================================
   LIST SPACING UTILITIES
   ========================================================================== */

// List Spacing
//
// Spacing utlities for ul/ol elements.
//
// .mb-1-list - Adds `mb-1` spacing to a list.
// .mb-2-list - Adds `mb-2` spacing to a list.
// .mb-3-list - Adds `mb-3` spacing to a list.
// .mb-4-list - Adds `mb-4` spacing to a list.
// .mb-5-list - Adds `mb-5` spacing to a list.
//
// markup:
// <ul class="list-links {{modifier_class}}">
//   <li>Item 1</li>
//   <li>Item 2</li>
//   <li>Item 3</li>
//   <li>Item 4</li>
//   <li>Item 5</li>
// </ul>
//
// hidedefaultstyling: true
//
// styleguide utilities.list-spacing

.mb-1-list {
  > li {
    margin-bottom: map-get($spacers, 1) !important;
  }
}
.mb-2-list {
  > li {
    margin-bottom: map-get($spacers, 2) !important;
  }
}
.mb-3-list {
  > li {
    margin-bottom: map-get($spacers, 3) !important;
  }
}
.mb-4-list {
  > li {
    margin-bottom: map-get($spacers, 4) !important;
  }
}
.mb-5-list {
  > li {
    margin-bottom: map-get($spacers, 5) !important;
  }
}

/* ==========================================================================
   LIST-CHECKMARK
   ========================================================================== */

// List Checkmark
//
// A list set that displays checkmarks as the bullet item.
//
// markup:
// <b>Default Check List:</b>
// <ul class="list-checkmark">
//   <li>Add the class 'list-checkmark' to the 'ul'</li>
//   <li>Great for machine or hand applications</li>
//   <li>...</li>
// </ul>
// <b>Check Dot List:</b>
// <ul class="list-checkmark check-dots">
//   <li>Add the class 'list-checkmark' to the 'ul'</li>
//   <li>Add the class 'check-dots' to the 'ul'</li>
//   <li>...</li>
// </ul>
// <b>Check Dot List in Red:</b>
// <ul class="list-checkmark check-dots check-dots--red">
//   <li>Add the class 'list-checkmark' to the 'ul'</li>
//   <li>Add the class 'check-dots' to the 'ul'</li>
//   <li>Add the class 'check-dots--red' to the 'ul'</li>
// </ul>
// <b>Check Dot List in 20px:</b>
// <ul class="list-checkmark check-dots check-dots--sze-20">
//   <li>Add the class 'list-checkmark' to the 'ul'</li>
//   <li>Add the class 'check-dots' to the 'ul'</li>
//   <li>Add the class 'check-dots--sze-20'to the 'ul'</li>
// </ul>
//
// styleguide utilities.list-checkmark

.list-checkmark {
  $checkmark-position: 24px;

  list-style: none;
  padding-left: rem($checkmark-position) !important;

  li {
    padding: rem(3) 0;
    position: relative;

    &:before {
      font-family: avery-fonticon;
      content: '\e924';
      font-size: rem(12);
      position: absolute;
      left: rem(-$checkmark-position);
    }
  }

  &.check-dots li:before {
    content: '\e92b';
    border-radius: 50%;
    background: $main-white;
    font-size: rem(16);
    text-align: center;
    line-height: 1;
    color: $bright-bold-blue;
  }

  // Check Dots Sizes
  &.check-dots--sze-20 {
    $check-dot-size: 20;

    li {
      line-height: normal !important;
      padding: 0 0 0 rem(6) !important;
      margin-bottom: rem($check-dot-size);
      font-size: rem($check-dot-size);

      &:before {
        font-size: rem($check-dot-size);
      }
    }
  }

  // Check Dots Colors
  &.check-dots--red li:before {
    color: $bright-red !important;
  }
}

/* ==========================================================================
   LIST-FAQ
   ========================================================================== */

// List FAQ
//
// A set of FAQs.
//
// markup:
// <h2 class="h4 mb-3">FAQ's</h2>
// <ul class="list-faq pl-4">
//   <li class="list-faq__q">The GHS Wizard used to be free, why should I subscribe?</li>
//   <li class="list-faq__a">The enhanced GHS Wizard has been upgraded to autofill chemical information for 150,000 chemicals, with continuously updated data powered by Chemwatch. Instantly create and print more accurate GHS labels for a wider range of chemicals.</li>
//   <li class="list-faq__q">Who is Chemwatch??</li>
//   <li class="list-faq__a">For over 20 years, Chemwatch has provided solutions for chemical management, SDS authoring and regulatory compliance.  Chemwatch GHS data for the Avery GHS Wizard is based on U.S. OSHA standards and is continuously updated, so you have the latest information for your GHS labels. Learn More about Chemwatch.</li>
// </ul>
//
// hidedefaultstyling: true
//
// styleguide utilities.list-links

.list-faq {
  list-style: none;

  li {
    position: relative;
  }

  .list-faq__q,
  .list-faq__a {
    &:before {
      font-weight: bold;
      position: absolute;
      left: map-get($spacers, 4) * -1;
    }
  }

  .list-faq__q {
    font-weight: bold;
    margin-bottom: map-get($spacers, 3);

    &:before {
      content: 'Q:';
    }
  }

  .list-faq__a {
    margin-bottom: map-get($spacers, 5);

    &:before {
      content: 'A:';
    }
  }
}

/* ==========================================================================
   CURSOR POINTER
   ========================================================================== */

// Cursor Pointer
//
// A utility class that gives an element the pointer cursor. Add the `.c-pointer` class to any element.
//
// markup:
// <span class="c-pointer">
//   <img class="img-fluid" style="max-width: 300px" src="https://img.avery.com/web/weprint/img-icon-low-min-order" alt="Low minimum orders—no setup fees">
// </span>
//
// styleguide utilities.c-pointer

.c-pointer {
  cursor: pointer !important;
}

/* ==========================================================================
   INLINE-ALERT
   ========================================================================== */

// Inline Alert
//
// An element that is displayed paired with text. This element is usually used to annouce or "alert" a brief message.
//
// NOTE: To modify the background of the inline alert. Simply add the `.bg-*` classes. See [here](/section-utilities.html#kssref-utilities-background-colors).
//
// markup:
// <a href="" class="text-link">Tags</a><span class="inline-alert">NEW</span>
//
// styleguide utilities.inline-alert
.inline-alert {
  background-color: $bright-red;
  font-size: rem(10);
  padding: rem(3) rem(4);
  margin: 0 rem(5);
  border-radius: rem(4);
  color: $main-white;
}

/* ==========================================================================
   INPUT TEXT WITH BUTTON
   ========================================================================== */

//-// Input Text with Button
//-//
//-// Global search with button element. Used for Search's, Email subscriptions, etc.
//-//
//-// Markup:
//-// <div class="input-text-btn-avry">
//-//   <input type="text" class="form-control" placeholder="Type search request here" />
//-//   <div class="btn btn-avry btn-action-green">Search</div>
//-// </div>
//-//
//-// Styleguide utilities.input-text-btn-avry

// FIXME: Deprecated - Remove `.input-text-btn-avry` and all its nested styles once verified not/no longer used.
.input-text-btn-avry {
  display: flex;
  max-width: 375px;
  border: 1px solid $main-black;
  background: $main-white;
  padding: rem(5) rem(5) rem(5) rem(8);
  border-radius: $avry-btn-border-radius;

  .form-control {
    flex: 1 1 auto;
    // TODO: use actual italic font.
    font-style: italic;
    border: none;
    padding-left: 0;
    margin-left: rem(12);

    @include media-breakpoint-down(sm) {
      font-size: rem(11);
    }
  }

  .btn.btn-avry {
    flex: 0 0 auto;
  }
}

/* ==========================================================================
   IMAGE WRAP
   ========================================================================== */

// Image Wrap
//
// Wraps image around text. By default, images will stack instead of wrapping in XS and SM breakpoints.
//
// **How to Use:** Add `.img-wrap` class with the float directional class of `.float-left` or `.float-right` to the `<img>` tag.
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi!</p>
// <img class="img-wrap float-right" src="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_01.jpg" style="max-width: 350px;">
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi!</p>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi!</p>
// <img class="img-wrap float-left" src="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_02.jpg" style="max-width: 350px;">
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi!</p>
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit iure fugit aperiam dolorem dolore nostrum illo eveniet sequi ex placeat. Provident dignissimos perferendis quam, facilis dolor consectetur enim vel eligendi!</p>
//
// Styleguide utilities.image-wrap

$img-wrap-spacing: rem(20);
.img-wrap {
  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0 0 $img-wrap-spacing;
    float: none !important;
  }

  @include media-breakpoint-up(md) {
    &.float-left {
      margin: 0 $img-wrap-spacing $img-wrap-spacing 0;
    }

    &.float-right {
      margin: 0 0 $img-wrap-spacing $img-wrap-spacing;
    }
  }
}

/* ==========================================================================
   IMAGE GROUP
   ========================================================================== */

//-// Image Group
//-//
//-// Similar to Tile Group, except the module content of this group are simply images.
//-//
//-// Markup:
//-// <section class="img-group">
//-//   <div class="container">
//-//     <div class="section-header">
//-//       <h5>Permanently adheres to tough surfaces like rough plastic, metal, wood, PVC pipes</h5>
//-//     </div>
//-//     <div class="row">
//-//        <div class="col-6 col-md-3">
//-//         <picture>
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" media="(max-width: 768px)">
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(min-width: 769px)">
//-//           <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//         </picture>
//-//        </div>
//-//        <div class="col-6 col-md-3">
//-//         <picture>
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(max-width: 768px)">
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(min-width: 769px)">
//-//           <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//         </picture>
//-//        </div>
//-//        <div class="col-6 col-md-3">
//-//         <picture>
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(max-width: 768px)">
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(min-width: 769px)">
//-//           <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//         </picture>
//-//        </div>
//-//        <div class="col-6 col-md-3">
//-//         <picture>
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(max-width: 768px)">
//-//           <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" media="(min-width: 769px)">
//-//           <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//         </picture>
//-//        </div>
//-//     </div>
//-//   </div>
//-// </section>
//-//
//-// Styleguide utilities.image-group

// FIXME: Deprecated - Remove `.img-group` and all its nested styles once verified not/no longer used. Can easily used using the [grid](https://getbootstrap.com/docs/4.0/layout/grid/)
.img-group {
  img {
    border-radius: rem(8);
    // Sets Gutters, based on gutter values assigned in different break points.
    margin-bottom: rem($grid-gutter-width);
  }
}

/* ==========================================================================
   SMOOTH SCROLLING
   ========================================================================== */

.smooth-scroll {
  -webkit-overflow-scrolling: touch; // NOTE: this extra feature is only available on Chrome... For now...
}

/* ==========================================================================
   STAR RATINGS
   ========================================================================== */

// Star Ratings
//
// These five star ratings can show the exact % amound of stars.
// To show that the width of the child div .google-stars must be
// hardcoded with the percent value.
//
// Markup:
// <div class="star-rating">
//   <div class="star-rating-fill" style="width: 96%;"></div>
// </div>
// <br>
// <br>
// <div class="star-rating star-rating--small">
//   <div class="star-rating-fill" style="width: 96%;"></div>
// </div>
//
// Styleguide utilities.ratings

.star-rating {
  display: inline-block;
  width: 100px;
  height: 20px;
  margin-right: 10px;
  background-image: url(https://img.avery.com/web/weprint/home/rating-stars-01.svg);

  &.star-rating--small {
    vertical-align: top;
    width: 5.3rem;
    height: 1rem;

    .star-rating-fill {
      background-size: 5.3rem 1rem;
    }
  }
  .star-rating-fill {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: 100px 20px;
    background-image: url(https://img.avery.com/web/weprint/home/rating-stars-full.svg);
  }
}

/* ==========================================================================
   STAR RATING - Font Awesome
   ========================================================================== */

// Star Rating - Font Awesome
//
// General Star rating element based on Font Awesome
//
// markup:
// <div class="avry-rating">
//   <div class="stars d-inline-block">
//     <i class="fa fa-star" aria-hidden="true"></i>
//     <i class="fa fa-star" aria-hidden="true"></i>
//     <i class="fa fa-star" aria-hidden="true"></i>
//     <i class="fa fa-star" aria-hidden="true"></i>
//     <i class="fa fa-star" aria-hidden="true"></i>
//   </div>
// </div>
//
// styleguide utilities.rating-font-awesome
.avry-rating,
.tile-rating,
.product-rating {
  // TODO: Check if `tile-rating` and `product-rating` is still used. Because we should keep this general...
  .stars {
    margin-right: rem(10);
    color: $yellow-gold;
    white-space: nowrap;
  }
}

/* ==========================================================================
   GOOGLE REVIEWS
   ========================================================================== */

// Google Reviews
//
// General Google Reviews Styling
//
// Markup:
// <div class="avry-google-reviews pb-5">
//   <div class="container container-fluid-sm-down">
//     <a href="https://www.google.com/shopping/seller?q=avery.com&amp;hl=en-US?int_id=banner-googlereviews-GTRreviews" target="_blank">
//       <div class="reviews-head bg-light-gray">
//         <div class="row align-items-center">
//           <div class="col-12 col-md-6 text-center text-md-left">
//             <img class="img-fluid" src="https://img.avery.com/web/weprint/logo-google-reviews" alt="Google Customer Reviews">
//           </div>
//           <div class="col-12 col-md-6 text-center text-md-right">
//             See all reviews
//           </div>
//         </div>
//       </div>
//     </a>
//     <div class="reviews-body">
//       <div class="row">
//         <div class="col-12 col-md-6 px-5">
//           <div class="review-item">
//             <div class="rating">
//               <div class="avry-rating">
//                 <div class="stars d-inline-block">
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                 </div>
//                 <span>5/5 Stars</span>
//               </div>
//             </div>
//             <div class="review-content">
//               <p>Designing and ordering my labels was easy and very affordable! They also arrived pretty fast!!! Very satisfied
//                 customer here!</p>
//             </div>
//           </div>
//           <div class="review-item">
//             <div class="rating">
//               <div class="avry-rating">
//                 <div class="stars d-inline-block">
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                 </div>
//                 <span>5/5 Stars</span>
//               </div>
//             </div>
//             <div class="review-content">
//               <p>They were fast, easy and I was very happy with the results.</p>
//             </div>
//           </div>
//           <div class="review-item">
//             <div class="rating">
//               <div class="avry-rating">
//                 <div class="stars d-inline-block">
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                 </div>
//                 <span>5/5 Stars</span>
//               </div>
//             </div>
//             <div class="review-content">
//               <p>This is the second time I have purchased from Avery We Print and my order was perfect and arrived quickly.
//                 For most projects I print my own labels because there are many changes, i.e. fragrance, size, ingredients
//                 since I do custom orders of bath &amp; body products. However for my round labels, which never change, this
//                 is my best option rather than buying the round labels and printing them myself.</p>
//             </div>
//           </div>
//         </div>
//         <div class="col-12 col-md-6 px-5">
//           <div class="review-item">
//             <div class="rating">
//               <div class="avry-rating">
//                 <div class="stars d-inline-block">
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                 </div>
//                 <span>5/5 Stars</span>
//               </div>
//             </div>
//             <div class="review-content">
//               <p>I have always trusted Avery products. I've used them for many years. This was my first time using the printing
//                 service. All I can say is Wow!! The printing was beautiful and I was quite surprised by the shipping. I didn't
//                 expect them so soon! I will most definitely use this service again!</p>
//             </div>
//           </div>
//           <div class="review-item">
//             <div class="rating">
//               <div class="avry-rating">
//                 <div class="stars d-inline-block">
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                   <i class="fa fa-star" aria-hidden="true"></i>
//                 </div>
//                 <span>5/5 Stars</span>
//               </div>
//             </div>
//             <div class="review-content">
//               <p>Great online design tools. Excellent, fluid website. Quick delivery. Great price and product!</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide utilities.ratings-2
.avry-google-reviews {
  .review-item {
    padding: rem(20) 0 0;
  }
  .reviews-head {
    padding: rem(10) rem(20);
    border-radius: $tile-border-radius;
  }
  .review-content {
    p {
      margin: 0;
    }
  }
}

/* ==========================================================================
   GOOGLE TRUSTED STORE
   ========================================================================== */

// Google Trusted Store
//
// General Google Tusted Store Styling
//
// Markup:
// <div class="avry-google-trusted-store text-center">
//   <p class="trusted-store-head-text h4">'Always quick production and delivery, and color output is always true to my original designs!'</p>
//   <div class="trusted-store-head bg-powder-blue">
//     <div class="row align-items-center">
//       <div class="col-12 col-md-4">
//         <img class="img-fluid" src="https://img.avery.com/web/weprint/logo-google-trusted-stores" alt="Google Trusted Store">
//       </div>
//       <div class="col-12 col-md-8 text-md-right">
//         <div class="rating">
//           <div class="avry-rating d-flex align-items-center justify-content-center justify-content-md-end">
//             <div class="star-rating">
//               <div class="star-rating-fill"></div>
//             </div>
//             <span>
//               <strong>4.8 out of 5</strong>
//             </span>
//             <span class="review-index">
//               <a href="https://www.google.com/shopping/seller?q=avery.com&amp;hl=en-US?int_id=banner-googlereviews-GTRreviews"
//                 target="_blank">
//                 <span class="text-link text-nowrap ml-3">See all reviews</span>
//               </a>
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide utilities.ratings-3
.avry-google-trusted-store {
  .trusted-store-head-text {
    margin-bottom: rem(20);
    font-weight: normal;
    color: $sub-gray;

    @include media-breakpoint-only(md) {
      font-size: rem(18);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem(21);
    }
  }

  .trusted-store-head {
    max-width: 775px;
    margin: 0 auto;
    padding: rem(5) rem(20);
    border-radius: $tile-border-radius;
  }

  .stars {
    font-size: rem(24);
  }
}

/* ==========================================================================
   ROTATION CLASSES
   ========================================================================== */

// Rotation Classes
//
// Rotates element at either 90, 180, or 270 degrees. Rotation is clockwise, 0 degrees being 12 o'clock.
//
// .rotate-0 - Rotates element 0 degrees
// .rotate-90 - Rotates element 90 degrees
// .rotate-180 - Rotates element 480 degrees
// .rotate-270 - Rotates element 270 degrees
//
// Markup:
// <div class="py-5" style="width: 100%;">
//   <img src="https://img.avery.com/w_345/web/templates/banner-software-design-and-print" class="{{modifier_class}}" alt="" />
// </div>
//
// hidedefaultstyling: true
//
// styleguide utilities.rotation-classes

.rotate-0 {
  transform: rotate(0deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.rotate-270 {
  transform: rotate(270deg) !important;
}

/* ==========================================================================
   TRANSFORM UTILITIES
   ========================================================================== */

// Transform Origin
//
// Sets element's anchor point (origin) for transform.
//
// .tfrm-orgn-lft - Sets element anchor point (origin) to the left.
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide utilities.transform-origin

.tfrm-orgn-lft {
  transform-origin: left !important;
}

/* ==========================================================================
   LAZYSIZES UTILITIES
   ========================================================================== */

.lazyload,
.lazyloading {
  opacity: 0.5;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 400ms;
}

/* ==========================================================================
   Image Containers
   ========================================================================== */

// Image Containers
//
// Ratio enforcement for image containers
//
// ".img-box", one ratio, and a parent width (if not provided by a grid or column system) is required.
//
// note: Image will be centered both vertically and horizontally.
//
// Markup:
// <div class="row">
//  <div class="col-12 col-md-4">
//    <div class="h4">1:1 ratio</div>
//    <div class="img-box ratio__1-1"><img src="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_02.jpg" /></div>
//  </div>
//  <div class="col-12 col-md-4">
//    <div class="h4">2:1 ratio </div>
//    <div class="img-box ratio__4-3"><img src="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_02.jpg" /></div>
//  </div>
//  <div class="col-12 col-md-4">
//    <div class="h4">4:1 ratio</div>
//    <div class="img-box ratio__2-1"><img src="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_02.jpg" /></div>
//  </div>
// </div>
//
// Styleguide utilities.ratio

.img-box {
  align-items: center;
  align-self: auto;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.ratio__4-3 {
  padding-top: 75%;
  width: 100%;
}
.ratio__sm-4-3 {
  @include media-breakpoint-up(sm) {
    padding-top: 75%;
    width: 100%;
  }
}
.ratio__md-4-3 {
  @include media-breakpoint-up(md) {
    padding-top: 75%;
    width: 100%;
  }
}
.ratio__lg-4-3 {
  @include media-breakpoint-up(lg) {
    padding-top: 75%;
    width: 100%;
  }
}
.ratio__xl-4-3 {
  @include media-breakpoint-up(xl) {
    padding-top: 75%;
    width: 100%;
  }
}

.ratio__2-1 {
  padding-top: 50%;
  width: 100%;
}
.ratio__sm-2-1 {
  @include media-breakpoint-up(sm) {
    padding-top: 50%;
    width: 100%;
  }
}
.ratio__md-2-1 {
  @include media-breakpoint-up(md) {
    padding-top: 50%;
    width: 100%;
  }
}
.ratio__lg-2-1 {
  @include media-breakpoint-up(lg) {
    padding-top: 50%;
    width: 100%;
  }
}
.ratio__xl-2-1 {
  @include media-breakpoint-up(xl) {
    padding-top: 50%;
    width: 100%;
  }
}

.ratio__1-1 {
  width: 100%;
  padding-top: 100%;
}
.ratio__sm-1-1 {
  @include media-breakpoint-up(sm) {
    padding-top: 100%;
    width: 100%;
  }
}
.ratio__md-1-1 {
  @include media-breakpoint-up(md) {
    padding-top: 100%;
    width: 100%;
  }
}
.ratio__lg-1-1 {
  @include media-breakpoint-up(lg) {
    padding-top: 100%;
    width: 100%;
  }
}
.ratio__xl-1-1 {
  @include media-breakpoint-up(xl) {
    padding-top: 100%;
    width: 100%;
  }
}

/* ==========================================================================
   Responsive YouTube Videos
   ========================================================================== */

// Responsive YouTube Videos
//
// This will work for Youtuve or Vimeo as long as it uses an iframe
//
// Use the class "video-box" on a container div. If you need a max-width set it on a parent element.
//
// Markup:
// <div style="max-width: 760px; margin: 0 auto;">
//   <div class="video-box">
//     <iframe src="https://www.youtube.com/embed/0NtF1ci_fFw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
//     </iframe>
//   </div>
// </div>
//
// Styleguide utilities.r-youtube

.video-box {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
