/* ==========================================================================
   COMMON (Misc My Account Styles)
   ========================================================================== */

.we-print-action {
  display: flex;
  height: 30px;
  background-color: $deep-blue;
  color: $main-white;
  font-size: rem(14);
  padding: rem(7) rem(16);
  margin-top: rem(10);

  @include media-breakpoint-up(md) {
    font-size: rem(18);
    padding: rem(6) rem(16);
    margin-top: rem(8);
  }
}

.account-page-cls {
  min-height: calc(100vh - 135px);

  @include media-breakpoint-up(lg) {
    min-height: 960px;
  }
}