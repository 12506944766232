/* ==========================================================================
   AVERY IMAGE GALLERY MODAL

   NOTE: Avery Modal Styles Extends Bootstrap's Modal Styles.
   ========================================================================== */

.modal.modal-avry.image-gallery-modal {
  padding: 0 rem(10) !important;

  // Sets the Width of the Modal
  .modal-dialog {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
    transform: translate(0, 0);
    position: static;

    @include media-breakpoint-up(md) {
      max-width: 725px;
      margin: 50px auto;
    }

    @include media-breakpoint-up(lg) {
      max-width: 900px;
    }
  }

  $modal-main-image-size: 382px;
  $modal-main-image-size-md-up: 550px;
  $modal-main-image-size-lg-up: 665px;
  .modal-main-image {
    align-items: center;

    img {
      max-height: $modal-main-image-size;
      flex: 0 0 auto;
    }

    @include media-breakpoint-up(md) {
      height: $modal-main-image-size-md-up;
      max-height: 100%;

      img {
        max-height: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      height: $modal-main-image-size-lg-up;
    }
  }

  .modal-body {
    $modal-content-spacing-md-up: 30px;
    $modal-content-spacing-lg-up: 50px;

    @include media-breakpoint-up(md) {
      padding: 0 rem($modal-content-spacing-md-up) rem($modal-content-spacing-md-up);

      .col-modal-content {
        // Main Image Container
        &:first-child {}

        // Thumbnails Container
        //
        // NOTE: The thumbnail container had to be set to a fixed width so it can distribute space equally.
        &:last-child {
          flex: 0 0 74px;
          max-width: 74px;
          margin-right: $modal-content-spacing-md-up;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 0 rem($modal-content-spacing-lg-up) rem($modal-content-spacing-lg-up);

      .col-modal-content {
        // Main Image Container
        &:first-child {}

        // Thumbnails Container
        &:last-child {
          margin-right: $modal-content-spacing-lg-up;
        }
      }
    }
  }

  .fa {
    font-size: rem(36);

    &:hover {
      opacity: 0.5;
    }
  }

  .thumb-prev,
  .thumb-next {
    cursor: pointer;
  }

  /*
     Swiper
     ========================================================================== */
  .mobile-main-gallery-swiper-container {
    margin-bottom: rem(20);
  }

  // Thumbnail Gallery Swiper (Mobile and Desktop)
  .modal-swiper-thumbnails {
    .modal-swiper-container {
      position: relative;
    }

    .thumb-prev,
    .thumb-next {
      position: relative;
      z-index: 10;
    }

    // Horizontal Thumbnails
    &.modal-swiper-horizontal {
      .modal-swiper-container {
        @include media-breakpoint-down(sm) {
          // Fade
          &:after {
            content: "";
            display: block;
            width: 10%;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
            position: absolute;
            bottom: 0;
            right: -1px;
            z-index: 5;
          }
        }
      }
    }

    // Vertical Thumbnails
    &.modal-swiper-vertical {
      .modal-swiper-container {
        // padding: rem(20) rem(2) 0; // NOTE: added padding to offset the gradient edge.

        // NOTE: Hid the Fade for now, will suggest that these are not needed.

        // Fade
        // &:before,
        // &:after {
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 25px;
        //   position: absolute;
        //   left: 0;
        //   z-index: 5;
        // }
        // &:before {
        //   background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        //   top: 0;
        // }
        // &:after {
        //   background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        //   bottom: 0;
        // }
      }
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}
