// Industrial Utilities
//
// styleguide industrial-utilities

/* ==========================================================================
   AVERY INDUSTRIAL - FOCAL ARROW
   ========================================================================== */

// Focal Arrow
//
// A visual element used to direct end-user to specified content
//
// Markup:
// <div class="focal-arrow"></div>
//
// Styleguide industrial-utilities.focal-arrow

.focal-arrow {
  position: relative;
  padding-bottom: rem(32);
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;

  &:after {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: 100%;
    height: map-get($spacers, 4);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDE4IDkiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gcG9pbnRzPSIwIDAgMTggMCA5IDkiIGZpbGw9IiNEN0Q3RDciLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

/* ==========================================================================
   AVERY INDUSTRIAL BACKGROUND UTILITIES
   ========================================================================== */

// Background Color
//
// Add these utility classes to add Industrial background colors to your element(s).
//
// NOTE: These colors are based on the Avery Industrial color palette.
//
// .bg-inds-blue - Industrial Blue Background
// .bg-inds-gray - Industrial Gray Background
// .bg-inds-yellow - Industrial Yellow Background
// .bg-inds-gray-gradient - Industrial Gray Gradien Background
//
// Markup:
// <div class="p-3 my-2 w-100 {{modifier_class}}" style="color: #FFFFFF;">Simply add this `.{{modifier_class}}` class to your element.</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide industrial-utilities.background-colors

.bg-inds-blue {
  background-color: $industrial-blue !important;
}

.bg-inds-gray {
  background-color: $industrial-gray !important;
}

.bg-inds-yellow {
  background-color: $industrial-yellow !important;
}

.bg-inds-gray-gradient {
  // background-blend-mode: multiply !important;
  // NOTE: In Zeplin it was `circle at 100% 0` but I have to reverse it because it uses
  // the `transform: rotate(180deg)` style. We can't use that for this utility because
  // it is going to rotate everything including the content. ¯\_(ツ)_/¯
  // background-image: radial-gradient(circle at 100% 0, rgba($main-white, 0.5), $industrial-gray), linear-gradient(to bottom, $industrial-gray, $industrial-gray) !important;

  // FIXME: Due to issues on gradient rendering issues on firefox and safari, we swill use a solid background color for now.
  background-color: $industrial-gray !important;
}

/* ==========================================================================
   AVERY INDUSTRIAL SVG UTILITIES
   ========================================================================== */

.fill-inds-blue {
  fill: $industrial-blue !important;
}
