// Fonticons
//
// Icons reused in multiple places. i.e. forms and bullet points
//
// Markup:
// <div class="h3 font-weight-normal pt-4 pb-3">Menu Icons</div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--menu"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--menu
//     <br />content: "\e915"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--search"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--search
//     <br/>content: "\e916"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--dash"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--dash
//     <br/>content: "\e90f"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--open"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--open
//     <br/>content: "\e913"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--close"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--close
//     <br/>content: "\e914"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--filter"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--filter
//     <br/>content: "\e909"
//   </div>
// </div>
// <div class="h3 font-weight-normal pt-4 pb-3">Direction</div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--up"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--up
//     <br/>content: "\e80a"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--right"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--right
//     <br/>content: "\e90b"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--down"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--down
//     <br/>content: "\e90c"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--left"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--left
//     <br/>content: "\e90d"
//   </div>
// </div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--up-lg"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--up-lg
//     <br/>content: "\e905"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--right-lg"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--right-lg
//     <br/>content: "\e906"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--down-lg"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--down-lg
//     <br/>content: "\e907"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--left-lg"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--left-lg
//     <br/>content: "\e908"
//   </div>
// </div>
// <div class="h3 font-weight-normal pt-4 pb-3">Bullet Points</div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--circle"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--circle
//     <br/>content: "\e928"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--bullet"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--bullet
//     <br/>content: "\e929"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--info-bull"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--info-bull
//     <br/>content: "\e92f"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--help-bull"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--help-bull
//     <br/>content: "\e92e"
//   </div>
// </div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--right-bull"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--right-bull
//     <br/>content: "\e92d"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--left-bull"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--left-bull
//     <br/>content: "\e92c"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--check-bull"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--check-bull
//     <br/>content: "\e92b"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--check-circle"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--check-circle
//     <br/>content: "\e92a"
//   </div>
// </div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--check-light"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--check-light
//     <br/>content: "\e90a"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--light-bulb"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--light-bulb
//     <br/>content: "\e90e"
//   </div>
//   <div class="col pb-4"></div>
//   <div class="col pb-4"></div>
// </div>
// <div class="h3 font-weight-normal pt-4 pb-3">Form Icons</div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--pencil-box"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--pencil-box
//     <br/>content: "\e91e"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--pencil"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--pencil
//     <br/>content: "\e921"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--lock"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--lock
//     <br/>content: "\e923"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--check"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--check
//     <br/>content: "\e924"
//   </div>
// </div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--error"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--error
//     <br/>content: "\e925"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--envelope"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--envelope
//     <br/>content: "\e922"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--view"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--view
//     <br/>content: "\e917"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--trash"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--trash
//     <br/>content: "\e919"
//   </div>
// </div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--box"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--box
//     <br/>content: "\e926"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--check-box"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--checkbox
//     <br/>content: "\e927"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--map-pin"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--map-pin
//     <br/>content: "\e902"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--reload"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--reload
//     <br/>content: "\e918"
//   </div>
// </div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--tiles"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--tiles
//     <br/>content: "\e903"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--list"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--list
//     <br/>content: "\e902"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--plus"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--plus
//     <br/>content: "\e911"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--minus"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--minus
//     <br/>content: "\e912"
//   </div>
// </div>
// <div class="h3 font-weight-normal pt-4 pb-3">Extraneous</div>
// <div class="row">
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--star"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--star
//     <br/>content: "\e900"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--star-full"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--star-full
//     <br/>content: "\e901"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--times"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--times
//     <br/>content: "\e910"
//   </div>
//   <div class="col pb-4">
//     <div class="h4"><i class="fonticon fonticon--elipsis"></i></div>
//     <hr class="my-2" />
//     fonticon fonticon--elipsis
//     <br/>content: "\e930"
//   </div>
// </div>
//
// Styleguide Fonticons.1
@font-face {
  font-family: 'avery-fonticon';
  src: url('https://www.avery.com/app/fonts/avery-fonticon.ttf') format('truetype'),
    url('https://www.avery.com/app/fonts/avery-fonticon.woff ') format('woff'),
    url('https://www.avery.com/app/fonts/avery-fonticon.woff2') format('woff2'),
    url('https://www.avery.com/app/fonts/avery-fonticon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.fonticon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'avery-fonticon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fonticon--filter:before {
  content: '\e909';
}
.fonticon--star:before {
  content: '\e900';
}
.fonticon--star-full:before {
  content: '\e901';
}
.fonticon--map-pin:before {
  content: '\e902';
}
.fonticon--tiles:before {
  content: '\e903';
}
.fonticon--list:before {
  content: '\e904';
}
.fonticon--up:before {
  content: '\e80a';
}
.fonticon--right:before {
  content: '\e90b';
}
.fonticon--down:before {
  content: '\e90c';
}
.fonticon--left:before {
  content: '\e90d';
}
.fonticon--up-lg:before {
  content: '\e905';
}
.fonticon--right-lg:before {
  content: '\e906';
}
.fonticon--down-lg:before {
  content: '\e907';
}
.fonticon--left-lg:before {
  content: '\e908';
}
.fonticon--times:before {
  content: '\e910';
}
.fonticon--plus:before {
  content: '\e911';
}
.fonticon--minus:before {
  content: '\e912';
}
.fonticon--open:before {
  content: '\e913';
}
.fonticon--dash:before {
  content: '\e90f';
}
.fonticon--close:before {
  content: '\e914';
}
.fonticon--menu:before {
  content: '\e915';
}
.fonticon--search:before {
  content: '\e916';
}
.fonticon--view:before {
  content: '\e917';
}
.fonticon--reload:before {
  content: '\e918';
}
.fonticon--trash:before {
  content: '\e919';
}
.fonticon--pencil-box:before {
  content: '\e91e';
}
.fonticon--pencil:before {
  content: '\e921';
}
.fonticon--envelope:before {
  content: '\e922';
}
.fonticon--lock:before {
  content: '\e923';
}
.fonticon--check:before {
  content: '\e924';
}
.fonticon--error:before {
  content: '\e925';
}
.fonticon--check-circle:before {
  content: '\e92a';
}
.fonticon--check-bull:before {
  content: '\e92b';
}
.fonticon--left-bull:before {
  content: '\e92c';
}
.fonticon--right-bull:before {
  content: '\e92d';
}
.fonticon--help-bull:before {
  content: '\e92e';
}
.fonticon--info-bull:before {
  content: '\e92f';
}
.fonticon--box:before {
  content: '\e926';
}
.fonticon--check-box:before {
  content: '\e927';
}
.fonticon--circle:before {
  content: '\e928';
}
.fonticon--bullet:before {
  content: '\e929';
}
.fonticon--elipsis:before {
  content: '\e930';
}
.fonticon--check-light:before {
  content: '\e90a';
  color: #505050;
}
.fonticon--light-bulb:before {
  content: '\e90e';
}
