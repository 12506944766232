// Industrial Accordion
//
// styleguide industrial-accordion

// Industrial Accordion Theme
//
// An Accordion theme initially seen in industrial. <span class="text-bright-red">Will potentially replace Avery.com accordion styles `.accordion-theme-1`</span>.
//
// **IMPORTANT:** The base accordion(collapse) markup has changed in bootstrap 4 (4.3.1) and is `NOT` compatible with `theme-1`. Please Utilize the corresponding `markup` if you need to use this accordion style.
//
// markup:
// <div class="accordion accordion-avry accordion-theme-2 mb-5" id="cpg-industrial-accordion">
//   <div class="section-header">
//     <h2>Additional Product Details</h2>
//   </div>
//   <div class="card">
//     <div class="card-header" id="heading-0">
//       <h5 class="mb-0">
//         <button class="btn" type="button" data-toggle="collapse" data-target="#collapse-0"
//           aria-expanded="true" aria-controls="collapse-0">
//           Video Demos
//         </button>
//       </h5>
//     </div>
//     <div id="collapse-0" class="collapse show" aria-labelledby="heading-0" data-parent="#cpg-industrial-accordion"
//       style="">
//       <div class="card-body">
//         <div class="container container-fluid-sm-down">
//           Video Demo content goes here...
//         </div>
//       </div>
//     </div>
//   </div>
//   <div class="card">
//     <div class="card-header" id="heading-1">
//       <h5 class="mb-0">
//         <button class="btn collapsed" type="button" data-toggle="collapse"
//           data-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
//           GHS Resources
//         </button>
//       </h5>
//     </div>
//     <div id="collapse-1" class="collapse" aria-labelledby="heading-1" data-parent="#cpg-industrial-accordion" style="">
//       <div class="card-body">
//         <div class="container container-fluid-sm-down">
//           GHS Resources content goes here...
//         </div>
//       </div>
//     </div>
//   </div>
//   <div class="card">
//     <div class="card-header" id="heading-2">
//       <h5 class="mb-0">
//         <button class="btn collapsed" type="button" data-toggle="collapse"
//           data-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
//           Materials
//         </button>
//       </h5>
//     </div>
//     <div id="collapse-2" class="collapse" aria-labelledby="heading-2" data-parent="#cpg-industrial-accordion" style="">
//       <div class="card-body">
//         <div class="container container-fluid-sm-down">
//           Materials content goes here...
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide industrial-accordion.global-set-theme-2

// TODO: When it is time to merge Industrial Accordion styles with Avery.com. Either name this '.accordion-theme-2' or replace all accordions.
// NOTE: These styles apply to both the Angular App and dotCMS.
.accordion-theme-2 {
  // NOTE: ng-bootstrap overrides.
  .card {
    border-radius: 0;

    .card-header {
      background-color: $faint-gray;
      padding: 0;
    }
  }

  .btn {
    width: 100%;
    display: block;
    padding: map-get($spacers, 4) 0;
    font-size: rem(24);
    font-weight: bold;
    text-align: center;
    color: $industrial-blue;

    // Hover State
    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }

    // Icon
    &:after{
      font-family: 'avery-fonticon';
      content: "\e90c";
      display: inline-block;
      font-weight: normal;
      font-size: rem(16);
      margin-left: rem(8);
      transform: rotate(-180deg);
      // TODO: Will define animation/transitions at a later time per UX team.
      // transition: transform 0.25s ease-in-out;
    }
    &.collapsed:after {
      transform: rotate(0);
    }
  }
}
