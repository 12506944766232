// Description Block
//
// styleguide description-block

/* ==========================================================================
   DESCRIPTION-BLOCK COMMON

   NOTE: Common styles for `.description-block`, `.description-block-stacked`
   ========================================================================== */

.description-block,
.description-block-horizontal,
.description-block-reverse,
.description-block-stacked {
  @include description-block-common();
}


/* ==========================================================================
   DESCRIPTION-BLOCK - DEFAULT
   ========================================================================== */

// Description Block - Default
//
// An individual Desription Block. This is the Default Description Block.
//
// markup:
// <div class="container container-fluid-sm-down">
//   <div class="row mb-5">
//     <div class="col-12">
//       <h3>2 Column Description Blocks Sample</h3>
//     </div>
//     <div class="col-12 col-md-6">
//       <div class="description-block">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-md-6">
//       <div class="description-block">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details text-main-long">
//             <p class="text-truncate">Free, Easy-to-Use Design Software</p>
//           </div>
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//           <button class="btn btn-avry">Start Designing</button>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div class="row">
//     <div class="col-12">
//       <h3>3 Column Description Blocks Sample</h3>
//     </div>
//     <div class="col-12 col-md-4">
//       <div class="description-block">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details">
//             <ul>
//               <li>One</li>
//               <li>Two</li>
//               <li>Three</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-md-4">
//       <div class="description-block">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-md-4">
//       <div class="description-block">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide description-block.description-block
.description-block {
  @include description-block-collapsable(116px, false);
}


/* ==========================================================================
   DESCRIPTION-BLOCK - FULL
   ========================================================================== */

// Description Block - Full
//
// An Description Block Element that contains a full width background for [MD, LG, XL] breakpoints and a top image for [XS and SM] breakpoints.
//
// **NOTE:** You can use this as a banner by wrapping this in a `<section class="banner-avry"></section>`
//
// markup:
// <div class="container container-fluid-sm-down">
//   <div class="row">
//     <div class="col-12">
//       <div class="description-block-full">
//         <div class="description-block-img-bg text-center text-md-left">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_1200/web/industrial/landing-page/ultra-duty-chemical-labels/FPO_GHS" media="(min-width: 768px) and (max-width: 992px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_2800/web/industrial/landing-page/ultra-duty-chemical-labels/FPO_GHS" media="(min-width: 993px)">
//             <img class="d-none d-md-block" style="max-width: 1110px;" src="https://img.avery.com/f_auto,q_auto,c_scale,w_2800/web/industrial/landing-page/ultra-duty-chemical-labels/FPO_GHS" alt="" />
//           </picture>
//           <div class="row">
//             <div class="col-md-5 offset-md-7">
//               <div class="description-block-img d-md-none">
//                 <img class="img-fluid" src="https://img.avery.com/f_auto,q_auto,c_scale,w_1200/web/industrial/landing-page/ultra-duty-chemical-labels/FPO_GHS" alt="">
//               </div>
//               <div class="description-block-text">
//                 <div class="description-text description-text-details text-main-long w-100">
//                   <p class="text-truncate">Are Your Chemical Labels OSHA-GHS Compliant?</p>
//                 </div>
//                 <div class="description-text description-text-details description-compact">
//                   <ul class="pl-3">
//                       <li>Easily create labels using free GHS Wizard&reg; online software</li>
//                       <li>Print using your desktop printer or let us print it for you</li>
//                       <li>Labels are waterproof &amp; resistant to chemicals, UV, abrasion</li>
//                       <li>BS5609 Certified - <a href="#" class="text-link">click here for details</a></li>
//                   </ul>
//                 </div>
//                 <button class="btn btn-avry btn-action">Get a Free Sample</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide description-block.description-block-reverse-full-display
.description-block-full {
  $min-height: 316px;

  @include media-breakpoint-up(md) {
    min-height: $min-height;
  }

  .description-block-img-bg {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      min-height: $min-height;

      img {
        position: absolute;
        min-height: $min-height;
      }
    }
  }
  .description-block-img {
    img {
      width: 100%;
    }
  }
  .description-block-text {
    @include media-breakpoint-down(sm) {
      margin: rem(20) 0 rem(30);
    }

    @include media-breakpoint-up(md) {
      display: flex;
      height: $min-height;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}


/* ==========================================================================
   DESCRIPTION-BLOCK - REVERSE
   ========================================================================== */

// Description Block - Reverse
//
// A reversed version of the DEFAULT `Description Block` where it is horizontal on MD, LG, and XL breakpoints but stacked/collapsed on XS and SM breakpoints.
//
// markup:
// <div class="container container-fluid-sm-down">
//   <div class="row">
//     <div class="col-12 col-md-6">
//       <div class="description-block-reverse">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details text-main-long">
//             <p class="text-truncate">Free, Easy-to-Use Design Software</p>
//           </div>
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide description-block.description-block-reverse
.description-block-reverse {
  @include description-block-collapsable(auto, false, true);
}

/* ==========================================================================
   DESCRIPTION-BLOCK-HORIZONTAL
   ========================================================================== */

// Description Block - Horizontal
//
// A description block that is horizontal (or `Collapsed`) in all breakpoints: XS, SM, MD, LG, XL.
//
// markup:
// <div class="container container-fluid-sm-down">
//   <div class="row">
//     <div class="col-12 col-md-6">
//       <div class="description-block-horizontal">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details text-main-long">
//             <p class="text-truncate">Free, Easy-to-Use Design Software</p>
//           </div>
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui
//               est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide description-block.description-block-horizontal
.description-block-horizontal {
  @include description-block-collapsable(auto, false, true, true);
}

/* ==========================================================================
   DESCRIPTION-BLOCK-STACKED
   ========================================================================== */

// Description Block Stacked
//
// A Variation of the Description Block, except this description block remains stacked in all breakpoints.
//
// markup:
// <div class="container container-fluid-sm-down">
//   <div class="row mb-5">
//     <div class="col-12 col-md-6">
//       <div class="description-block-stacked">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-md-6">
//       <div class="description-block-stacked">
//         <div class="description-block-img">
//           <picture>
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//           </picture>
//         </div>
//         <div class="description-block-text">
//           <div class="description-text description-text-details text-main-long">
//             <p class="text-truncate">Free, Easy-to-Use Design Software</p>
//           </div>
//           <div class="description-text description-text-details">
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio cupiditate non temporibus nisi iste? Qui est, autem. Quod voluptatum, alias eaque fugiat, dolore eveniet unde ea aliquam est, excepturi beatae.</p>
//           </div>
//           <button class="btn btn-avry">Start Designing</button>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide description-block.description-block-stacked
.description-block-stacked {
  &.stacked-divider {
    @include media-breakpoint-down(sm) {
      border-bottom: solid $divider-weight $divider-color;
      padding: 0 0 rem(20);
    }
  }

  .description-block-img {
    margin: 0;

    img {
      width: 100%;
    }
  }
  .description-block-text {
    margin-top: rem(20);
  }
}

/* ==========================================================================
   DESCRIPTION BLOCK GROUP
   ========================================================================== */

// Description Block Group
//
// A group of Description Blocks
//
// markup:
// <section class="description-block-group">
//   <div class="container container-fluid-sm-down">
//     <div class="row">
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <ul>
//                 <li>One</li>
//                 <li>Two</li>
//                 <li>Three</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <ul>
//                 <li>One</li>
//                 <li>Two</li>
//                 <li>Three</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <ul>
//                 <li>One</li>
//                 <li>Two</li>
//                 <li>Three</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <ul>
//                 <li>One</li>
//                 <li>Two</li>
//                 <li>Three</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque earum in aperiam aspernatur ut. Debitis nostrum, sint odit ipsa necessitatibus alias enim amet, perspiciatis ex, iure quis. Rerum voluptas, maxime?</p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <ul>
//                 <li>One</li>
//                 <li>Two</li>
//                 <li>Three</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="description-block">
//           <div class="description-block-img">
//             <picture>
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//               <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//               <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//             </picture>
//           </div>
//           <div class="description-block-text">
//             <div class="description-text description-text-details">
//               <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque earum in aperiam aspernatur ut. Debitis nostrum, sint odit ipsa necessitatibus alias enim amet, perspiciatis ex, iure quis. Rerum voluptas, maxime?</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
//
// styleguide description-block.description-block-group
.description-block-group {
  padding: rem($grid-gutter-width) 0 0;

  .container>.row>[class*="col-"] {
    margin: 0 0 rem($grid-gutter-width);

    @include media-breakpoint-down(sm) {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $base-border-color;
      }
    }
  }
}
