/* ==========================================================================
   WEPRINT TILES - IMG SPACED

   NOTE: A variation of the info description tile.
   ========================================================================== */

//-// WePrint Tiles - Img Spaced
//-//
//-// Utilizes the [Info Description Tile (Spaced)](section-tiles.html#kssref-tiles-info-description-spaced) class as its base. It contains WePrint Category Tile specific Styles.
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12 col-md-4">
//-//     <a class="text-decoration-none" href="">
//-//       <div class="tile-item tile-info-description-img-spaced tile-weprint-img-spaced tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate">Rectangle Labels</p>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Make your products, promotions and personal events stand out in a crowd.</p>
//-//           </div>
//-//           <div class="tile-text tile-action">
//-//             <div class="row align-items-center">
//-//               <div class="col-12 col-md-6">
//-//                 <span class="font-italic">Starting at only &nbsp;</span><strong>$7.00</strong>
//-//               </div>
//-//               <div class="col-12 col-md-6">
//-//                 <button class="btn btn-avry">Start Now</button>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide weprint.weprint-img-spaced

// FIXME: Deprecated - Remove `.tile-weprint-img-spaced` and all its nested styles once verified not/no longer used.
.tile-weprint-img-spaced {
  @include media-breakpoint-down(sm) {
    $tile-height: 175px;

    height: $tile-height;
    .tile-img {
      img {
        max-width: $tile-height;
      }
    }
  }

  .tile-text {
    $text-details-height: 40px;

    &.text-details {
      height: $text-details-height;
      min-height: auto;
    }
    & .text-truncate-multiline {
      max-height: $text-details-height;
    }
  }
  .tile-action {
    margin-top: rem(10);
  }
  .btn.btn-avry {
    width: 100%;

    @include media-breakpoint-down(sm) {
      max-width: 115px;
      margin-top: rem(10);
    }
  }
}
