@use "sass:math";

/* ==========================================================================
   TILE MY ACCOUNT ITEM (Grid) ONLY - Utilizes Bootstrap's Card Component
   ========================================================================== */

//-// My Account Tile - Grid
//-//
//-// A Grid Tile used in the Project and Files pages of My Account.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div class="container mx-auto">
//-//   <div class="row">
//-//     <div class="col-md-4 col-xl-3">
//-//       <div class="tile-my-account tile-item tile-grid-item">
//-//         <div class="card tile-container">
//-//           <div class="card-header">
//-//             <div class="flexible-img flexible-img-vertical">
//-//               <img class="tile-img img-fluid img-fluid-height" src="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg">
//-//             </div>
//-//           </div>
//-//           <div class="card-body tile-block">
//-//             <div class="card-text tile-text text-main-long">
//-//               <p class="text-truncate-multiline">name-Badge-64404-01</p>
//-//             </div>
//-//             <div class="card-text tile-rating">
//-//             </div>
//-//             <div class="card-text tile-text text-details">
//-//               <div class="detail-content detail-size-and-shape">
//-//                 <p class="text-truncate">3" x 4" (in.)</p>
//-//                 <p class="text-truncate">Hanging Name Badges</p>
//-//                 <p class="text-truncate revised-details">Last Saved May 15, 2015</p>
//-//               </div>
//-//             </div>
//-//           </div>
//-//           <div class="card-footer we-print-action-footer">
//-//             <div class="row">
//-//               <div class="col-12">
//-//                 <div class="we-print-action">
//-//                   <object class="we-print-svg-logo" data="https://www.avery.com/assets/svg/weprint_logo.svg" style="width: 70px;" type="image/svg+xml">
//-//                     <img src="assets/svg/fallback/weprint_logo.png">
//-//                   </object>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//     <div class="col-md-4 col-xl-3">
//-//       <div class="tile-my-account tile-item tile-grid-item">
//-//         <div class="card tile-container">
//-//           <div class="card-header">
//-//             <div class="flexible-img flexible-img-vertical">
//-//               <img class="tile-img img-fluid img-fluid-height" src="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg">
//-//             </div>
//-//           </div>
//-//           <div class="card-body tile-block">
//-//             <div class="card-text tile-text text-main-long">
//-//               <p class="text-truncate-multiline">Holiday 2in sq - 64447</p>
//-//             </div>
//-//             <div class="card-text tile-rating">
//-//             </div>
//-//             <div class="card-text tile-text text-details">
//-//               <div class="detail-content">
//-//                 <p class="text-truncate">&nbsp;</p>
//-//                 <p class="text-truncate">&nbsp;</p>
//-//                 <p class="text-truncate revised-details">Last Saved Jul 14, 2015</p>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.my-account-grid

// FIXME: Deprecated - Review `.tile-my-account.tile-grid-item .tile-container`. Anticipating new style updates.
.tile-my-account.tile-grid-item .tile-container {
  @include tile-container-grid-common();

  .tile-text {
    &.text-main-long {
      $height: 38px;

      height: $height;
      margin-top: rem(10);

      & > p {
        max-height: $height;
        margin-right: 0;
      }
    }
    &.text-details {
      font-size: rem(16);
      line-height: 1.3;

      p {
        margin: 0;
      }

      .detail-content {
        height: 75px;
      }
    }
  }

  .card-footer {
    background-color: $deep-blue;
    color: $main-white;
    padding: 0;

    &.we-print-action-footer {
      .we-print-action {
        background: none;
      }
    }
  }
}

/* ==========================================================================
   TILE MY ACCOUNT ITEM (List) ONLY - Utilizes Bootstrap's Card Component
   ========================================================================== */

//-// My Account Tile - List
//-//
//-// A List Tile used in the Project and Files pages of My Account.
//-//
//-// Markup:
//-// <div class="container mx-auto">
//-//   <div class="row">
//-//     <div class="col-12">
//-//       <div class="tile-my-account tile-item tile-list-item">
//-//         <div class="tile-container">
//-//           <div class="row no-gutters full-height">
//-//             <div class="col-5 col-md-3 full-height gutter-top gutter-bottom col-list-item-image">
//-//               <div class="flexible-img flexible-img-horizontal">
//-//                 <img class="tile-img img-fluid img-fluid-height" src="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg">
//-//               </div>
//-//             </div>
//-//             <div class="col-7 col-md-9 full-height gutter-top gutter-bottom col-list-item-detail">
//-//               <div class="tile-block full-height">
//-//                 <div class="row full-height-md-up align-items-center">
//-//                   <div class="col-12 col-md-5">
//-//                     <div class="tile-text text-main-long">
//-//                       <p class="text-truncate-multiline">name-Badge-64404-01</p>
//-//                     </div>
//-//                   </div>
//-//                   <div class="col-12 col-md-4">
//-//                     <div class="tile-text text-details">
//-//                       <div class="detail-content detail-size-and-shape">
//-//                         <p class="text-truncate">3" x 4" (in.)</p>
//-//                         <p class="text-truncate">Hanging Name Badges</p>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                   <div class="col-12 col-md-3">
//-//                     <div class="tile-text text-details we-print-project">
//-//                       <div class="detail-content">
//-//                         <p class="text-truncate revised-details">Last Saved <span class="d-md-block">May 15, 2015</span></p>
//-//                       </div>
//-//                         <div class="we-print-action">
//-//                         <object class="we-print-svg-logo" data="https://www.avery.com/assets/svg/weprint_logo.svg" style="width: 70px;" type="image/svg+xml">
//-//                           <img src="assets/svg/fallback/weprint_logo.png">
//-//                         </object>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//     <div class="col-12">
//-//       <div class="tile-my-account tile-item tile-list-item">
//-//         <div class="tile-container">
//-//           <div class="row no-gutters full-height">
//-//             <div class="col-5 col-md-3 full-height gutter-top gutter-bottom col-list-item-image">
//-//               <div class="flexible-img flexible-img-horizontal">
//-//                 <img class="tile-img img-fluid img-fluid-height" src="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg">
//-//               </div>
//-//             </div>
//-//             <div class="col-7 col-md-9 full-height gutter-top gutter-bottom col-list-item-detail">
//-//               <div class="tile-block full-height">
//-//                 <div class="row full-height-md-up align-items-center">
//-//                   <div class="col-12 col-md-5">
//-//                     <div class="tile-text text-main-long">
//-//                       <p class="text-truncate-multiline">Holiday 2in sq - 64447</p>
//-//                     </div>
//-//                   </div>
//-//                   <div class="col-12 col-md-4">
//-//                     <div class="tile-text text-details">
//-//                       <div class="detail-content">
//-//                         <p class="text-truncate"> (in.)</p>
//-//                         <p class="text-truncate">&nbsp;</p>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                   <div class="col-12 col-md-3">
//-//                     <div class="tile-text text-details">
//-//                       <div class="detail-content">
//-//                         <p class="text-truncate revised-details">Last Saved <span class="d-md-block">Jul 14, 2015</span></p>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.my-account-list

// FIXME: Deprecated - Review `.tile-my-account.tile-list-item .tile-container`. Anticipating new style updates.
.tile-my-account.tile-list-item .tile-container {
  height: 150px;

  @include tile-container-list-common();

  .tile-block {
    // Right Spacing for XS and SM List Tile Blocks
    @include media-breakpoint-down(sm) {
      padding-right: rem(math.div($grid-gutter-width, 2)) !important;
    }
  }

  .tile-text {
    &.text-main-long {
      @include media-breakpoint-down(sm) {
        $height: 38px;

        height: $height;

        & > p {
          max-height: $height;
          margin: 0;
        }
      }

      @include media-breakpoint-only(md) {
        max-height: 58px;
      }

      @include media-breakpoint-up(lg) {
        max-height: 65px;
        font-size: rem(18);
      }
    }

    &.text-details {
      @include media-breakpoint-up(lg) {
        font-size: rem(16);
      }
    }
  }
}
