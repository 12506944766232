/* ==========================================================================
   AVERY MY ACCOUNT MODAL - Specific Styles

   NOTE: Since ng-bootstrap modal component and the 'project-modal' component are encapsulated, 'project-modal' specific styles are moved here at global level.
   ========================================================================== */

.modal.modal-avry.my-account-modal {
  .main-image-container {
    height: 300px;

    @include media-breakpoint-up(md) {
      padding: rem($grid-gutter-width);
    }

    img {
      max-height: 100%;
    }
  }
}

.modal.modal-avry.modal-project-upload {
  .modal-dialog {
    width: 284px;
    min-width: 0px;
  }
}

.modal-action-confirm-name {
  display: none;

  .btn.btn-avry {
    min-width: 100px;
    margin-bottom: rem(10);
  }
}

.modal-action-open,
.modal-action-download {
  .btn.btn-avry {
    min-width: 215px;
    margin-top: rem(20);
    margin-bottom: rem(20);
  }
}

.modal-action-overlay {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba($main-white,.75);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;

  @include border-radius(10px);

  .confirm-label {
    font-size: rem(18);
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: rem(26);
    }
  }

  .overlay-content {
    max-width: 185px;
    margin-left: auto;
    margin-right: auto;
    padding: rem(20);
    text-align: center;
    background-color: rgba($main-black,.75);
    color: $main-white;
    position: relative;
    top: 12%;

    @include media-breakpoint-up(md) {
      max-width: 380px;
      padding: rem(50) rem(30);
    }

    @include border-radius(15px);
  }

  .btn.btn-avry {
    min-width: 140px;
    margin-top: rem(16);

    @include media-breakpoint-up(md) {
      min-width: 0;
      width: 100%;
      margin-top: rem(40);
    }
  }
}

.modal.modal-avry.order-detail-modal {
  .modal-dialog {
    max-width: 750px;
    min-width: 0;

    @include media-breakpoint-down(sm) {
      margin-left: $modal-dialog-margin;
      margin-right: $modal-dialog-margin;
    }

    @include media-breakpoint-up(md) {
      min-width: 575px;
    }
  }
}
