// Toggle Switch
//
// Bootstraps native toggle switches with slight re-styling. The input id must match the lable for attribute.
//
// Markup:
// <div class="custom-control custom-switch">
//   <input type="checkbox" class="custom-control-input" id="customToggle1" aria-label="Toggle Value">
//   <label class="custom-control-label" for="customToggle1">
//     Toggle Value
//   </label>
// </div>
//
// Styleguide forms.toggle

.custom-switch {
  .custom-control-label {
    align-content: center;
    display: flex;
    flex-direction: column;
    height: rem(24);
    justify-content: center;

    &::before {
      background-color: $main-white;
      border-radius: rem(20);
      border-color: $gray-medium;
      width: rem(30);
      height: rem(20);
    }
    &::after {
      width: rem(18);
      height: rem(18);
      box-shadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.4);
      border-radius: 100%;
      background-color: $main-white;
      margin-top: rem(-1);
      margin-left: rem(-1);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      transform: translateX(rem(12));
    }
    &::before {
      border-color: $bright-green;
      background-color: $bright-green;
    }
  }
}

// Angular Toggle Switch
//
// To use the toggle switch in Angular you need the toggleSwitch() function
//
// <div style="border-left: 3px solid grey; padding-left: 15px; margin-left: 15px;">
// <pre><span style="color:grey"><span style="color:indianred">import</span> <span style="color:black">{</span> Component <span style="color:black">}</span> <span style="color:indianred">from</span> '@angular/core';
//
// <span style="color:purple">@Component</span><span style="color:black">({</span>
//   &nbsp;&nbsp;<span style="color:cornflowerblue">selector</span>: '<span style="color:green">page</span>',
//   &nbsp;&nbsp;<span style="color:cornflowerblue">template</span>: &#96;
//     &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="custom-control custom-switch"&gt;
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="checkbox" class="custom-control-input"
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;id="customsize2"
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;attr.aria-label="<span style="color:green">{{toggleValue}}</span>"
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[checked]="<span style="color:green">selected</span>"
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[disabled]="disableFlag"
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(change)="<span style="color:orange">toggleSwitch(</span><span style="color:green">selected.target.checked</span><span style="color:orange">)</span>"&gt;
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;label class="custom-control-label" for="customsize2"&gt;
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:green">{{toggleValue}}</span>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/label&gt;
//     &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
//   &nbsp;&nbsp;&#96;
// <span style="color:black">})</span>
// &nbsp;
// <span style="color:indianred">export class</span> <span style="color:green">PageComponent</span> <span style="color:black">{</span>
//   &nbsp;&nbsp;@Output() itemValue = new EventEmitter<boolean>();
//   &nbsp;&nbsp;toggleValue: string = 'Off';
//   &nbsp;
//   &nbsp;&nbsp;<span style="color:orange">toggleSwitch(<span style="color:green">selected: boolean</span><span style="color:orange">) {
//     &nbsp;&nbsp;&nbsp;&nbsp;this.toggleValue = selected ? 'On' : 'Off';
//     &nbsp;&nbsp;&nbsp;&nbsp;this.itemValue.emit(selected);
//   &nbsp;&nbsp;}</span>
// <span style="color:black">}</span></span></pre>
// </div>
//
// Styleguide forms.toggle2
