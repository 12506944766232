/* ==========================================================================
   BOXED-GRID
   ========================================================================== */

// Boxed Grid
//
// A grid layout that replicates a `<table>` but is actually using bootstraps grid layout. These will stack on configured breakpoints.
//
// Markup:
// <div class="boxed-grid">
//   <div class="row boxed-grid-row">
//     <div class="col-md-8 boxed-grid-title">BS5609 Sec. 3 Certified Inkjet Printers</div>
//     <div class="col-md-4 boxed-grid-title">Important Notes</div>
//   </div>
//   <div class="row boxed-grid-row">
//     <div class="col-md-12 boxed-grid-sub-title">UltraDuty GHS Labels: 60511, 60512, 60513</div>
//   </div>
//   <div class="row boxed-grid-row">
//     <div class="col-md-4">
//       <p class="top-buffer"><strong>Epson<sup>®</sup> ColorWorks C831 (GP-C831)</strong></p>
//       <p>(Pin-fed Fanfold Labels Only)</p>
//     </div>
//     <div class="col-md-4">
//       <p class="top-buffer"><img src="https://stg-cms.avery.com/app/images/dpo/ghs_bs5609_printer_epson-colorworks.jpg" alt="Epson C831 Printer" class="img-fluid"></p>
//     </div>
//     <div class="col-md-4">
//       <p class="top-buffer">Please use the "Label" setting when printing for BS5609 Sec. 3 certification</p>
//     </div>
//   </div>
// </div>
//
// Styleguide table.common-boxed-grid

// TODO: Move these styles with `table`
.boxed-grid {
  .boxed-grid-row {
    margin: 0;
    border-top: 1px solid $base-border-color;
    border-right: 1px solid $base-border-color;
    border-left: 1px solid $base-border-color;

    > [class*="col-"] {
      padding: rem(10);
      font-size: rem(13);
      line-height: 1.5;

      @include media-breakpoint-up(md) {
        &:not(:last-child) {
          border-right: 1px solid $base-border-color;
        }
      }

      &.boxed-grid-title {
        background: $light-powder-blue;
        font-weight: bold;
        border-right: none;
      }

      &.boxed-grid-sub-title {
        background: $light-gray;
      }
    }

    &:last-child {
      border-bottom: 1px solid $base-border-color;
    }
  }
}
