@use "sass:math";

// Dropdowns
//
// NOTE: Avery Dropdown Styles should be used along with Bootstrap's Dropdown Styles.
//
// **Instructions:** For Accessability, Make sure there is a label with an id **id=""** that matches the **aria-labelledby=""** on the relevant button or imput. Use the class **sr-only** if you don't want the label to be visible, but always have a label.
//
// Active state on hover or using the **active** class.
//
// Create a special item with the **dropdown-item--special** class.
//
// Prevent Hover state with the **dropdown-item--no-hover** class.
//
// markup:
// <label id="someID">Drop Down</label>
// <div class="row pb-4">
//   <div class="col-4">
//     <div class="dropdown">
//       <button class="dropdown-toggle" type="button" id="FirstExample" data-toggle="dropdown" aria-labelby="someID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="FirstExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//   </div>
//   <div class="col-4">
//     <input class="form-control w-100" type="email" />
//   </div>
//   <div class="col-4">
//     <button class="cta cta--secondary cta--blue">Button</button>
//   </div>
// </div>
// <label id="someID">Small Drop Down</label>
// <div class="row pb-4">
//   <div class="col-4">
//     <div class="dropdown dropdown--sm">
//       <button class="dropdown-toggle" type="button" id="smExample" data-toggle="dropdown" aria-labelby="smID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="smExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//   </div>
//   <div class="col-4">
//     <input class="form-control form-control-sm w-100" type="email" />
//   </div>
//   <div class="col-4">
//     <button class="cta cta--secondary cta--blue cta--sm">Button</button>
//   </div>
// </div>
// <label id="someID">Large Drop Down</label>
// <div class="row pb-4">
//   <div class="col-4">
//     <div class="dropdown dropdown--lg">
//       <button class="dropdown-toggle" type="button" id="lgExample" data-toggle="dropdown" aria-labelby="lgID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="lgExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//   </div>
//   <div class="col-4">
//     <input class="form-control form-control-lg w-100" type="email" />
//   </div>
//   <div class="col-4">
//     <button class="cta cta--secondary cta--blue cta--lg">Button</button>
//   </div>
// </div>
// <label id="someID">Extra Large Drop Down</label>
// <div class="row pb-4">
//   <div class="col-4">
//     <div class="dropdown dropdown--xl">
//       <button class="dropdown-toggle" type="button" id="xlExample" data-toggle="dropdown" aria-labelby="xlID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="xlExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//   </div>
//   <div class="col-4">
//     <input class="form-control form-control-xl w-100" type="email" />
//   </div>
//   <div class="col-4">
//     <button class="cta cta--secondary cta--blue cta--xl">Button</button>
//   </div>
// </div>
// <div class="row">
//   <div class="col-6">
//     <br>
//     <br>
//     <label id="differentID">DropUp</label>
//     <div class="dropup">
//       <button class="dropdown-toggle" type="button" id="dropUpExample" data-toggle="dropdown" aria-labelby="differentID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="dropUpExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//     <br>
//     <br>
//     <label id="anotherID">Disabled Dropdown</label>
//     <div class="dropdown">
//       <button disabled class="dropdown-toggle" type="button" id="disabledExample" data-toggle="dropdown" aria-labelby="anotherID" aria-haspopup="true" aria-expanded="false">
//         Disabled Dropdown
//       </button>
//       <div class="dropdown-menu" aria-labelledby="disabledExample">
//         <a class="dropdown-item" href="#">Should not show up</a>
//       </div>
//     </div>
//     <br>
//     <br>
//     <label id="yetAnotherID">Stripes</label>
//     <div class="dropdown w-100">
//       <button class="dropdown-toggle" type="button" id="fullWidthExample" data-toggle="dropdown" aria-labelby="yetAnotherID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu dropdown-menu--stripes" aria-labelledby="fullWidthExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//     <br>
//     <br>
//     <label id="yetAnotherID">Special Dropdown Item</label>
//     <div class="dropdown w-100">
//       <button class="dropdown-toggle" type="button" id="fullWidthExample" data-toggle="dropdown" aria-labelby="yetAnotherID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="fullWidthExample">
//         <a class="dropdown-item dropdown-item--special dropdown-item--no-hover" href="#">Special Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//     <br>
//     <br>
//     <label id="yetAnotherID">Error using the class <b>dropdown--red</b></label>
//     <div class="dropdown dropdown--red w-100">
//       <button class="dropdown-toggle" type="button" id="fullWidthExample" data-toggle="dropdown" aria-labelby="yetAnotherID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="fullWidthExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//     <br>
//     <br>
//     <label id="yetAnotherID">Success using the class <b>dropdown--green</b></label>
//     <div class="dropdown dropdown--green w-100">
//       <button class="dropdown-toggle" type="button" id="fullWidthExample" data-toggle="dropdown" aria-labelby="yetAnotherID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="fullWidthExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//   </div>
// </div>
// <br/>On Background colors
// <div class="p-3 bg-faint-gray">
//   <label id="someID">On faint gray background</label>
//   <div class="dropdown mb-5">
//     <button class="dropdown-toggle" type="button" id="smFirstExample" data-toggle="dropdown" aria-labelby="someID" aria-haspopup="true" aria-expanded="false">
//       Action
//     </button>
//     <div class="dropdown-menu" aria-labelledby="smFirstExample">
//       <a class="dropdown-item" href="#">Item</a>
//       <a class="dropdown-item" href="#">Item</a>
//       <a class="dropdown-item" href="#">Item</a>
//     </div>
//   </div>
//   <div class="m-3 px-3 bg-almost-white border">
//     <label id="someID">on almost white background</label>
//     <div class="dropdown mb-3">
//       <button class="dropdown-toggle" type="button" id="smFirstExample" data-toggle="dropdown" aria-labelby="someID" aria-haspopup="true" aria-expanded="false">
//         Action
//       </button>
//       <div class="dropdown-menu" aria-labelledby="smFirstExample">
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//         <a class="dropdown-item" href="#">Item</a>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide forms.dropdowns

$form-box-shadow-down: 0 rem(2) rem(6) 0 rgba(0, 0, 0, 0.5);
$form-box-shadow-up: 0 0 rem(6) 0 rgba(0, 0, 0, 0.5);

$margin: 8;
$marginSM: 5;
$marginLG: 10;
$marginXL: 14;

.dropdown {
  &.dropdown--green > button {
    background-color: $light-green !important;
    border-color: $bright-green !important;

    &::before {
      background-color: $bright-green !important;
    }
  }

  &.dropdown--red > button {
    background-color: $light-red !important;
    border-color: $bright-red !important;

    &::before {
      background-color: $bright-red !important;
    }
  }
}

.dropdown-toggle {
  background-color: $main-white;
  border: rem(1) solid $charcoal-black;
  border-radius: rem(3);
  color: $base-dark;
  display: inline-block;
  font-size: rem(14);
  line-height: 1.3;
  padding: rem($margin) rem(35) rem($margin) rem(12);
  position: relative;
  text-align: left !important;
  vertical-align: middle;
  width: 100%;

  &:focus,
  &:active:focus {
    outline: none;
  }

  &::after {
    font-family: avery-fonticon;
    height: auto !important;
    border: 0 !important;
    margin-right: rem(16);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;

    .dropdown & {
      content: '\e90c';
    }

    .dropup & {
      content: '\e80a';
    }
    .dropdown.show &,
    .dropup.show & {
      transform: rotate(180deg) translate(0, 50%);
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }

  &::before {
    .dropdown.show &,
    .dropup.show & {
      background-color: #fff;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      height: 7px;
      width: 100%;
      z-index: 801;
    }

    .dropdown & {
      bottom: 0;
    }

    .dropup & {
      top: 0;
    }
  }

  .dropdown.show & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: $form-box-shadow-up !important;
  }

  .dropup.show & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: $form-box-shadow-down !important;
  }
}

.dropdown-item {
  font-size: rem(14);
  padding: rem(4) rem(22);
}

.dropdown-menu {
  border: rem(1) solid $charcoal-black;
  border-radius: rem(3);
  width: 100%;
  min-width: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin: rem(-1) 0 !important;
  z-index: 800;

  .dropdown & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: $form-box-shadow-down;
  }

  .dropup & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: $form-box-shadow-up;
  }

  .dropdown-item {
    white-space: normal;

    &:active,
    &:focus,
    &:hover,
    &.active {
      background-color: $light-blue !important;
      color: $base-dark;
    }

    &.dropdown-item--no-hover {
      &:hover,
      &:active,
      &:focus {
        background-color: $main-white !important;
        cursor: default;
      }
    }

    &.disabled {
      background-color: $gray-medium;
      cursor: not-allowed !important;
      opacity: 0.4;
    }

    .dropdown & {
      border-bottom: rem(1) solid $base-gray;

      &:last-child {
        border-bottom-width: 0 !important;
      }
    }

    .dropup & {
      border-top: rem(1) solid $base-gray;

      &:first-child {
        border-top-width: 0 !important;
      }
    }
  }

  &.dropdown-menu--stripes .dropdown-item {
    &:nth-child(even) {
      background-color: $faint-gray;
    }
  }
}

.dropdown--sm {
  .dropdown-toggle {
    font-size: rem(12);
    letter-spacing: rem(0.5);
    padding: rem($marginSM) rem(35) rem($marginSM) rem(12);
  }
  .dropdown-item {
    font-size: rem(12);
    letter-spacing: rem(0.5);
    padding: rem(math.div($marginSM, 2)) rem(22);
  }
}

.dropdown--lg {
  .dropdown-toggle {
    font-size: rem(16);
    letter-spacing: rem(0.67);
    padding: rem($marginLG) rem(58) rem($marginLG) rem(12);
    white-space: initial;

    &::after {
      font-size: rem(23);
      right: rem(4);
    }
  }

  .dropdown-item {
    font-size: rem(16);
    letter-spacing: rem(0.67);
    padding: rem($marginLG) rem(12);
  }
}

.dropdown--xl {
  .dropdown-toggle {
    font-size: rem(18);
    letter-spacing: rem(0.6);
    line-height: 1.11;
    padding: rem($marginXL) rem(35) rem($marginXL) rem(14);
    white-space: initial;

    &::after {
      font-size: rem(23);
      right: rem(4);
    }
  }

  .dropdown-item {
    font-size: rem(16);
    letter-spacing: rem(0.67);
    padding: rem(math.div($marginXL, 2)) rem(22);
  }
}

.dropdown-item--special {
  background-color: $sky-blue;

  &.dropdown-item--no-hover {
    &:hover,
    &:active,
    &:focus {
      background-color: $sky-blue !important;
    }
  }
}

// Angular Dropdowns
//
// ng-bootstrap fills in a lot of the classes for you. And the ngbDropdownItem can be anything: a <b>div</b> or a <b>button</b> or an <b>a</b>-tag.
//
// To make it a drop up rather than down:<ul>
//   <li>Change the class <b>dropdown</b> to <b>dropup</b></li>
//   <li>Add <b>placement="top-right"</b> to the same element</li>
// </ul>
//
// <div style="border-left: 3px solid grey; padding-left: 15px; margin-left: 15px; color: black;">
// <pre>
// <span style="color:grey">&lt;label</span> id="<span style="color:limegreen">someID</span>"<span style="color:grey">&gt;</span><span style="color:cornflowerblue">Drop Down Label</span><span style="color:grey">&lt;/label&gt;</span>
//
// <span style="color:grey">&lt;div</span>
//   &nbsp;&nbsp;class="dropdown"
//   &nbsp;&nbsp;<span style="color:indianred">ngbDropdown</span>
// <span style="color:grey">&gt;</span>
//   &nbsp;&nbsp;<span style="color:grey">&lt;button</span>
//     &nbsp;&nbsp;&nbsp;&nbsp;aria-expanded="false"
//     &nbsp;&nbsp;&nbsp;&nbsp;aria-haspopup="true"
//     &nbsp;&nbsp;&nbsp;&nbsp;aria-labelby="<span style="color:limegreen">someID</span>"
//     &nbsp;&nbsp;&nbsp;&nbsp;data-toggle="dropdown"
//     &nbsp;&nbsp;&nbsp;&nbsp;id="<span style="color:green">smFirstExample</span>"
//     &nbsp;&nbsp;&nbsp;&nbsp;type="button"
//     &nbsp;&nbsp;&nbsp;&nbsp;<span style="color:indianred">ngbDropdownToggle</span>
//   &nbsp;&nbsp;<span style="color:grey">&gt;</span><span style="color:cornflowerblue">Selected Item</span><span style="color:grey">&lt;/button&gt;</span>
//   &nbsp;
//   &nbsp;&nbsp;<span style="color:grey">&lt;div</span>
//     &nbsp;&nbsp;&nbsp;&nbsp;aria-labelledby="<span style="color:green">smFirstExample</span>"
//     &nbsp;&nbsp;&nbsp;&nbsp;<span style="color:indianred">ngbDropdownMenu</span>
//   &nbsp;&nbsp;<span style="color:grey">&gt;</span>
//     &nbsp;&nbsp;&nbsp;&nbsp;<span style="color:grey">&lt;a</span>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;class="<span style="color:LightSteelBlue">{{other-styles-as-needed}}</span>"
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:indianred">ngbDropdownItem</span>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;href="#"
//     &nbsp;&nbsp;&nbsp;&nbsp;<span style="color:grey">&gt;</span><span style="color:cornflowerblue">Menu Item</span><span style="color:grey">&lt;/a&gt;</span>
//   &nbsp;&nbsp;<span style="color:grey">&lt;/div&gt;</span>
// <span style="color:grey">&lt;/div&gt;</span>
// </pre>
// </div>
//
// Styleguide forms.dropdowns2
