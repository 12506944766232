/* ==========================================================================
   Accessibility
   ========================================================================== */

// Header - Accessibility
//
// 1. All pages should have an article tag container the main content.
//
// 2. Each section within a page should have an h1
//
// 3. A section can have a sub-section and a sub-sub-section etc.
//
// 4. Header Tags cannot jump rank. h1 -> h2 -> h3 etc.
//
// 5. Use the header classes to ensure UX visuals
//
// 6. Use best judgement when deciding how to divide up section and sub-section content
//
// <div style="border-left: 3px solid grey; padding-left: 15px; margin-left: 15px;">
// <pre style="color:grey">&lt;<span style="color:cornflowerblue">article</span>&gt;
//     &lt;<span style="color:cornflowerblue">h1</span>&gt;<span style="color:DarkGray">Page Title</span>&lt;/<span style="color:cornflowerblue">h1</span>&gt;
//     &lt;<span style="color:cornflowerblue">h2</span> <span style="color:indianred">class</span>="<span style="color:green">fnt-sze-16 font-weight-normal</span>"&gt;<span style="color:DarkGray">Page Sub-title</span>&lt;/<span style="color:cornflowerblue">h2</span>&gt;
// &nbsp;
//     &lt;<span style="color:cornflowerblue">section</span>&gt;
//         &lt;<span style="color:cornflowerblue">h1</span> <span style="color:indianred">class</span>="<span style="color:green">h2</span>"&gt;<span style="color:DarkGray">Section Title</span>&lt;/<span style="color:cornflowerblue">h1</span>&gt;
//         &lt;<span style="color:cornflowerblue">h2</span> <span style="color:indianred">class</span>="<span style="color:green">fnt-sze-16 font-weight-normal</span>"&gt;<span style="color:DarkGray">Section Sub-title</span>&lt;/<span style="color:cornflowerblue">h2</span>&gt;
//         &lt;<span style="color:cornflowerblue">p</span>&gt;<span style="color:DarkGray">Section Content</span>&lt;/<span style="color:cornflowerblue">p</span>&gt;
//     &lt;/<span style="color:cornflowerblue">section</span>&gt;
// &nbsp;
//     &lt;<span style="color:cornflowerblue">section</span>&gt;
//         &lt;<span style="color:cornflowerblue">h1</span> <span style="color:indianred">class</span>="<span style="color:green">h2</span>"&gt;<span style="color:DarkGray">Section Title</span>&lt;/<span style="color:cornflowerblue">h1</span>&gt;
// &nbsp;
//         &lt;<span style="color:cornflowerblue">section</span>&gt;
//             &lt;<span style="color:cornflowerblue">h1</span> <span style="color:indianred">class</span>="<span style="color:green">h3</span>"&gt;<span style="color:DarkGray">sub-section Title</span>&lt;/<span style="color:cornflowerblue">h1</span>&gt;
//             &lt;<span style="color:cornflowerblue">h2</span> <span style="color:indianred">class</span>="<span style="color:green">fnt-sze-16 font-weight-normal</span>"&gt;<span style="color:DarkGray">Sub-section Sub-title</span>&lt;/<span style="color:cornflowerblue">h2</span>&gt;
//             &lt;<span style="color:cornflowerblue">p</span>&gt;<span style="color:DarkGray">Sub-section Content</span>&lt;/<span style="color:cornflowerblue">p</span>&gt;
//         &lt;/<span style="color:cornflowerblue">section</span>&gt;
//     &lt;/<span style="color:cornflowerblue">section</span>&gt;
//  &lt;/<span style="color:cornflowerblue">article</span>&gt;</pre>
//  </div>
//
// hidemarkupsnippet: true
//
// Styleguide typography.header-01

/* ==========================================================================
   HEADERS
   ========================================================================== */

// Header - Element
//
// Header Styles for avery.com. Sizes for XS and SM breakpoint(s) will change to be smaller. `<h4>`~`<h6>` sizes uses the default h4-h6 styles (no size changes).
//
// Styles will apply when the `<h1>`~`<h6>` tag are used or by using the following classes:
//
// .h1 - Font Size: **36px**, Font Weight: **700 (Bold)**
// .h2 - Font Size: **30px**, Font Weight: **700 (Bold)**
// .h3 - Font Size: **24px**, Font Weight: **700 (Bold)**
// .h4 - Font Size: **18px**, Font Weight: **700 (Bold)**
// .h5 - Font Size: **14px**, Font Weight: **700 (Bold)**
// .h6 - Font Size: **12px**, Font Weight: **700 (Bold)**
//
// Markup:
// <div class="m-0 {{modifier_class}}">An Sample Header</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide typography.header-02

h1,
.h1 {
  @include media-breakpoint-only(xs) {
    font-size: 1.4rem;
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.7rem;
  }
}
h2,
.h2 {
  @include media-breakpoint-only(xs) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.5rem;
  }
}
h3,
.h3 {
  @include media-breakpoint-only(xs) {
    font-size: 1.2rem;
  }
  @include media-breakpoint-only(sm) {
    font-size: 1.3rem;
  }
}

/* ==========================================================================
   SUB-HEADER
   ========================================================================== */

// Header - Sub-Header
//
// Used with/or support the `<h1>`~`<h6>` header tags and classes. Add `.font-weight-normal` to remove the default bold font weight.
//
// Styles will apply by using the following classes:
//
// .sh1 - Font Size: **24px**, Font Weight: **700 (Bold)**, Line Height: **0.83**
// .sh2 - Font Size: **18px**, Font Weight: **700 (Bold)**, Line Height: **1.11**
//
// Markup:
// <div class="m-0 font-weight-bold {{modifier_class}}">A Sub-Header Sample</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide typography.header-03

.sh1,
.sh2 {
  font-weight: bold;
  margin-bottom: map-get($spacers, 2);
}

.sh1 {
  font-size: rem(18);
  line-height: 1.11;
  letter-spacing: rem(0.12);

  @include media-breakpoint-only(sm) {
    font-size: rem(18);
    line-height: 1.11;
    letter-spacing: rem(0.12);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(24);
    line-height: 1.17;
    letter-spacing: rem(0.17);
  }
}

.sh2 {
  font-size: rem(18);
  line-height: 1.11;
  letter-spacing: rem(0.12);
}

// Sub-Header
// FIXME: deprecate, this is too general. Sub-headers should be like treated like header classes. UI/UX should provide these style definitions.
.sub-header {
  max-width: 350px;
  margin: 0 auto;

  @include media-breakpoint-only(sm) {
    max-width: 450px;
  }
  @include media-breakpoint-up(md) {
    max-width: 540px;
  }
}

// Section Header Containers
.section-main-header {
  padding-top: rem(30);
  padding-bottom: rem(30);
  margin: 0;
}

/* ==========================================================================
   SECTION HEADERS
   ========================================================================== */

// Header - Section Header
//
// Contains a Content Block's Header and/or Sub-Header. Usually contains a  &lt;<span style="color:cornflowerblue">h1</span>&gt; to &lt;h6&gt; tag with an optional '.sub-header' sibling.
//
// Markup:
// <div class="section-header">
//   <h2>Lorem Ipsum</h2>
//   <p class="sub-header">
//     Dolor sit amet, consectetur adipisicing elit.
//   </p>
// </div>
//
// Styleguide typography.header-04
.section-header {
  text-align: center;

  > *:last-child {
    margin-bottom: rem(40);
  }
}
