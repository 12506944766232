// Error Page
//
// Styleguide error

/* ==========================================================================
   Error Info (content of Error page)
   ========================================================================== */

// Error Info
//
// Main content that is displayed in a 404 error page.
//
// **NOTE:** Seen in Angular App and dotCMS pages.
//
// Markup:
// <section class="error-info bg-light-gray py-5">
//   <div class="container container-fluid-sm-down text-center">
//     <h1 class="mb-1">We're sorry. That page isn't here.</h1>
//     <div class="h3 font-weight-normal mb-3">It looks like the page you want doesn't exist.</div>
//     <div class="fnt-sze-18 mb-5">Error Code: 404</div>
//   </div>
//   <div class="container container-fluid-sm-down">
//     <div class="row align-item-center justify-content-center text-center mb-3">
//       <h4 class="col-12 mb-4">Try one of these helpful links:</h4>
//       <div class="col-auto mb-2"><a class="text-link fnt-sze-16 font-weight-bold" href="/">Home</a></div>
//       <div class="col-auto mb-2"><a class="text-link fnt-sze-16 font-weight-bold"
//           href="/category/products/">Products</a></div>
//       <div class="col-auto mb-2"><a class="text-link fnt-sze-16 font-weight-bold" href="/weprint">Custom Printing</a>
//       </div>
//       <div class="col-auto mb-2"><a class="text-link fnt-sze-16 font-weight-bold" href="/templates">Templates</a></div>
//       <div class="col-auto mb-2"><a class="text-link fnt-sze-16 font-weight-bold" href="/ideas">Ideas</a></div>
//       <div class="col-auto mb-2"><a class="text-link fnt-sze-16 font-weight-bold" href="/help">Help</a></div>
//     </div>
//   </div>
//   <div class="container container-fluid-sm-down">
//     <div class="row justify-content-center">
//       <div class="col-10 col-sm-8 col-md-8 col-lg-6 col-xl-5 input-group">
//         <form class="input-group input-group-lg">
//           <input class="form-control" id="search-request" placeholder="Try searching for something else" type="text">
//           <div class="input-group-append">
//             <button class="cta cta--primary cta--blue" id="search-button">
//               Search
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   </div>
// </section>
//
// Styleguide error.error-info
