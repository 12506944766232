// Info Box
//
// **Seen In:** Template Detail Page
//
// styleguide info-box

/* ==========================================================================
   COMMON INFO-BOX STYLES
   ========================================================================== */

.info-box {
  @include info-box-container-style();
}

/* ==========================================================================
   TEMPLATE DETAIL INFO-BOX STYLES
   ========================================================================== */

// Info Box - Detail
//
// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//
// Markup:
// <div class="info-box template-detail-info-box" style="max-width: 400px; margin: 0 auto;">
//   <div class="row">
//     <div class="col-5 col-sm-4 col-md-5 col-lg-4">
//       <div class="info-box-img">
//         <img class="img-fluid" src="https://dev-cms.avery.com/assets/img/template-page/template-detail/logo-excel.jpg">
//       </div>
//     </div>
//     <div class="col-7 col-sm-8 col-md-7 col-lg-8">
//       <p class="info-label">
//         Microsoft Excel
//       </p>
//       <div class="info-details">
//         <p class="text-truncate-multiline">Mail-merge has never been easier with the Excel plugin available in the Windows Store.</p>
//       </div>
//       <a href="" class="text-link">
//         Learn More
//       </a>
//     </div>
//   </div>
// </div><!-- /.info-box -->
//
// styleguide info-box.detail

.info-box.template-detail-info-box {
  @include info-box-border();
}

/* ==========================================================================
   TEMPLATE DOWNLOAD INFO-BOX STYLES
   ========================================================================== */

// Info Box - Download
//
// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//
// Markup:
// <div class="info-box template-download-info-box" style="max-width: 600px; margin: 0 auto;">
//   <div class="row">
//     <div class="col-4">
//       <div class="info-box-img">
//         <img src="https://dev-cms.avery.com/assets/img/template-page/template-detail/logo-photoshop.jpg">
//       </div>
//     </div>
//     <div class="col-8">
//       <p class="info-label">
//         Adobe Photoshop
//       </p>
//       <p class="info-details">
//         <button class="btn btn-avry btn-action-green">Download Blank Template</button>
//       </p>
//       <a href="" class="text-link">
//         Email blank template
//       </a>
//     </div>
//   </div>
// </div><!-- /.info-box -->
//
// styleguide info-box.download

.info-box.template-download-info-box {
  @include media-breakpoint-down(sm) {
    margin-top: rem(16);
  }

  .info-label, .info-details {
    height: auto;
  }
}

/* ==========================================================================
   STACKED INFO BOX
   ========================================================================== */

// Info Box - Stacked
//
// An info box that stacks in XS and SM breakpoints.
//
// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//
// Markup:
// <div class="info-box info-box-stack" style="max-width: 600px; margin: 0 auto;">
//   <div class="row">
//     <div class="col-12 col-md-6 col-lg-5">
//       <div class="flexible-img flexible-img-vertical">
//         <img class="img-fluid img-fluid-height" src="//stg-cms.avery.com/app/images/industrial/landing/ohs-bandge.jpg">
//       </div>
//     </div>
//     <div class="col-12 col-md-6 col-lg-7">
//       <p class="info-label">
//         Ultra Duty® GHS Labels
//       </p>
//     </div>
//   </div>
// </div>
//
// styleguide info-box.stacked

.info-box.info-box-stack {
  margin: 0;
  padding: 0;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  > .row {
    align-items: center;
  }

  .info-label {
    height: auto;
    margin: 0;
    font-size: rem(18);
    line-height: 1.5;

    @include media-breakpoint-down(sm) {
      margin-top: rem(16);
    }
  }
}

/* ==========================================================================
   INFO BOX GRAY
   ========================================================================== */

.info-box-gray {
  height: 150px;
  background: $faint-gray;
  border-radius: $tile-border-radius; // Made Border Radius similar to tiles.
  padding: rem(10);
  cursor: default;

  @include media-breakpoint-down(sm) {
    text-align: left;

    .info-label, .info-details {
      margin: 0;
    }
  }

  @include media-breakpoint-up(md) {
    height: 100%;
  }

  > .row {
    @include media-breakpoint-down(sm) {
      height: 100%;
    }
  }

  .info-label {
    height: auto;
    font-size: rem(13);
    font-weight: bold;
    line-height: 1.5;
    margin: 0;
  }

  .info-details {
    height: auto;
    font-size: rem(13);
  }
}

