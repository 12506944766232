/* ==========================================================================
   AVERY COLOR DEFINITIONS
   ========================================================================== */

/* TODO: The Following documentation for colors is manually created. Find a way to automate this using KSS. */
// Colors
//
// Avery Global Colors
//
// *Note for Developers:* The `Variable:` information in the swatches below are the SASS/SCSS color variables that you can simply paste into your styles.
//
// Markup:
// <div class="container">
// <div class="row no-gutters fnt-sze-14 lh-1-8" style="letter-spacing: .01rem;">
// <!-- -----------------WHITES------------------------------------- -->
//   <h3 class="col-12 mt-4">Whites</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 border" style="background:#FFFFFF;">
//       <h4>Main White</h4>
//       <b>Bg Class</b>: bg-white
//       <br /><b>txt Class</b>: text-main-white
//       <br /><b>Var</b>: $main-white
//       <br /><b>Hex</b>: #FFFFFF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 border border-left-0"; style="background:#FBFBFB;">
//       <h4>Almost White</h4>
//       <b>Bg Class</b>: bg-almost-white
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $almost-white
//       <br /><b>Hex</b>: #FBFBFB
//     </div>
//   </div>
// <!-- -----------------BLACKS------------------------------------ -->
//   <h3 class="col-12 mt-4">Blacks</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white"; style="background:#000000;">
//       <h4>Main Black</h4>
//       <b>Bg Class</b>: bg-black
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $main-black
//       <br /><b>Hex</b>: #000000
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white"; style="background:#242424;">
//       <h4>Base Text Color</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: text-base-color
//       <br /><b>Var</b>: $base-dark
//       <br /><b>Hex</b>: #242424
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white"; style="background:#383D46;">
//       <h4>Charcoal Black</h4>
//       <b>Bg Class</b>: bg-charcoal
//       <br /><b>txt Class</b>: text-charcoal
//       <br /><b>Var</b>: $charcoal-black
//       <br /><b>Hex</b>: #383D46
//     </div>
//   </div>
// <!-- -----------------GRAYS------------------------------------ -->
//   <h3 class="col-12 mt-4">Grays</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#B9B9B9;">
//       <h4>Base Gray</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: text-base-gray
//       <br /><b>Var</b>: $base-gray
//       <br /><b>Hex</b>: #B9B9B9
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#B4B4B4;">
//       <h4>Washed Gray</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $washed-gray
//       <br /><b>Hex</b>: #B4B4B4
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#CCCCCC;">
//       <h4>Gray Medium</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $gray-medium
//       <br /><b>Hex</b>: #CCCCCC
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white"; style="background:#53565A;">
//       <h4>Industrial Gray</h4>
//       <b>Bg Class</b>: bg-inds-gray
//       <br /><b>txt Class</b>: text-color-ind-gray
//       <br /><b>Var</b>: $industrial-gray
//       <br /><b>Hex</b>: #53565A
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#6D6D6D;">
//       <h4>Sub Gray</h4>
//       <b>Bg Class</b>: text-sub-gray
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $sub-gray
//       <br /><b>Hex</b>: #6D6D6D
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#8C8C8C;">
//       <h4>Bold Gray</h4>
//       <b>Bg Class</b>: bg-bold-gray
//       <br /><b>txt Class</b>: text-bold-gray
//       <br /><b>Var</b>: $bold-gray
//       <br /><b>Hex</b>: #8C8C8C
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#F1F1F1;">
//       <h4>Light Gray</h4>
//       <b>Bg Class</b>: bg-light-gray
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $light-gray
//       <br /><b>Hex</b>: #F1F1F1
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#F7F7F7;">
//       <h4>Faint Gray</h4>
//       <b>Bg Class</b>: bg-faint-gray
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $faint-gray
//       <br /><b>Hex</b>: #F7F7F7
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3"; style="background:#F3F2ED;">
//       <h4>Brownish Gray</h4>
//       <b>Bg Class</b>: bg-tan
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $brownish-gray
//       <br /><b>Hex</b>: #F3F2ED
//     </div>
//   </div>
// <!-- -----------------BLUES------------------------------------- -->
//   <h3 class="col-12 mt-4">Blues</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#00B4EB;">
//       <h4>Base Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $base-blue
//       <br /><b>Hex</b>: #00B4EB
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#00A3E0;">
//       <h4>Hyper Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: text-hyper-blue
//       <br /><b>Var</b>: $hyper-blue
//       <br /><b>Hex</b>: #00A3E0
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-base-gray" style="background:#0075BF;">
//       <h4>Hyper Deep Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $hyper-deep-blue
//       <br /><b>Hex</b>: #0075BF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white" style="background:#105bd2;">
//       <h4>Primary Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $primary-blue
//       <br /><b>Hex</b>: #105bd2
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white" style="background:#0057B8;">
//       <h4>Industrial Blue</h4>
//       <b>Bg Class</b>: bg-inds-blue
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $industrial-blue
//       <br /><b>Hex</b>: #0057B8
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white" style="background:#0D4FA8;">
//       <h4>Container Blue</h4>
//       <b>Bg Class</b>: bg-container-blue
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $container-blue
//       <br /><b>Hex</b>: #0D4FA8
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//      <div class="text-center h-100 p-3 text-main-white" style="background:#0039A6;">
//        <h4>Deep Blue</h4>
//        <b>Bg Class</b>: bg-deep-blue
//       <br /><b>txt Class</b>: --
//       <br /v><b>Var</b>: $deep-blue
//       <br /v><b>Hex</b>: #0039A6
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-main-white" style="background:#1464e4;">
//       <h4>Bright Bold Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: text-bright-blue
//       <br /><b>Var</b>: $bright-bold-blue
//       <br /><b>Hex</b>: #1464e4
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#DBFEFF;">
//       <h4>Washed Blue</h4>
//       <b>Bg Class</b>: bg-light-blue
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $washed-blue
//       <br /><b>Hex</b>: #DBFEFF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#caeeff;">
//       <h4>Sky Blue</h4>
//       <b>Bg Class</b>: bg-sky-blue
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $sky-blue
//       <br /><b>Hex</b>: #CAEEFF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#E0F4FB;">
//       <h4>Powder Blue</h4>
//       <b>Bg Class</b>: bg-bowder-blue
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $powder-blue
//       <br /><b>Hex</b>: #E0F4FB
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#F3FCFF;">
//       <h4>Light Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $light-blue
//       <br /><b>Hex</b>: #F3FCFF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#EBF5FF;">
//       <h4>Light Powder Blue</h4>
//       <b>Bg Class</b>: bg-light-powder-blu
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $light-powder-blue
//       <br /><b>Hex</b>: #EBF5FF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#D8EBFF;">
//       <h4>Deep Powder Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $deep-powder-blue
//       <br /><b>Hex</b>: #D8EBFF
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#ccdef1;">
//       <h4>Industrial Powder Blue</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $industrial-powder-blue
//       <br /><b>Hex</b>: #CCDEF1
//     </div>
//   </div>
// <!-- ---------------GREENS--------------------------------------- -->
//   <h3 class="col-12 mt-4">Greens</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#07A200;">
//       <h4>Base Green</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: text-base-green
//       <br /><b>Var</b>: $base-green
//       <br /><b>Hex</b>: #07A200
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#0ACC00;">
//       <h4>Bright Green</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $bright-green
//       <br /><b>Hex</b>: #0ACC00
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#0bb203;">
//       <h4>Hover Green</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $hover-green
//       <br /><b>Hex</b>: #0BB203
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#eefbee;">
//       <h4>Light Green</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $light-green
//       <br /><b>Hex</b>: #eefbee
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#BAFED9;">
//       <h4>Mint Green</h4>
//       <b>Bg Class</b>: bg-mint-green
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $mint-green
//       <br /><b>Hex</b>: #BAFED9
//     </div>
//   </div>
// <!-- ----------------REDS-------------------------------------- -->
//   <h3 class="col-12 mt-4">Reds</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#EA001C;">
//       <h4>Primary Red</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: text-bright-red
//       <br /><b>Var</b>: $bright-red
//       <br /><b>Hex</b>: #EA001C
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#ff0d0d;">
//       <h4>Hover Red</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $hover-red
//       <br /><b>Hex</b>: #FF0D0D
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3 text-base-gray" style="background:#C5001D;">
//       <h4>Bold Red</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $bold-red
//       <br /><b>Hex</b>: #C5001D
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#FDF3F3;">
//       <h4>Light Red</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $light-red
//       <br /><b>Hex</b>: #FDF3F3
//     </div>
//   </div>
// <!-- ---------------ORANGES------------------------------------- -->
//   <h3 class="col-12 mt-4">Oranges</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#FEECDF;">
//       <h4>Light Orange</h4>
//       <b>Bg Class</b>: bg-light-orange
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $light-orange
//       <br /><b>Hex</b>: #FEECDF
//     </div>
//   </div>
// <!-- ---------------YELLOWS------------------------------------ -->
//   <h3 class="col-12 mt-4">Yellows</h3>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#FFA31F;">
//       <h4>Yellow Gold</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $yellow-gold
//       <br /><b>Hex</b>: #FFA31F
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#F6F3A9;">
//       <h4>Pastel Yellow</h4>
//       <b>Bg Class</b>: --
//       <br /><b>txt Class</b>: --
//       <br /><b>Var</b>: $pastel-yellow
//       <br /><b>Hex</b>: #F6F3A9
//     </div>
//   </div>
//   <div class="col-6 col-md-4 col-lg-3">
//     <div class="text-center h-100 p-3" style="background:#EAAA00;">
//       <h4>Industrial Yellow</h4>
//       <b>Bg Class</b>: bg-inds-yellow
//       <br /><b>txt Class</b>: text-color-ind-yellow
//       <br /><b>Var</b>: $industrial-yellow
//       <br /><b>Hex</b>: #EAAA00
//     </div>
//   </div>
// </div></div>
//
// hidemarkupsnippet: true
//
// Styleguide colors

$base-dark: #242424;

// Whites
$main-white: #ffffff;

// Blacks
$main-black: #000000;
// $graphite-black: #383f4e;
$charcoal-black: #383d46;

// Grays
$base-gray: #b9b9b9; // Seen from Disabled Buttons
$gray-medium: #cccccc;
$sub-gray: #6d6d6d; // Seen in Dropdown Menus and Accordion Dropdowns.
$bold-gray: #8c8c8c; // Seen as Border Color for Selection Buttons
$light-gray: #f1f1f1;
$faint-gray: #f7f7f7; // Seen in Disabled elements
$almost-white: #fbfbfb; // Seen in items within a faint-gray field
$washed-gray: #b4b4b4; // Seen in Disabled elements
$brownish-gray: #f3f2ed;

// Blues
$base-blue: #00b4eb; // Seen on Outlines and Click states.
$deep-blue: #0039a6;
$container-blue: #0d4fa8;
// $faded-blue: #bfe5fa; // Used for alerts
$sky-blue: #caeeff; // Used for dropdowns
$light-blue: #f3fcff; // Seen on Hover button states
// $header-blue: #003090; // Seen in Templates Start Page
$hyper-blue: #00a3e0;
$hyper-deep-blue: #0075bf;
$washed-blue: #dbfeff;
$powder-blue: #e0f4fb;
$deep-powder-blue: #d8ebff;
$light-powder-blue: #ebf5ff;
$bright-bold-blue: #1464e4;
// $we-print-deep-blue: #0238a3;
$primary-blue: #105bd2;
$primary-hover-blue: #0062ff;

// Reds
$bright-red: #ea001c;
$bold-red: #c5001d;
// $deep-red: #99001d;
// $faded-red: #f0bfc6; // Used for alerts
$hover-red: #ff0d0d;
$light-red: #fdf3f3; // Seen on Hover button states and Error inputs

// Greens
$base-green: #07a200;
$bright-green: #0acc00;
$hover-green: #0bb203;
// $deep-green: #076f00;
// $faded-green: #aefcaa; // Used for alerts
$light-green: #eefbee; // Used for Success inputs
$neon-green: #40f78d;
$mint-green: #bafed9;

// Oranges
// $deep-orange: #e57125;
// $faded-orange: #faeabf; // Used for alerts
$light-orange: #feecdf;

// Yellows
$pastel-yellow: #f6f3a9; // used for alerts
$yellow-gold: #ffa31f; // used for stars

/* ==========================================================================
   AVERY INDUSTRIAL COLORS
   ========================================================================== */

// Black
$industrial-black: #000000;

// Blues
$industrial-blue: #0057b8;
$industrial-hover-blue: #1a68bf;
$industrial-powder-blue: #ccdef1;

// Grays
$industrial-gray: #53565a;
$industrial-hover-gray: #6c6f72;
$industrial-medium-gray: #cccccc;
$industrial-light-gray: #f1f1f1;

// Green
$industrial-green: #07a200;
$industrial-hover-green: #20ab1a;

// Red
$industrial-red: #c5001d;
$industrial-hover-red: #ca1a34;

// Yellow
$industrial-yellow: #eaaa00;
$industrial-hover-yellow: #ecb21a;

/* ==========================================================================
   AVERY HOLIDAY COLORS (2020)

   NOTE: These colors are meant to be exclusive to Holiday pages only.
   If if needs to go global, it would be best to move the color out of this
   section and grouped with other global colors.
   ========================================================================== */

$aqua-green: #1f6d73;
$aqua-teal: #b4d2d6;
$aquamarine: #7fc4b9;
$burgundy: #67110c;
$cool-blue: #b3d4d9;
$cool-gray: #eef5f5;
$dark-tan: #aaa28e;
$forest-green: #3f666b;
$hunter-green: #0c4d47;
$indian-red: #c46058;
$mint-green: #e1f0e6;
$navy-blue: #0f2d41;
$peach: #ffe8d8;
$ruby-red: #990034;
$sky-blue: #ade0dc;
$steel-blue: #8097a9;
$sweet-rose: #eed5d4;
