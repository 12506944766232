/* ==========================================================================
   DOTCMS/Industrial Overrides
   ========================================================================== */

.sub-category-tile-group-filters-summary.bg-light-gray {
  .filter-summary-set:not([data-swiper-enabled="true"]) {
    @include media-breakpoint-down(sm) {
      &:before {
        background: linear-gradient(to right, rgba($light-gray, 0) 0%, $light-gray 100%);
      }
    }
  }
}
