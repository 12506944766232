/* ==========================================================================
   FEATURE GRID
   ========================================================================== */

// Feature Grid
//
// A modular block that displays content. Meant to be displayed in a grid. Modular elements are NOT meant to be clickable.
//
// Column sizes is based on requirement.
//
// Markup:
// <div class="container container-fluid-sm-down text-center">
//   <div class="row">
//     <div class="col-12 col-sm-6 col-md-4 mb-5">
//       <div class="feat-grid">
//         <div class="feat-grid__img px-5 pt-5 px-md-4 pt-md-4 px-lg-5 pt-lg-5">
//           <picture>
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-fast-labeling-desktop" media="(max-width: 768px)">
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-fast-labeling-desktop" media="(min-width: 769px)">
//             <img class="img-fluid px-5 lazyload" src="https://www.avery.com/app/images/logos/avery_ind_placeholder.svg" data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-fast-labeling-desktop" alt="Fast Labeling Icon">
//           </picture>
//         </div>
//         <div class="feat-grid__body px-5 pb-5 px-md-4 pb-md-4 px-lg-5 pb-lg-5">
//           <div class="h3 my-4">Fast Labeling</div>
//           <span class="fnt-sze-18 lh-1-5 text-charcoal">Quickly create and print OSHA-compliant labels with our fast and easy-to-use GHS templates.</span>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-sm-6 col-md-4 mb-5">
//       <div class="feat-grid">
//         <div class="feat-grid__img px-5 pt-5 px-md-4 pt-md-4 px-lg-5 pt-lg-5">
//           <picture>
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-chemicals-desktop" media="(max-width: 768px)">
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-chemicals-desktop" media="(min-width: 769px)">
//             <img class="img-fluid px-5 lazyload" src="https://www.avery.com/app/images/logos/avery_ind_placeholder.svg" data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-chemicals-desktop" alt="Chemicals Icon">
//           </picture>
//         </div>
//         <div class="feat-grid__body px-5 pb-5 px-md-4 pb-md-4 px-lg-5 pb-lg-5">
//           <div class="h3 my-4">150,000 Chemicals</div>
//           <span class="fnt-sze-18 lh-1-5 text-charcoal">Access chemical information for 150k+ chemicals in our expert database powered by Chemwatch™.</span>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-sm-6 col-md-4 mb-5">
//       <div class="feat-grid">
//         <div class="feat-grid__img px-5 pt-5 px-md-4 pt-md-4 px-lg-5 pt-lg-5">
//           <picture>
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-easy-search-desktop" media="(max-width: 768px)">
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-easy-search-desktop" media="(min-width: 769px)">
//             <img class="img-fluid px-5 lazyload" src="https://www.avery.com/app/images/logos/avery_ind_placeholder.svg" data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-easy-search-desktop" alt="Easy Search Icon">
//           </picture>
//         </div>
//         <div class="feat-grid__body px-5 pb-5 px-md-4 pb-md-4 px-lg-5 pb-lg-5">
//           <div class="h3 my-4">Easy Search</div>
//           <span class="fnt-sze-18 lh-1-5 text-charcoal">Find the info you need fast. Search our database by CAS#, product identifier or chemical name.</span>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-sm-6 col-md-4 mb-5">
//       <div class="feat-grid">
//         <div class="feat-grid__img px-5 pt-5 px-md-4 pt-md-4 px-lg-5 pt-lg-5">
//           <picture>
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-autofill-desktop" media="(max-width: 768px)">
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-autofill-desktop" media="(min-width: 769px)">
//             <img class="img-fluid px-5 lazyload" src="https://www.avery.com/app/images/logos/avery_ind_placeholder.svg" data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-autofill-desktop" alt="Easy Search Icon">
//           </picture>
//         </div>
//         <div class="feat-grid__body px-5 pb-5 px-md-4 pb-md-4 px-lg-5 pb-lg-5">
//           <div class="h3 my-4">Autofill Statements</div>
//           <span class="fnt-sze-18 lh-1-5 text-charcoal">Increase chemical label accuracy with auto filled signal word and hazard &amp; precautionary statements.</span>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-sm-6 col-md-4 mb-5">
//       <div class="feat-grid">
//         <div class="feat-grid__img px-5 pt-5 px-md-4 pt-md-4 px-lg-5 pt-lg-5">
//           <picture>
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-autopictograms-desktop" media="(max-width: 768px)">
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-autopictograms-desktop" media="(min-width: 769px)">
//             <img class="img-fluid px-5 lazyload" src="https://www.avery.com/app/images/logos/avery_ind_placeholder.svg" data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-autopictograms-desktop" alt="Easy Search Icon">
//           </picture>
//         </div>
//         <div class="feat-grid__body px-5 pb-5 px-md-4 pb-md-4 px-lg-5 pb-lg-5">
//           <div class="h3 my-4">Automated Pictograms</div>
//           <span class="fnt-sze-18 lh-1-5 text-charcoal">OSHA-compliant graphics. GHS pictograms are automatically added to your chemical labels for you.</span>
//         </div>
//       </div>
//     </div>
//     <div class="col-12 col-sm-6 col-md-4 mb-0 mb-sm-5">
//       <div class="feat-grid">
//         <div class="feat-grid__img px-5 pt-5 px-md-4 pt-md-4 px-lg-5 pt-lg-5">
//           <picture>
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-language-english-spanish-desktop" media="(max-width: 768px)">
//             <source data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-language-english-spanish-desktop" media="(min-width: 769px)">
//             <img class="img-fluid px-5 lazyload" src="https://www.avery.com/app/images/logos/avery_ind_placeholder.svg" data-srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/industrial/design-print/ghs-subscription/ghs-subscription-icon-language-english-spanish-desktop" alt="Easy Search Icon">
//           </picture>
//         </div>
//         <div class="feat-grid__body px-5 pb-5 px-md-4 pb-md-4 px-lg-5 pb-lg-5">
//           <div class="h3 my-4">English &amp; Spanish</div>
//           <span class="fnt-sze-18 lh-1-5 text-charcoal">Support Right-to-Know laws with English and Spanish versions of all signal wordsand statements.</span>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide feature-grid

.feat-grid {
  @include global-drop-shadow();
  height: 100%;

  .feat-grid__img {
    padding: map-get($spacers, 3) map-get($spacers, 3) 0;
  }

  .feat-grid__body {
    padding: 0 map-get($spacers, 3) map-get($spacers, 3);
  }
}
