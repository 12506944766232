@use "sass:math";

/* ==========================================================================
   VARIABLES

   NOTE: used in functions
   ========================================================================== */

$root-em-value: 16px; // Follow browser default ~16px;

/* ==========================================================================
   FUNCTIONS
   ========================================================================== */

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
///
/// NOTE: Based on Bourbon's strip-unit function.
@function strip-units($number) {
  @return math.div($number, ($number * 0 + 1));
}

/// Converts Pixel to Rem
/// @param {Number} $pxval - Pixel value to be converted
/// @return {Number} - Rem Value
///
/// NOTE: Root emspace (rem) value ($root-em-value) is based on browser default, typically 16px.
///
/// NOTE: Based on Bourbon's v4 PX to REM function.
///       This function is deprecated on Bourbon v5, but is still widely used
///       in our styles.
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $root-em-value;
  @if not unitless($base) {
    $base: strip-units($base);
  }

  @return (math.div($pxval, $base)) * 1rem;
}

/// Converts rem/em to Pixels.
/// @param {Number} $value - A rem/em value.
/// @return {Number} $result - a px value.
@function px($value) {
  $value: strip-units($value); // Strip Unit
  $rem: $root-em-value;

  @if not unitless($rem) {
    $rem: strip-units($rem);
  }

  $result: round($value * $rem);

  @return #{$result}px;
}
