/* ==========================================================================
   AVERY ACCORDION
   ========================================================================== */

// Avery Accordion
//
// Basic accessible accordion.
//
// NOTE: This a non bootstrap accordion that is more felxable and requries less js.
//
// markup:
// <h3 class="mt-4">Basic Unstyled Accordion</h3>
// <div class="acdn__item">
//   <input class="acdn__control" id="lorem-ipsum" type="checkbox" />
//   <label class="acdn__label" for="lorem-ipsum" id="lorem-ipsum-header" aria-expanded="false">Lorem ipsum</label>
//   <div class="acdn__content" aria-labelledby="lorem-ipsum-header">
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//   </div>
// </div>
// <span></span>
// <h3 class="mt-4">Menu Accordion</h3>
// <div class="w-100 w-md-50">
//   <div class="acdn--menu">
//     <div class="acdn__item">
//       <input class="acdn__control" id="egestas-maecenas" type="checkbox" checked />
//       <label class="acdn__label" for="egestas-maecenas" id="egestas-maecenas-header" aria-expanded="false">Egestas maecenas</label>
//       <div class="acdn__content" aria-labelledby="egestas-maecenas-header">
//         <form class="p-3">
//           <div class="form-group"><input type="checkbox" value="Facilisis" id="facilisisOption"> <label for="facilisisOption">Facilisis mauris</label></div>
//           <div class="form-group"><input type="checkbox" value="Scelerisque" id="scelerisqueOption"> <label for="scelerisqueOption">Elit scelerisque mauris</label></div>
//           <div class="form-group"><input type="checkbox" value="Adipiscing" id="adipiscingOption"> <label for="adipiscingOption">Adipiscing mauris</label></div>
//         </form>
//       </div>
//     </div>
//     <div class="acdn__item">
//       <input class="acdn__control" id="risus-in-hendrerit" type="checkbox" />
//       <label class="acdn__label" for="risus-in-hendrerit" id="risus-in-hendrerit-header" aria-expanded="false">Risus in hendrerit</label>
//       <div class="acdn__content" aria-labelledby="risus-in-hendrerit-header">
//       <form class="p-3">
//         <div class="form-group"><input type="radio" value="Sodales" name="acdnOption" id="sodalesOption"> <label for="sodalesOption">Sodales</label></div>
//         <div class="form-group"><input type="radio" value="Pharetra" name="acdnOption" id="pharetraOption"> <label for="pharetraOption">Pharetra</label></div>
//         <div class="form-group"><input type="radio" value="Viverra" name="acdnOption" id="viverraOption"> <label for="viverraOption">viverra</label></div>
//         <div class="form-group"><input type="radio" value="tempor" name="acdnOption" id="temporOption"> <label for="temporOption">Tempor</label></div>
//       </form>
//       </div>
//     </div>
//   </div>
// </div>
// <span></span>
// <h3 class="col-12 mt-4">info Accordion</h3>
// <div class="acdn--info">
//  <div class="acdn__item">
//    <input class="acdn__control" id="loremOption" checked type="radio" name="acdnRadio" />
//    <label class="acdn__label" for="loremOption" id="lorem-ipsum-set-header" aria-expanded="false">Lorem ipsum</label>
//    <div class="acdn__content" aria-labelledby="lorem-ipsum-set-header">
//      <div class="p-3 fnt-sze-14">
//        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//      </div>
//    </div>
//  </div>
//  <div class="acdn__item">
//    <input class="acdn__control" id="egestasOption" type="radio" name="acdnRadio" />
//    <label class="acdn__label" for="egestasOption" id="egestas-maecenas-set-header" aria-expanded="false">Egestas maecenas</label>
//    <div class="acdn__content" aria-labelledby="egestas-maecenas-set-header">
//      <div class="p-3 fnt-sze-14">
//        <p>Egestas maecenas pharetra convallis posuere morbi. Purus sit amet volutpat consequat mauris. Facilisis mauris sit amet massa vitae tortor. In nisl nisi scelerisque eu. Adipiscing at in tellus integer feugiat scelerisque varius morbi enim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Pretium fusce id velit ut. Lorem sed risus ultricies tristique nulla aliquet enim tortor at. Montes nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus.</p>
//      </div>
//    </div>
//  </div>
//  <div class="acdn__item">
//    <input class="acdn__control" id="risusOption" type="radio" name="acdnRadio" />
//    <label class="acdn__label" for="risusOption" id="risus-in-hendrerit-set-header" aria-expanded="false">Risus in hendrerit</label>
//    <div class="acdn__content" aria-labelledby="risus-in-hendrerit-set-header">
//      <div class="p-3 fnt-sze-14">
//        <p>Risus in hendrerit gravida rutrum quisque non. Eget dolor morbi non arcu risus. Amet facilisis magna etiam tempor. Arcu dui vivamus arcu felis bibendum ut tristique. Elit sed vulputate mi sit. Ut porttitor leo a diam sollicitudin tempor. Aliquam vestibulum morbi blandit cursus risus at ultrices mi tempus. Ultricies mi quis hendrerit dolor magna eget. Nisl pretium fusce id velit. Gravida neque convallis a cras semper auctor neque vitae. Malesuada pellentesque elit eget gravida cum sociis natoque penatibus et. Eu volutpat odio facilisis mauris sit. Hac habitasse platea dictumst vestibulum rhoncus est.</p>
//      </div>
//    </div>
//  </div>
// </div>
//
// styleguide accordion.avery

.acdn__item {
  input[type='checkbox']:checked,
  input[type='radio']:checked {
    ~ .acdn__content {
      height: auto;
      opacity: 1;
      visibility: visible;
    }
    ~ .acdn__label {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.acdn--info,
.acdn--menu {
  .acdn__control {
    background-color: transparent;
    border: 1px solid transparent;
    height: 1px;
    position: absolute;
    width: 1px;
    margin: 4px 0 0 0;
    outline: none;
    -webkit-appearance: none;
  }
}

.acdn__content {
  height: 0;
  opacity: 0;
  overflow: hidden;
  // transition: 350ms all linear;
  // transition: max-height 0.4s linear;
  visibility: hidden;
}

// Menu Type
.acdn--menu,
.acdn--info {
  .acdn__label {
    background-color: $light-gray;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 16px;
    position: relative;
    padding-right: 32px;
    width: 100%;

    &:after {
      content: '\e90c';
      display: flex;
      align-items: center;
      font-family: avery-fonticon;
      font-size: 13px;
      height: 100% !important;
      margin-right: 14px;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(0deg);
      // save for when animation is needed
      transition: 350ms all ease-out;
    }
  }
  ul {
    list-style: none;
    padding-left: 24px;
    margin-bottom: 12px;
  }
  li {
    font-size: 16px;
    margin-top: 24px;
  }
}

// Info Type
.acdn--info {
  border-bottom: 1px solid $base-gray;

  .acdn__label {
    border-radius: 0;
    border-top: 1px solid $base-gray;
    margin-bottom: 0;
  }
}
