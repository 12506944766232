// Avery Components
@import "global/avery_accordion";
@import "global/avery_alert";
@import "global/avery_banner";
@import "global/avery_boxed-grid";
@import "global/avery_buttons";
@import "global/avery_description-block";
@import "global/avery_error";
@import "global/avery_feature-grid";
@import "global/avery_filters";
@import "global/avery_fonticons";
@import "global/avery_forms";
@import "global/avery_header";
@import "global/avery_holiday";
@import "global/avery_horizontal_thumbnails";
@import "global/avery_info-box";
@import "global/avery_modal";
@import "global/avery_my_account";
@import "global/avery_option-box";
@import "global/avery_section";
@import "global/avery_skeleton";
@import "global/avery_swiper";
@import "global/avery_table";
@import "global/avery_templates";
@import "global/avery_tiles";
@import "global/avery_tooltips";
@import "global/avery_ui_switch.scss";
@import "global/avery_weprint.scss";

/* NOTE: NOT PART OF MVS */
// @import "global/avery_upsale_carousel";
// @import "global/avery_multi_category_carousel";

// Avery Typography
@import "global/avery_typography";

// Avery Utilities
@import "global/avery_utilities";
@import "global/avery_utilities_responsive";

// Avery Industrial
@import "global/avery_industrial";

// Hot Fixes & Browser Bug Fixes
@import "global/avery_hotfixes"
