// Text Inputs
//
// Slightly modified from bootstraps native forms
// Only custom part is the icons added for password and email
//
// Can also use class rather than type. i.e. **class="password"** vs. **type="password"**
//
//
// Markup:
// <div class="form-row">
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Text input</label>
//     <input class="form-control w-100" type="text">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Search input</label>
//     <input class="form-control w-100" type="search" placeholder="Search Terms">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label class="w-100">
//       <div class="passwordToggle float-right">
//         <i aria-hidden="true" class="fa fa-eye"></i> View
//       </div>
//       Password input
//     </label>
//     <input class="form-control w-100" type="password">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Disabled input</label>
//     <input class="form-control w-100 password" disabled>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Error input</label>
//     <input class="form-control w-100 is-invalid" type="Email">
//     <div class="invalid-feedback"> Invalid Entry </div>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Success input</label>
//     <input class="form-control w-100 is-valid" type="Email">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Email input (default medium)</label>
//     <input class="form-control w-100" type="email">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>CTA for comparison</label>
//     <button class="cta cta--secondary d-block">Button</button>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Large Input (<strong>form-control-lg</strong>)</label>
//     <input class="form-control form-control-lg w-100" type="email">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Large CTA for comparison</label>
//     <button class="cta cta--secondary cta--lg d-block">Button</button>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Small Input with (<strong>form-control-sm</strong>)</label>
//     <input class="form-control form-control-sm w-100" type="email">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Small CTA for comparison</label>
//     <button class="cta cta--secondary cta--sm d-block">Button</button>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Extra Large Input with (<strong>form-control-xl</strong>)</label>
//     <input class="form-control form-control-xl w-100" type="email">
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Extra Large CTA for comparison</label>
//     <button class="cta cta--secondary cta--xl d-block">Button</button>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Input with CTA (input-group)</label>
//     <div class="input-group">
//       <input class="form-control" type="text" placeholder="Enter Text Here">
//       <div class="input-group-append">
//         <div class="cta cta--primary cta--blue"> Search </div>
//       </div>
//     </div>
//   </div>
//   <div class="form-group col-12 col-md-6 pb-3">
//     <label>Square Search Field (input-group)</label>
//     <div class="input-group">
//       <input class="form-control" type="search" placeholder="Search Terms">
//     </div>
//   </div>
// </div>
// <br/>On Background colors
// <div class="p-3 bg-faint-gray">
//   <div class="row">
//     <div class="form-group col-12 col-md-6 pb-3">
//       <label>Email input</label>
//       <input class="form-control w-100" type="email">
//     </div>
//     <div class="form-group col-12 col-md-6 pb-3">
//       <label>Disabled input</label>
//       <input class="form-control w-100" disabled type="Email">
//     </div>
//   </div>
//   <div class="m-3 px-3 bg-almost-white border">
//     <div class="row">
//       <div class="form-group col-12 col-md-6 pb-3">
//         <label>Email input</label>
//         <input class="form-control w-100" type="email">
//       </div>
//       <div class="form-group col-12 col-md-6 pb-3">
//         <label>Disabled input</label>
//         <input class="form-control w-100" disabled type="Email">
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide forms.1

$envelopeIcon: 'https://www.avery.com/app/images/icons/forms/icon-envelope.svg';
$invalidIcon: 'https://www.avery.com/app/images/icons/forms/icon-triangle-error.svg';
$validIcon: 'https://www.avery.com/app/images/icons/forms/icon-green-check.svg';
$passwordIcon: 'https://www.avery.com/app/images/icons/forms/icon-lock.svg';

$margin: 8;
$marginSM: 5;
$marginLG: 10;
$marginXL: 14;

$errorIcon: url($invalidIcon) no-repeat right rem(16) center / rem(20) rem(20);
$successIcon: url($validIcon) no-repeat right rem(16) center / rem(20) rem(20);

@function inputIcon($type, $margin, $width) {
  @return url($type) no-repeat left rem($margin) center / rem($width) rem($width);
}

@mixin formAnimation {
  animation-duration: 0.1ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

@mixin inputType($iconType, $iconSize) {
  padding-left: rem($margin * 2 + $iconSize);
  background: inputIcon($iconType, $margin, $iconSize);

  &.is-invalid {
    background: inputIcon($iconType, $margin, $iconSize), $errorIcon;
  }

  &.is-valid {
    background: inputIcon($iconType, $margin, $iconSize), $successIcon;
  }

  &.form-control-sm {
    padding-left: rem($marginSM * 2 + $iconSize);
    background: inputIcon($iconType, $marginSM, $iconSize);

    &.is-invalid {
      background: inputIcon($iconType, $marginSM, $iconSize), $errorIcon;
    }

    &.is-valid {
      background: inputIcon($iconType, $marginSM, $iconSize), $successIcon;
      background-color: #ecfbeb;
    }
  }

  &.form-control-lg {
    padding-left: rem($marginLG * 2 + $iconSize);
    background: inputIcon($iconType, $marginLG, $iconSize);

    &.is-invalid {
      background: inputIcon($iconType, $marginLG, $iconSize), $errorIcon;
    }

    &.is-valid {
      background: inputIcon($iconType, $marginLG, $iconSize), $successIcon;
      background-color: #ecfbeb;
    }
  }

  &.form-control-xl {
    padding-left: rem($marginXL * 2 + $iconSize);
    background: inputIcon($iconType, $marginXL, $iconSize);

    &.is-invalid {
      background: inputIcon($iconType, $marginXL, $iconSize), $errorIcon;
    }

    &.is-valid {
      background: inputIcon($iconType, $marginXL, $iconSize), $successIcon;
    }
  }
}

.form-control {
  background-color: $main-white !important;
  border-color: $charcoal-black;
  border-radius: rem(3) !important;
  border-width: rem(1);
  font-size: rem(14);
  height: auto;
  line-height: 1.3;
  padding: rem(8);
  position: relative;

  &::placeholder {
    color: #6D6D6D;
  }

  &.form-control-lg {
    font-size: rem(16);
    letter-spacing: rem(0.67);
    padding: rem($marginLG);
  }

  &.form-control-sm {
    font-size: rem(12);
    letter-spacing: rem(0.5);
    padding: rem($marginSM);
  }

  &.form-control-xl {
    font-size: rem(18);
    letter-spacing: rem(0.6);
    line-height: 1.11;
    padding: rem(20) rem($marginXL);
  }

  .input-group & {
    box-shadow: none;
    &[type='search'],
    &.search {
      border-radius: 0 !important;
    }
  }

  &:focus {
    border-color: $primary-blue;
    box-shadow: inset 0 0 0 1px $primary-blue;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    &.is-valid {
      animation-name: autofill-valid;
      animation-fill-mode: both;
    }
    &.is-invalid {
      animation-name: autofill-invalid;
      animation-fill-mode: both;
    }

    &[type='password'],
    &.password {
      animation-name: autofill-password;
      @include formAnimation;

      &.is-valid {
        animation-name: autofill-password-valid;
        @include formAnimation;
      }
      &.is-invalid {
        animation-name: autofill-password-invalid;
        @include formAnimation;
      }

      @keyframes autofill-password {
        to {
          background-color: $main-white !important;
          background: inputIcon($passwordIcon, $marginSM, 16) $main-white;
        }
      }
      @keyframes autofill-password-valid {
        to {
          background-color: $main-white !important;
          background: inputIcon($passwordIcon, $marginSM, 16), $successIcon;
        }
      }

      @keyframes autofill-password-invalid {
        to {
          background-color: $main-white !important;
          background: inputIcon($passwordIcon, $marginSM, 16) no-repeat, $errorIcon;
        }
      }
    }

    &[type='email'],
    &.email {
      animation-name: autofill-email;
      @include formAnimation;

      &.is-valid {
        animation-name: autofill-email-valid;
        @include formAnimation;
      }
      &.is-invalid {
        animation-name: autofill-email-invalid;
        @include formAnimation;
      }
    }
    @keyframes autofill-email {
      to {
        background: inputIcon($envelopeIcon, $marginSM, 20) $main-white;
      }
    }
    @keyframes autofill-email-valid {
      to {
        border-color: $bright-green;
        background: inputIcon($envelopeIcon, $marginSM, 20), $successIcon;
      }
    }
    @keyframes autofill-email-invalid {
      to {
        background: inputIcon($envelopeIcon, $marginSM, 20), $errorIcon;
        border-color: $bright-red;
      }
    }
  }

  &.is-invalid {
    background: $errorIcon;
    background-color: #fdf3f3 !important;
    border-color: $bright-red;
    box-shadow: inset 0 0 0 1px $bright-red;
    color: $bold-red;
    padding-right: rem(38);
  }

  &.is-valid {
    background: $successIcon;
    background-color: #e6f5e5 !important;
    border-color: $bright-green;
    box-shadow: inset 0 0 0 1px $bright-green;
    padding-right: rem(38);
  }

  &[type='password'],
  &.password {
    @include inputType($passwordIcon, 20);
  }

  &[type='email'],
  &.email {
    @include inputType($envelopeIcon, 20);
  }

  &[type='search'],
  &.search {
    border-radius: rem(20) !important;
  }

  &.disabled,
  &:disabled {
    border-color: $light-gray;
    cursor: not-allowed !important;
    opacity: 0.4;
  }
}

.invalid-feedback {
  color: $bright-red;
  font-size: inherit;
}

.valid-feedback {
  color: $bright-green;
}

.text-entry-control {
  &:focus {
    outline: none;
  }
}
