/* ==========================================================================
   INFO DESCRIPTION TILE - COMMON

   NOTE: Grouped info description tiles to save file size space when compiled.
   ========================================================================== */

// FIXME: Deprecated - Remove `.tile-info-description, .tile-info-description-img-spaced, .tile-item-description-full-collapse` and all its nested styles once verified not/no longer used.
.tile-info-description,
.tile-info-description-img-spaced,
.tile-item-description-full-collapse {
  @include tile-thumb-label();

  $text-details-height: 80px;

  &[class*="tile-cropped"] {
    @include media-breakpoint-down(sm) {
      .tile-img {
        min-height: 0;
        line-height: 0;
        img {
          margin: 0;
        }
      }
    }
  }

  .flexible-img {
    height: auto;
    flex: 0 0 100px;
    padding: rem(10);
    @include media-breakpoint-up(md) {
      height: 175px;
      flex: 0 0 auto;
    }
    .tile-img {
      border-radius: 0;
    }
  }

  .tile-text {
    text-align: left;
    padding: 0;
  }

  .text-main-long {
    p {
      margin-bottom: rem(5);
    }
  }

  // Text Details
  .text-details {
    min-height: $text-details-height;

    ul, ul.text-truncate {
      max-height: $text-details-height;
      overflow: hidden;
      margin: 0;
      padding-left: rem(16);
    }

    :last-child:not(:only-child):not(.btn-avry) {
      margin: 0;
      padding: 0;
    }
  }

  .text-truncate-multiline {
    max-height: $text-details-height;
    margin: 0;
    padding: 0 rem(10) 0 0;
    text-align: left;
  }

  &.h-auto {
    @include media-breakpoint-down(sm) {
      .tile-text.text-details {
        height: auto;
        max-height: none;

        p, ul {
          height: auto;
          max-height: none;
        }
      }
    }
  }
}

// By default tiles are stacked verticall (like tiles in a grid layout), the
// following are the tile types that collapse at certain breakpoints.
// FIXME: Deprecated - Remove `.tile-info-description, .tile-info-description-img-spaced` and all its nested styles once verified not/no longer used.
.tile-info-description,
.tile-info-description-img-spaced {
  @include tile-collapsable(116px); // If .tile-info-description has the 'tile-cropped-*' classes
}


/* ==========================================================================
   INFO DESCRIPTION TILE - DEFAULT
   ========================================================================== */

//-// Info Description Tile - Default
//-//
//-// Tiles containing simply a title and some information text.
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12 col-md-4">
//-//     <a href="#" class="text-decoration-none">
//-//       <div class="tile-item tile-info-description tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate">Product Support</p>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati vero quasi beatae sed repellendus alias, aspernatur illum neque cumque explicabo, delectus, similique fuga incidunt asperiores, nisi non! Quos, nobis, unde.</p>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-//   <div class="col-12 col-md-4">
//-//     <a href="#" class="text-decoration-none">
//-//       <div class="tile-item tile-info-description tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_360/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate">Product Support</p>
//-//           </div>
//-//           <div class="tile-text text-link text-truncate">
//-//             <span class="text-truncate">View our Entire Selection</span>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <ul class="text-truncate">
//-//               <li class="text-truncate">Attribute Number 1</li>
//-//               <li class="text-truncate">Attribute Number 2</li>
//-//               <li class="text-truncate">Attribute Number 3</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.info-description


/* ==========================================================================
   INFO DESCRIPTION TILE HEADLINE

   NOTE: Utilizes the Info Description Tile - Spaced class as its base. It contains an external tile header headline as the addition for MD, LG, and XL breakpoints.
   TODO: Overall, this tile is almost like the `.tile-info-description-img-spaced` except this has an external Headline.
         Should try consolidating this tile into `.tile-info-description-img-spaced`.
   ========================================================================== */

//-// Info Description Tile - Headline
//-//
//-// Utilizes the [Info Description Tile - Spaced](section-tiles.html#kssref-tiles-info-description-spaced) class as its base. It contains an external tile header headline as the addition for MD, LG, and XL breakpoints.
//-//
//-// <span style="color: red;">*Important: This tile should be consolidated into the `.tile-info-description-img-spaced` tile.*</span>
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12 col-md-4">
//-//     <h4 class="tile-info-description-label text-center d-none d-md-block">Custom Printing</h4>
//-//     <a class="text-decoration-none" href="https://accounts.avery.com/">
//-//       <div class="tile-item tile-info-description-headline tile-info-description-img-spaced tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_03.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <h4 class="tile-info-description-label text-truncate d-md-none">Custom Printing</h4>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tristique.</p>
//-//           </div>
//-//           <button class="btn btn-avry">View All</button>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.info-description-headline

// FIXME: Deprecated - Remove `.tile-info-description-headline` and all its nested styles once verified not/no longer used.
.tile-info-description-headline {
  margin-top: rem(20);
  text-align: center;

  .tile-text {
    & .text-truncate-multiline {
      padding: 0 rem(10);

      @include media-breakpoint-down(sm) {
        padding: 0 rem(10) 0 0;
      }
      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }
  }
  .btn.btn-avry {
    min-width: 100px;
    margin-top: rem(10);
    @include media-breakpoint-up(md) {
      min-width: 140px;
      margin-top: rem(30);
    }
  }
}


/* ==========================================================================
   INFO DESCRIPTION - IMAGE SPACED

   NOTE: A variation of the info description tile.
   ========================================================================== */

//-// Info Description Tile - Image Spaced
//-//
//-// A close variation of the [Info Description Tile (Default)](/section-tiles.html#kssref-tiles-info-description) except this tile has padding around the image on XS and SM breakpoints. These tiles have no differences in MD, LG, and XL when compared to the [Info Description Tile (Default)](/section-tiles.html#kssref-tiles-info-description). We see these tiles in the avery.com [Homepage Headline tiles](/section-tiles.html#kssref-tiles-info-description-headline) and *WePrint category tiles*.
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12 col-md-4">
//-//     <a class="text-decoration-none" href="https://accounts.avery.com/">
//-//       <div class="tile-item tile-info-description-img-spaced tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_09.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate">Rectangle Labels</p>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Make your products, promotions and personal events stand out in a crowd.</p>
//-//           </div>
//-//           <div class="tile-text tile-action">
//-//             <div class="row align-items-center">
//-//               <div class="col-12 col-md-6">
//-//                 <span class="font-italic">Starting at only &nbsp;</span><strong>$7.00</strong>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.info-description-img-spaced

// FIXME: Deprecated - Remove `.tile-info-description-img-spaced` and all its nested styles once verified not/no longer used.
.tile-info-description-img-spaced {
  @include tile-thumb-img-spaced();

  @include media-breakpoint-down(sm) {
    .btn.btn-avry {
      display: none;
    }
  }
}


/* ==========================================================================
   INFO DESCRIPTION - FULL COLLAPSE
   ========================================================================== */

//-// Info Description Tile - Full Collapse
//-//
//-// A tile that would remain collapsed in all break points. A collapse tile would have an image on the left and content on the right.
//-//
//-// **NOTE:** These tiles will have a fixed height of 150px in XS and SM breakpoints, 185px on the MD breakpoint, 220px on the LG breakpoint, and 275px on the XL(and above) breakpoint.
//-// <br>
//-// **IMPORTANT:** It is <span style="color: red;">highly recommended</span> to use these tiles for 2 or 1 column layouts. There is no space to use these tiles in 3 and above column layouts.
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12 col-md-6">
//-//     <a href="#" class="text-decoration-none">
//-//       <div class="tile-item tile-item-description-full-collapse">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://img.avery.com/web/weprint/home/img-tile-business-cards.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://img.avery.com/web/weprint/home/img-tile-business-cards.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://img.avery.com/web/weprint/home/img-tile-business-cards.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate h3">Cards</p>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <!-- Show for MD ONly -->
//-//             <p class="d-lg-none mb-2">Excellent for promotions, networking and more</p>
//-//             <!-- Show for LG and above -->
//-//             <ul class="list-checkmark d-none d-lg-block">
//-//               <li>Excellent for promotions, networking and more</li>
//-//               <li>Beautiful, full-color graphics</li>
//-//               <li>One or two-sided printing available</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.info-description-full-collapse

// FIXME: Deprecated - Remove `.tile-item-description-full-collapse` and all its nested styles once verified not/no longer used.
.tile-item-description-full-collapse {
  $tile-height: 150px;
  $tile-height-md: 185px;
  $tile-height-lg: 220px;
  $tile-height-xl-up: 275px;
  $text-details-height: 60px;

  display: flex;
  flex-direction: row;
  height: $tile-height;
  margin-top: 0;

  @include padded-tile-image($tile-height);

  // Tile and Image adjustments for
  @include media-breakpoint-only(md) {
    height: $tile-height-md;
    .tile-img img {
      max-width: $tile-height-md;
    }
  }
  @include media-breakpoint-only(lg) {
    height: $tile-height-lg;
    .tile-img img {
      max-width: $tile-height-lg;
    }
  }
  @include media-breakpoint-up(xl) {
    height: $tile-height-xl-up;
    .tile-img img {
      max-width: $tile-height-xl-up;
    }
  }

  .tile-img {
    flex: 0 0 auto;
    flex-shrink: 0;
    margin-right: rem(16);

    &, img {
      @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-top-left-radius: $tile-border-radius;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: $tile-border-radius;
      }
    }

    img {
      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }
  }

  .tile-block {
    width: 100%;
    padding: rem(10) rem(10) rem(10) 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: rem(10) rem(10) rem(20) 0;
    }
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
}


/* ==========================================================================
   INFO DESCRIPTION TILE - FULL WIDTH
   ========================================================================== */

//-// Info Description Tile - Full Width
//-//
//-// Utilizes the [Description Block Full Collapse](/section-tiles.html#kssref-tiles-info-description-full-collapse) as base. This tile is meant to take on the full width of the container width.
//-//
//-// **IMPORTANT:** This class is meant to be used with the `.tile-item-description-full-collapse` class.
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12">
//-//     <a href="#" class="text-decoration-none">
//-//       <div class="tile-item tile-item-description-full-collapse tile-info-description-full-width tile-cropped-col-2">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://img.avery.com/web/weprint/home/img-weprint-tile-homepagebanner.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://img.avery.com/web/weprint/home/img-weprint-tile-homepagebanner.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid" srcset="https://img.avery.com/web/weprint/home/img-weprint-tile-homepagebanner.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate h3">Labels</p>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <!-- Show for MD ONly -->
//-//             <p class="d-none d-md-block d-lg-none mb-2">Wide variety of sizes and materials</p>
//-//             <!-- Show for LG and above -->
//-//             <ul class="list-checkmark d-none d-lg-block">
//-//               <li>Wide variety of sizes and materials</li>
//-//               <li>Perfect for product labeling, promotions and personal events</li>
//-//               <li>Custom sizes available at no extra cost</li>
//-//             </ul>
//-//             <p class="d-md-none">Prices start at $7 (USD)</p>
//-//             <div>
//-//               <span class="d-none d-md-block">Prices start at $7 (USD)</span>
//-//               <button class="btn btn-avry btn-action mt-md-3 mt-lg-0 ml-lg-3 d-md-block d-lg-inline-block">Start With Labels</button>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.info-description-full-width

// FIXME: Deprecated - Remove `.tile-info-description-full-width` and all its nested styles once verified not/no longer used.
.tile-info-description-full-width {
  @include media-breakpoint-up(md) {
    overflow: hidden;

    .tile-img {
      flex: 0 0 55%;

      img {
        max-width: none;
      }
    }
  }
}
