// Buttons
//
// NOTE: Avery Button Styles inherits Bootstrap's Button Styles.
//
// Styleguide buttons

// CTA's
//
// Since buttons are called CTA's by CM and UX teams the new buttons(.btn) are called CTA's(.cta).
//
// Note the double dash for modifyer classes
//
// Also available are the media queried cta's. ie. (cta-sm--sm, cta-md, cta-lg--lg, cta-xl--sm)
//
// .cta--sm - Small Size
// .cta--lg - Large Size
// .cta--xl - Extra Large Size
// .disabled - Disabled
// .selected - Selected
// .cta--removable - Remove x
// .cta--pill - Pill shaped
//
// Markup:
// cta--primary
// <p>
//   <button class="cta cta--primary {{modifier_class}}">Default</button>
//   <button class="cta cta--primary cta--blue {{modifier_class}}">cta--blue</button>
//   <button class="cta cta--primary cta--red {{modifier_class}}">cta--red</button>
//   <button class="cta cta--primary cta--yellow {{modifier_class}}">cta--yellow</button>
//   <button class="cta cta--primary cta--black {{modifier_class}}">cta--black</button>
//   <button class="cta cta--primary cta--green {{modifier_class}}">cta--green</button>
// </p>
// cta--secondary
// <p>
//   <button class="cta cta--secondary {{modifier_class}}">Default</button>
//   <button class="cta cta--secondary cta--blue {{modifier_class}}">cta--blue</button>
//   <button class="cta cta--secondary cta--red {{modifier_class}}">cta--red</button>
//   <button class="cta cta--secondary cta--yellow {{modifier_class}}">cta--yellow</button>
//   <button class="cta cta--secondary cta--black {{modifier_class}}">cta--black</button>
//   <button class="cta cta--secondary cta--green {{modifier_class}}">cta--green</button>
// </p>
// cta--selection
// <p>
//   <button class="cta cta--selection {{modifier_class}}">CTA Default</button>
// </p>
//
// Styleguide buttons.1

.cta {
  background-color: transparent;
  border-color: transparent;
  border-radius: rem(3);
  border-style: solid;
  border-width: rem(1);
  color: $base-dark;
  cursor: pointer;
  display: inline-block;
  font-size: rem(14);
  line-height: 1.3;
  padding: rem(8) rem(22);
  position: relative;
  text-align: center;
  vertical-align: middle;

  .input-group & {
    padding: rem(7) rem(12);
  }
  .input-group-sm & {
    @include media-breakpoint-up(sm) {
      font-size: rem(12);
      padding: rem(4) rem(9);
    }
  }
  .input-group-lg & {
    @include media-breakpoint-up(lg) {
      font-size: rem(16);
      padding: rem(9) rem(16);
    }
  }

  &:not(.disabled) {
    &:hover {
      text-decoration: none;
      background-color: $faint-gray;
    }
    &:focus {
      outline: 2px solid -webkit-focus-ring-color;
      outline-offset: 1px;
    }
  }
  .input-group-prepend & {
    border-bottom-left-radius: rem(3);
    border-bottom-right-radius: 0;
    border-top-left-radius: rem(3);
    border-top-right-radius: 0;
    margin-right: -2px;
    z-index: +1;
  }
  .input-group-append & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: rem(3);
    border-top-left-radius: 0;
    border-top-right-radius: rem(3);
    margin-left: -2px;
  }
  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.4;
  }

  &.cta--sm {
    font-size: rem(12);
    letter-spacing: rem(0.5);
    padding: rem(5) rem(16);
    text-transform: initial;
  }
  &.cta-sm--sm {
    @include media-breakpoint-up(sm) {
      font-size: rem(12);
      letter-spacing: rem(0.5);
      padding: rem(5) rem(16);
    }
  }
  &.cta-md--sm {
    @include media-breakpoint-up(md) {
      font-size: rem(12);
      letter-spacing: rem(0.5);
      padding: rem(5) rem(16);
      text-transform: inital;
    }
  }
  &.cta-lg--sm {
    @include media-breakpoint-up(lg) {
      font-size: rem(12);
      letter-spacing: rem(0.5);
      padding: rem(5) rem(16);
      text-transform: inital;
    }
  }
  &.cta-xl--sm {
    @include media-breakpoint-up(xl) {
      font-size: rem(12);
      letter-spacing: rem(0.5);
      padding: rem(5) rem(16);
      text-transform: inital;
    }
  }

  &.cta-sm--md {
    @include media-breakpoint-up(sm) {
      font-size: rem(14);
      letter-spacing: rem(0.58);
      padding: rem(8) rem(22);
    }
  }
  &.cta-md--md {
    @include media-breakpoint-up(md) {
      font-size: rem(14);
      letter-spacing: rem(0.58);
      padding: rem(8) rem(22);
    }
  }
  &.cta-lg--md {
    @include media-breakpoint-up(lg) {
      font-size: rem(14);
      letter-spacing: rem(0.58);
      padding: rem(8) rem(22);
    }
  }
  &.cta-xl--md {
    @include media-breakpoint-up(xl) {
      font-size: rem(14);
      letter-spacing: rem(0.58);
      padding: rem(8) rem(22);
    }
  }

  &.cta--lg {
    font-size: rem(16);
    letter-spacing: rem(0.67);
    padding: rem(10) rem(33);
  }
  &.cta-sm--lg {
    @include media-breakpoint-up(sm) {
      font-size: rem(16);
      letter-spacing: rem(0.67);
      padding: rem(10) rem(33);
    }
  }
  &.cta-md--lg {
    @include media-breakpoint-up(md) {
      font-size: rem(16);
      letter-spacing: rem(0.67);
      padding: rem(10) rem(33);
    }
  }
  &.cta-lg--lg {
    @include media-breakpoint-up(lg) {
      font-size: rem(16);
      letter-spacing: rem(0.67);
      padding: rem(10) rem(33);
    }
  }
  &.cta-xl--lg {
    @include media-breakpoint-up(xl) {
      font-size: rem(16);
      letter-spacing: rem(0.67);
      padding: rem(10) rem(33);
    }
  }

  &.cta--xl {
    font-size: rem(18);
    letter-spacing: rem(0.6);
    line-height: 1.11;
    padding: rem(20) rem(14);
  }
  &.cta-sm--xl {
    @include media-breakpoint-up(sm) {
      font-size: rem(18);
      letter-spacing: rem(0.6);
      line-height: 1.11;
      padding: rem(20) rem(14);
    }
  }
  &.cta-md--xl {
    @include media-breakpoint-up(md) {
      font-size: rem(18);
      letter-spacing: rem(0.6);
      line-height: 1.11;
      padding: rem(20) rem(14);
    }
  }
  &.cta-lg--xl {
    @include media-breakpoint-up(lg) {
      font-size: rem(18);
      letter-spacing: rem(0.6);
      line-height: 1.11;
      padding: rem(20) rem(14);
    }
  }
  &.cta-xl--xl {
    @include media-breakpoint-up(xl) {
      font-size: rem(18);
      letter-spacing: rem(0.6);
      line-height: 1.11;
      padding: rem(20) rem(14);
    }
  }
}

.cta--primary {
  background-color: $bold-gray;
  border-color: $bold-gray;
  color: $main-white !important;
  text-transform: uppercase;

  &:not(.disabled) {
    &:hover {
      background-color: $base-gray;
      border-color: $base-gray;
    }
  }

  body[data-avry-mode='industrial'] & {
    background-color: $industrial-gray;
    border-color: $industrial-gray;
    font-weight: bold;
  }
}

.cta--secondary {
  background-color: $main-white !important;
  border-color: $base-dark;
  color: $base-dark;
  text-transform: uppercase;

  &:not(.disabled) {
    &:active {
      background-color: $main-white !important;
    }
    &:hover {
      background-color: $light-gray !important;
    }
  }

  body[data-avry-mode='industrial'] & {
    border-color: $industrial-gray;
    color: $industrial-gray;
    font-weight: bold;

    &:not(.disabled) {
      &:hover {
        background-color: $light-gray !important;
      }
    }
  }
}

// Prepend and Append buttons
//
// For number inputs search and such
//
// Markup:
// <div class="row">
//   <div class="col-auto">
//     <div class="input-group">
//       <span class="input-group-prepend">
//         <button class="cta cta--primary cta--black" type="button">
//           <span class="fonticon fonticon--minus"></span>
//         </button>
//       </span>
//       <input class="form-control text-center" id="amount" type="number">
//       <span class="input-group-append">
//         <button class="cta cta--primary cta--black" type="button">
//           <span class="fonticon fonticon--plus"></span>
//         </button>
//       </span>
//     </div>
//   </div>
//   <div class="col-auto">
//     <div class="input-group">
//       <span class="input-group-prepend">
//         <button class="cta cta--primary cta--yellow" type="button">
//           <span class="fonticon fonticon--map-pin"></span>
//         </button>
//       </span>
//       <input class="form-control" id="map" type="text">
//     </div>
//   </div>
//   <div class="col-auto">
//     <div class="input-group">
//       <input class="form-control" id="search" type="text">
//       <span class="input-group-append">
//         <button class="cta cta--primary cta--blue" type="button">
//           <span class="fonticon fonticon--search"></span>
//         </button>
//       </span>
//     </div>
//   </div>
// </div>
//
// Styleguide buttons.2

.cta--blue {
  border-color: $primary-blue;
  background-color: $primary-blue;
  color: $primary-blue;

  &:not(.disabled) {
    &:active {
      background-color: $primary-blue;
    }
    &:hover {
      background-color: $primary-hover-blue;
      border-color: $primary-hover-blue;
      &.cta--secondary {
        background-color: $light-blue !important;
        color: $primary-blue;
      }
    }
  }

  body[data-avry-mode='industrial'] & {
    background-color: $industrial-blue;
    border-color: $industrial-blue;

    &:not(.disabled) {
      &:active {
        background-color: $industrial-blue;
      }
      &:hover {
        background-color: $hyper-deep-blue;
        border-color: $hyper-deep-blue;
        &.cta--secondary {
          background-color: $light-gray !important;
          color: $industrial-blue;
        }
      }
    }
  }
}

.cta--red {
  border-color: $bright-red;
  background-color: $bright-red;
  color: $bright-red;

  &:not(.disabled) {
    &:active {
      background-color: $bright-red;
    }
    &:hover {
      background-color: $hover-red;
      border-color: $hover-red;
      &.cta--secondary {
        background-color: $light-red !important;
        color: $bright-red;
      }
    }
  }

  body[data-avry-mode='industrial'] & {
    background-color: $bold-red;
    border-color: $bold-red;

    &:not(.disabled) {
      &:active {
        background-color: $bold-red;
      }
      &:hover {
        background-color: $bright-red;
        border-color: $bright-red;
        &.cta--secondary {
          background-color: $light-gray !important;
          color: $bright-red;
        }
      }
    }
  }
}

.cta--yellow {
  background-color: $industrial-yellow;
  border-color: $industrial-yellow;
  color: $industrial-yellow;

  &:not(.disabled) {
    &:active {
      background-color: $industrial-yellow;
    }
    &:hover {
      background-color: #ecb21a;
      border-color: #ecb21a;
      &.cta--secondary {
        background-color: $light-gray !important;
        color: $industrial-yellow;
      }
    }
  }

  body[data-avry-mode='industrial'] & {
    background-color: $industrial-yellow;
    border-color: $industrial-yellow;
  }
}

.cta--black {
  background-color: $charcoal-black;
  border-color: $charcoal-black;
  color: $charcoal-black;

  &:not(.disabled) {
    &:active {
      background-color: $charcoal-black;
    }
    &:hover {
      background-color: $industrial-gray;
      border-color: $industrial-gray;
      &.cta--secondary {
        background-color: $light-gray !important;
        color: $charcoal-black;
      }
    }
  }
}

.cta--green {
  background-color: $base-green;
  border-color: $base-green;
  color: $base-green;

  &:not(.disabled) {
    &:active {
      background-color: $base-green;
    }
    &:hover {
      background-color: $hover-green;
      border-color: $hover-green;
      &.cta--secondary {
        background-color: $light-green !important;
        color: $base-green;
      }
    }
  }

  // body[data-avry-mode='industrial'] & {
  //   background-color: $industrial-green;
  //   border-color: $industrial-green;

  //   &:not(.disabled) {
  //     &:active {
  //       background-color: $industrial-green;
  //     }
  //     &:hover {
  //       background-color: $industrial-hover-green;
  //       border-color: $industrial-hover-green;
  //       &.cta--secondary {
  //         background-color: $light-gray !important;
  //         color: $industrial-hover-green;
  //       }
  //     }
  //   }
  // }
}

.cta--selection {
  background-color: $main-white !important;
  border-color: $charcoal-black;
  color: $charcoal-black;

  &:not(.disabled) {
    &:hover {
      border-color: $primary-blue !important;
      box-shadow: inset 0px 0px 0px 1px $primary-blue;
    }
    &.selected,
    &:active {
      background-color: $deep-powder-blue !important;
      border-color: $primary-blue;
      box-shadow: inset 0px 0px 0px 1px $primary-blue;

      body[data-avry-mode='industrial'] & {
        background-color: $industrial-powder-blue;
        border-color: $industrial-blue;
      }
    }
  }
}

.cta--removable {
  &:after {
    font-family: 'avery-fonticon';
    content: '\e910';
    font-size: 0.625rem;
    padding-left: 1.25rem;
  }
}

.cta--pill {
  border-radius: rem(25);
}

.input-group > .input-group-append .btn-avry {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

//-// Select buttons (deprecated)
//-//
//-// New updated selection buttons for default and industrial use,
//-// notice the double dash.
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// .disabled - Disabled
//-// :hover - Hover State
//-// :focus - Active State
//-// .selected - Selected
//-// .selected:focus - Active State
//-//
//-// Markup:
//-// <button class="btn btn--selection {{modifier_class}}">Avery Button</button>
//-//
//-// Styleguide buttons.selection-buttons
.btn.btn--selection {
  background-color: #fff;
  border: rem(1) solid #383d46;
  border-radius: rem(3);

  &:hover {
    background-color: #f1f1f1;
  }

  // Default Active & Focus State Override
  &:active:focus,
  &:focus {
    outline: none;
    box-shadow: 0 0 rem(10) rgba(#00b4eb, 0.6);
  }

  &.selected:active,
  &.selected:focus {
    box-shadow: 0 0 rem(10) rgba(#00b4eb, 0.4);
  }

  // Selected, Active
  &.selected,
  &.selected:active,
  &.selected:focus {
    background-color: $light-blue;
    border: rem(2) solid #1464e4;
    border-radius: rem(3);

    body[data-avry-mode='industrial'] & {
      background-color: #e0f4fb;
      border: rem(2) solid #0057b8;
    }
  }

  // Disabled State
  &.disabled {
    cursor: not-allowed;
    background-color: #fff !important;
    border: rem(1) dashed #b9b9b9 !important;
    color: #b9b9b9 !important;
  }
}

body:not([data-avry-mode='industrial']) {
  //-// Avery Main Button (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .btn-lg - Large Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-// :focus - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry {{modifier_class}}">Avery Button</button>

  .btn.btn-selection {
    // TODO: delete .btn-selection
    // once all select buttons have been switched ot the new ones above.
    background-color: #fff;

    &.selected,
    &.selected:active,
    &.selected:focus {
      background-color: #f3fcff;
      border: rem(1) solid #00b4eb;
      box-shadow: 0 0 rem(10) rgba(#00b4eb, 0.6);
    }
  }

  .btn.btn-avry {
    border-radius: $avry-btn-border-radius;
    background-color: #fff;
    border: rem(1) solid #242424;
    color: #242424;
    cursor: pointer;
    position: relative;

    p {
      margin: 0;
      line-height: rem(16);
    }

    // Disabled State
    &.disabled {
      cursor: not-allowed;
      background-color: #fff !important;
      border: rem(2) dashed #b9b9b9 !important;
      color: #b9b9b9 !important;
    }

    // Active State
    &:not(.disabled):active:focus,
    &:not(.disabled):active {
      @include on-button-active(#f1f1f1, #242424);
      @include on-button-text-active();
    }

    // Focus State
    &:not(.disabled):not(:active):focus {
      border: rem(1) solid #00b4eb;
      box-shadow: 0 0 rem(10) rgba(#00b4eb, 0.6);
    }

    // Default Active & Focus State Override
    &:active:focus,
    &:focus {
      outline: none;
    }

    // Hover State
    &:hover {
      background-color: #f1f1f1;
    }

    // Large Buttons
    &.btn-lg:not(.disabled):active:focus,
    &.btn-lg:not(.disabled):active {
      @include on-button-text-active(lg);
    }
    // Small Buttons
    &.btn-sm:not(.disabled):active:focus,
    &.btn-sm:not(.disabled):active {
      // line-height: ensure proper height of button next to small input
      @include on-button-text-active(sm);
    }
  }

  //-// Avery Button (Outline - Blue) (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-outline-blue'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .btn-lg - Large Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-// :focus - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-outline-blue {{modifier_class}}">Avery Button</button>

  .btn.btn-avry.btn-outline-blue {
    border: rem(1) solid #0d4fa8 !important;
    color: #0d4fa8 !important;

    // Active State
    &:not(.disabled):active:focus,
    &:not(.disabled):active {
      @include on-button-active(#f1f1f1, #0d4fa8);
    }
  }

  //-// Action Buttons (Red) (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-action'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .btn-lg - Large Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-action {{modifier_class}}">Avery Button</button>

  .btn.btn-avry.btn-action {
    @include btn-action(#ea001c, #fff, #c5001d, #99001d);

    .input-group & {
      border-color: #c5001d;
    }
  }

  //-// Action Buttons (Green) (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-action-green'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .btn-lg - Large Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-action-green {{modifier_class}}">Avery Button</button>

  .btn.btn-avry.btn-action-green {
    @include btn-action(#0acc00, #fff, #07a200, #076f00);

    .input-group & {
      border-color: #07a200;
    }
  }

  //-// Action Buttons (Blue) (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-action-blue'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .btn-lg - Large Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-action-blue {{modifier_class}}">Avery Button</button>

  .btn.btn-avry.btn-action-blue {
    @include btn-action(#0238a3, $main-white, #0039a6, #0238a3);
  }

  //-// Action Buttons (Gray) (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-action-gray'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .btn-lg - Large Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-action-gray {{modifier_class}}">Avery Button</button>

  .btn.btn-avry.btn-action-gray {
    @include btn-action(#b9b9b9, #fff, #8c8c8c, #6d6d6d);

    .input-group & {
      border-color: #8c8c8c;
    }
  }

  //-// Purchase Action Buttons (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-purchase-action'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-sm - Small Size
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-purchase-action {{modifier_class}}">Avery Button</button>

  .btn.btn-avry.btn-purchase-action {
    background-color: #fff;
    border: rem(1) solid #ea001c;
    color: #ea001c;

    &.btn-sm {
      font-size: rem(12);
    }

    &:not(.disabled):active:focus,
    &:not(.disabled):active {
      @include on-button-active(#fdf3f3, #ea001c);
    }

    &:hover {
      background-color: #fdf3f3;
      border: rem(1) solid #ea001c;
    }
  }

  //-// Selection Buttons (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-selection'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. The ".btn-lg .btn-block" classes are also required to match required style. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .disabled - Disabled
  //-// .selected - When button is Selected
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button type="button" class="btn btn-avry btn-selection btn-lg btn-block {{modifier_class}}">
  //-//   <p><strong>2 Pack</strong></p>
  //-//   <p><strong>$1.27</strong></p>
  //-//   <p>Prod.# 17888</p>
  //-// </button>

  .btn.btn-avry.btn-selection {
    max-width: 200px;
    line-height: rem(24);
    font-size: rem(12);

    &,
    &:active {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &.selected,
    &.selected:active,
    &.selected:focus {
      background-color: #f3fcff;
      border: rem(1) solid #00b4eb;
      box-shadow: 0 0 rem(10) rgba(#00b4eb, 0.6);
    }

    &:not(.disabled):not(.selected):active:focus,
    &:not(.disabled):not(.selected):active {
      @include on-button-active(#f3fcff, #00b4eb, #00b4eb);
    }

    &:not(.selected):hover {
      background-color: #f1f1f1;
      border: rem(1) solid #242424;
    }
  }

  .btn.btn-avry.btn-paypal-express-yellow {
    background-color: #ffc439;
    border: 0.0625rem solid #ffc439;
    color: #000000;

    &:not(.disabled):active {
      background-color: #ffc149;
      box-shadow: inset 0.25rem 0.1875rem 0 #b07f2b;
    }

    &:not(.selected):hover {
      background-color: #ffc149;
      border: 0.0625rem solid #ffc149;
    }
  }

  //-// Circular Button Set (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Circular Buttons are displayed as a row and to be nested as a set by a parent of *'.circle-button-set'* container. Add *'.btn-avry .btn-circle'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .btn-circle-sm - Small Size
  //-// .disabled - Disabled
  //-// .btn-circle-sm:active - Small Size Active State
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <ul class="circle-button-set">
  //-//   <li><button class="btn btn-avry btn-circle {{modifier_class}}"><span>S</span></button></li>
  //-//   <li><button class="btn btn-avry btn-circle {{modifier_class}}"><span>M</span></button></li>
  //-//   <li><button class="btn btn-avry btn-circle {{modifier_class}}"><span>L</span></button></li>
  //-//   <li><button class="btn btn-avry btn-circle {{modifier_class}}"><span>XL</span></button></li>
  //-// </ul>
  //-//
  //-// Styleguide buttons.circular-set

  .circle-button-set {
    li {
      display: inline-block;
    }
  }

  .btn.btn-avry.btn-circle {
    padding: rem(20);
    position: relative;
    border: rem(1) solid #242424;

    > span {
      display: inline-block;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    // Circular Buttons - Disabled
    &.disabled {
      border: rem(1) dashed #b9b9b9 !important;
    }

    // Circular Buttons - Hover
    &:hover {
      background-color: #f3fcff;
      border: rem(1) solid #00b4eb;
    }

    // Circular Buttons - Active
    &:not(.disabled):active:focus,
    &:not(.disabled):active {
      @include on-button-active(#f3fcff, #00b4eb);
      @include on-button-text-active-circle(22px, 20px, 18px);
    }

    // Circular Buttons - Small
    &.btn-circle-sm {
      padding: rem(15);
      font-size: rem(12);
    }

    // Circular Buttons - Small Active
    &.btn-circle-sm:not(.disabled):active:focus,
    &.btn-circle-sm:not(.disabled):active {
      @include on-button-active(#f3fcff, #00b4eb);
      @include on-button-text-active-circle(16px, 15px, 14px);
    }
  }

  //-// Filter Apply Button (depreciated)
  //-//
  //-// This is custom, used with Bootstrap's '.btn' class <br>
  //-// **How to Use:** Add *'.btn-avry .btn-filter-apply'* class with bootstrap's *'.btn'* class for style to take effect. Add modifier class in examples below for additional styles to take effect. Ignore pseudo classes (i.e. ':hover', ':active', etc), these will automatically be implemented in the css.
  //-//
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-avry btn-filter-apply {{modifier_class}}">Avery Button</button>
  //-//
  //-// Styleguide buttons.filter-apply
  .btn.btn-avry.btn-filter-apply {
    border: rem(1) solid #00b4eb;
    color: #00b4eb;

    &:not(.disabled):active:focus,
    &:not(.disabled):active {
      @include on-button-active(#f3fcff, #00b4eb);
    }

    &:hover {
      background-color: #f3fcff;
      color: #00b4eb;
    }
  }

  //-// Filter Button (depreciated)
  //-//
  //-// **Note:** Not an Avery Button, this simply uses bootstrap's default buttons with 'filter button color'. This just simply changes a color of a bootstrap default button.
  //-//
  //-// .disabled - Disabled
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-filter {{modifier_class}}">FILTER</button>
  //-//
  //-// Styleguide buttons.filter
  .btn.btn-filter {
    @include button-variant(#00b4eb, #00b4eb);
  }

  //-// Filter Summary Button (depreciated)
  //-//
  //-// **Note:** Not an Avery Button, Simply uses bootstrap default buttons with 'filter button color'.
  //-//
  //-// .disabled - Disabled
  //-// .selected - Selected
  //-// :active - Active State
  //-// :hover - Hover State
  //-//
  //-// Markup:
  //-// <button class="btn btn-filter-summary {{modifier_class}}">FILTER</button>
  //-//
  //-// Styleguide buttons.filter-summary
  .btn.btn-filter-summary {
    @include button-variant(#fff, #b9b9b9);
    margin: rem(5) rem(10) rem(5) 0;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }

    &:after {
      font-family: 'avery-fonticon';
      content: '\e910';
      font-size: rem(10);
      padding-left: rem(20);
      position: relative;
      top: -1px;
    }

    &.selected,
    &.selected:active,
    &.selected:focus {
      background-color: #f3fcff;
      border: rem(1) solid #00b4eb;
      box-shadow: 0 0 rem(10) rgba(#00b4eb, 0.6);
    }

    &.btn-filter-summary-no-close:after {
      display: none;
    }
  }
}
