/* ==========================================================================
   SWIPER CAROUSEL

   TODO: Verify if these styles can go inside the global common swiper styles.
   ========================================================================== */

.swiper-carousel-avry {
  max-width: none;

  > .container {
  //   max-width: map-get($container-max-widths, xl) + $container-space-offset;
    padding: 0;
  }

  .swiper-container {
    padding-left: rem(10);
    padding-right: rem(10);
  }

  .swiper-slide .tile-item {
    margin: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    $swiper-square: 50px;

    width: auto;
    height: auto;

    &:hover {
      opacity: 0.70;
    }

    &:before {
      display: block;
      width: $swiper-square;
      height: $swiper-square;
      border: 1px solid $base-border-color;
      border-radius: rem(25);
      background: rgba($main-white, 0.5); ///$main-white;
      line-height: 1.25;
    }
  }

  .swiper-button-next {
    right: -15px;

    &:before {
      padding-left: 0;
    }
  }
  .swiper-button-prev {
    left: -15px;

    &:before {
      padding-right: rem(18);
    }
  }
}
