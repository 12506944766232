@use "sass:math";

/* ==========================================================================
   COMMON (ALL tile item should have these styles)
   ========================================================================== */

/*
   LIST & GRID - Common

   @param { map|object } $config - optional - configuration map/object. Params: [ border-radius ].
   ========================================================================== */
@mixin tile-container-style($config: ()) {
  @include global-drop-shadow();
  border: 0;

  @if map-has-key($config, border-radius) {
    border-radius: map-get($config, border-radius);
  } @else {
    border-radius: $tile-border-radius;
  }

  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in, color 0.2s ease-in;

  &:hover {
    @include tile-container-hover-style();
  }

  &:active {
    transition: box-shadow 0.1s ease-in, color 0.1s ease-in;
    box-shadow: none;
  }
}

@mixin tile-container-hover-style() {
  box-shadow: rem(3) rem(3) rem(10) $bold-gray;
}

@mixin tile-container-common() {
  @include tile-container-style();

  // TODO: Confirm if these styles are being used.
  // .tile-header {
  //   padding: rem(12) rem(20);
  //   background: transparent;
  //   border: 0;
  // }

  .tile-img {
    border-radius: 0;
  }
}

/*
   Tile Grid - Common

   NOTE: Grid Tile Content Utilizes Bootstrap Cards.
   ========================================================================== */
@mixin tile-container-grid-common() {
  padding-bottom: rem($footer-space);
  margin-bottom: rem($grid-gutter-width);

  &:before {
    padding-top: math.div($gutter-base, 2);
  }

  // Sets Grid Image to square. (Only Applicable with Desktop)
  .flexible-img {
    @include media-breakpoint-only(md) {
      height: 200px;
    }
    @include media-breakpoint-only(lg) {
      height: 208px;
    }
    @include media-breakpoint-only(xl) {
      height: 235px;
    }
  }

  .card-header {
    // Card Header Bootstrap Overrides that are specific to Grid Items
    background-color: transparent;
    border: none;
    padding: 0;
  }

  // FIXME: These styles are used in dotCMS. Remove 'card-block' when dotCMS is completely using Bootstrap 4 production.
  .card-body,
  .card-block {
    padding: 0 rem(10);
  }

  .card-footer {
    height: $footer-space;
    width: 100%;
    border: none;
    padding: rem(6);
    background-color: $light-gray;
    @include border-bottom-radius(8px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
/*
   Tile Grid - Common (SM~down)

   NOTE: Grid View is not available on Break Points SM and below.
         In rare cases, this is were to put common Grid styles for SM Down and Below
   ========================================================================== */
@mixin tile-container-grid-sm-down-common() {
  .flexible-img {
    @include media-breakpoint-down(sm) {
      height: 200px;
      padding: rem(10);
    }
  }
}

/*
   Tile List - Common
   ========================================================================== */
@mixin tile-container-list-common() {
  background-color: $main-white;
  margin-bottom: rem($grid-gutter-width);

  .tile-block {
    p {
      margin: 0;
    }

    .text-details {
      padding: 0;
      margin: 0;
    }
  }
}

@mixin tile-filter-container-list-common() {
  .col-list-item-detail {
    padding-right: rem(math.div($grid-gutter-width, 2));
  }

  .tile-list-actions {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: rem(8);
    border-bottom-right-radius: rem(8);
    background-color: $light-gray;
  }
}

// For List Items that display the product image on the left with details on the left and purchase actions at the bottom
//
// NOTE: Compact List Product Item only show in 'XS' and 'MD' breakpoints. Pass boolean true for $includeSM to include override for 'SM' breakpoint.
@mixin compact-list-product-display-override($includeSM: false) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @if $breakpoint == 'xs' or $breakpoint == 'md' or ($includeSM and $breakpoint == 'sm') {
      @include media-breakpoint-only($breakpoint) {
        @include compact-list-product-display-styles();

        .col-list-item-image,
        .col-list-item-detail {
          height: 210px;
        }
        .col-list-item-action {
          height: 35px;
        }

        .purchase-price {
          margin-bottom: 40px;
        }

        // NOTE: '@include button-size()' is a bootstrap mixin. '$input-btn-padding-y-sm', '$input-btn-padding-x-sm', and '$font-size-sm' are bootstrap variables.
        .btn-purchase-action {
          @include button-size(
            $input-btn-padding-y-sm,
            $input-btn-padding-x-sm,
            $font-size-sm,
            1.5,
            $avry-btn-border-radius
          );
        }
      }
    }
  }
}
@mixin compact-list-product-display-styles() {
  .col-list-item-image,
  .col-list-item-detail {
    height: 180px;
  }
  .col-list-item-action {
    height: 35px;
  }

  .tile-list-actions {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-left-radius: rem(8);
    padding: 0 20px;
    background-color: $light-gray;

    .purchase-price {
      margin: 0 25px 0 0;
    }
  }
}

/* ==========================================================================
   TILE ITEMS GROUP (Container of Grid/List Tile Items)

   NOTE: All tiles should be nested in this class '.tile-item-group'.
   ========================================================================== */

@mixin tile-item-group() {
  padding: rem($grid-gutter-width) 0 0;

  @include media-breakpoint-up(md) {
    background-color: $brownish-gray;
  }

  .row.row-tile-items {
    margin: 0;

    @include media-breakpoint-up(md) {
      margin: 0 rem(math.div($grid-gutter-width, 2));
    }
  }
}

/* ==========================================================================
   TILE THUMBNAIL AND LABEL

   NOTE: base for a simple thumbnail and label tile.
   ========================================================================== */
@mixin tile-thumb-label() {
  @include tile-container-style();
  background-color: $main-white;
  margin-bottom: $grid-gutter-width;

  // Thumbail
  .tile-img,
  .tile-img img {
    border-top-right-radius: $tile-border-radius;
    border-top-left-radius: $tile-border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tile-img object {
    width: auto;
    max-width: 100%;
  }

  // Thumbail Label
  .tile-item-label {
    height: 45px;
    margin: rem(14) 0 0;
    padding: 0;
    font-size: rem(13);
    line-height: 1.5;
    text-align: center;
    // TODO: Use actual Bold font
    font-weight: bold;

    @include media-breakpoint-only(md) {
      height: 60px;
    }
    @include media-breakpoint-up(lg) {
      height: 45px;
    }
  }

  .text-truncate-multiline {
    max-height: 45px;
    margin: 0;

    @include media-breakpoint-up(md) {
      max-height: 58px;
      text-align: center;
      padding: 0 10px;
    }
  }

  .tile-item-label.tile-item-label-lg {
    margin: rem(10) 0 0;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      height: 68px;
    }
  }
}

/* ==========================================================================
   TILE CROPPED SHORT
   ========================================================================== */

/**
  * @param $bp {string} - breakpoint value (xs, sm, md, lg or xl);
  * @param $tile-column-qty {number} - Qty of tiles in the row;
  */
@mixin tile-cropped-img-container($bp: '', $tile-column-qty: 3) {
  $img-container-height: auto;

  // GIVEN SIZES:
  // XS-S = Square
  // M = 236 x 168 px
  // L = 300 x 214 px
  // XL = 350 x 250 px

  // NOTE: Tile heights for 2 column tiles.
  @if $tile-column-qty == 2 {
    @if $bp == 'xs' {
      $img-container-height: auto;
    }
    @if $bp == 'sm' {
      $img-container-height: auto;
    }
    @if $bp == 'md' {
      $img-container-height: 265px;
    }
    @if $bp == 'lg' {
      $img-container-height: 305px;
    }
    @if $bp == 'xl' {
      $img-container-height: 325px;
    }
  }

  // NOTE: Tile heights for 3 column tiles.
  @if $tile-column-qty == 3 {
    @if $bp == 'xs' {
      $img-container-height: auto;
    }
    @if $bp == 'sm' {
      $img-container-height: auto;
    }
    @if $bp == 'md' {
      $img-container-height: 168px;
    }
    @if $bp == 'lg' {
      $img-container-height: 214px;
    }
    @if $bp == 'xl' {
      $img-container-height: 250px;
    }
  }

  // NOTE: Sizes based on 4 column while substracting a certain amount:
  @if $tile-column-qty == 4 {
    $img-container-height-offset: 80px;

    @if $bp == 'xs' {
      $img-container-height: auto;
    }
    @if $bp == 'sm' {
      $img-container-height: auto;
    }
    @if $bp == 'md' {
      $img-container-height: 168px - $img-container-height-offset;
    }
    @if $bp == 'lg' {
      $img-container-height: 214px - $img-container-height-offset;
    }
    @if $bp == 'xl' {
      $img-container-height: 240px - $img-container-height-offset;
    }
  }

  .tile-img {
    @if $img-container-height == auto {
      min-height: 0;
      line-height: 0;
    } @else {
      min-height: $img-container-height;
      line-height: $img-container-height;
    }
  }
}

/* ==========================================================================
   TILE COLLAPSABLE

   NOTE: Collapses tile to a row on the SM breakpoint and below.
   NOTE: Similar to description-block-collapsable, except this is exclusive to tiles.
   ========================================================================== */

/**
 * @param $thumbnail-size {string} - pixel value of desired height;
 * @param $isReverse {boolean} - flag if tile should be in reverse layout, which is inverse to the default layout.
 */
@mixin tile-collapsable($thumbnail-size: 80px, $isReverse: false) {
  @if not $isReverse {
    // NOTE: This is Default
    @include tile-collapsable-default($thumbnail-size);
  } @else {
    @include tile-collapsable-reverse($thumbnail-size);
  }
}
// This is a support mixin to `@mixin tile-collapsable`.
@mixin tile-collapsable-default($thumbnail-size) {
  // This size ($thumbnail-size) will be used on height and width for proportionality.
  @include media-breakpoint-down(sm) {
    display: flex;
    height: $thumbnail-size;
    flex-direction: row;
    align-items: center;
  }
  .tile-block {
    width: 100%;
    padding: rem(10) rem(10) rem(10) 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: rem(10) rem(10) rem(20);
    }
  }
  .tile-img {
    @include media-breakpoint-down(sm) {
      margin-right: rem(16);
    }
  }
  .tile-img,
  .tile-img img {
    @include media-breakpoint-down(sm) {
      flex-shrink: 0;
      border-top-right-radius: 0;
      border-top-left-radius: $tile-border-radius;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: $tile-border-radius;

      img,
      object {
        width: 100%;
        max-width: $thumbnail-size;
      }
    }
  }
}
// This is a support mixin to `@mixin tile-collapsable`.
@mixin tile-collapsable-reverse($thumbnail-size) {
  $md-thumbnail-size: (strip-units($thumbnail-size) - 80) * 1px;

  @include media-breakpoint-up(md) {
    display: flex;
    height: $md-thumbnail-size;
    flex-direction: row;
    align-items: flex-start;
  }
  @include media-breakpoint-up(lg) {
    height: $thumbnail-size;
  }
  .tile-block {
    width: 100%;
    padding: rem(10) rem(10) rem(20);
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: rem(10) rem(10) rem(10) 0;
    }
  }
  .tile-img {
    flex: 0 0 auto;

    @include media-breakpoint-up(md) {
      margin-right: rem(16);
    }
  }
  .tile-img,
  .tile-img img {
    @include media-breakpoint-up(md) {
      border-top-right-radius: 0;
      border-top-left-radius: $tile-border-radius;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: $tile-border-radius;

      img,
      object {
        width: 100%;

        @include media-breakpoint-only(md) {
          max-width: $md-thumbnail-size;
        }
        @include media-breakpoint-up(lg) {
          max-width: $thumbnail-size;
        }
      }
    }
  }
}

/* ==========================================================================
   TILE THUMB IMAGE SPACED

   NOTE: A variation of the thumb & label except this mixin has padding around the image on XS and SM.
         These styles have no differences in MD, LG, and XL.
   ========================================================================== */

/**
 * @param $tile-height {string} - pixel value of desired overall height of tile;
 * @param $text-details-height-mobile {string} - XS and SM pixel value of desired height of the tile's text detail area.
 * @param $text-details-height-desktop {string} - MD, LG and XL pixel value of desired height of the tile's text detail area.
 */
@mixin tile-thumb-img-spaced(
  $tile-height: 150px,
  $text-details-height-mobile: 60px,
  $text-details-height-desktop: 80px
) {
  @include media-breakpoint-down(sm) {
    height: $tile-height;
    margin-top: 0;

    @include padded-tile-image($tile-height);

    &.h-auto {
      min-height: $tile-height;
    }

    .tile-img {
      border-radius: 0;
    }
  }

  .tile-block {
    width: 100%;
    padding: rem(10) rem(10) rem(10) 0;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      padding: rem(10) rem(10) rem(20);
    }
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
  .tile-text {
    &.text-details {
      min-height: $text-details-height-mobile;

      @include media-breakpoint-up(md) {
        min-height: $text-details-height-desktop;
      }
    }
    & .text-truncate-multiline {
      max-height: $text-details-height-mobile;

      @include media-breakpoint-up(md) {
        max-height: $text-details-height-desktop;
      }
    }
  }
}

/**
 * @param $tile-height {string} - pixel value of desired overall height of tile;
 */
@mixin padded-tile-image($tile-height) {
  .tile-img {
    img {
      max-width: $tile-height;
      border-radius: 0;
      padding: rem(10);
    }
  }
}
