/* ==========================================================================
   AVERY MODAL COMMON

   NOTE: Avery Modal Styles Extends Bootstrap's Modal Styles.
   ========================================================================== */

body.modal-open {
  // NOTE: this prevents the body from scrolling on mobile devices.
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

.modal-backdrop {
  background-color: #091834;
  body[data-avry-mode='industrial'] & {
    background-color: #1f1f1f;
  }

  &.fade.show {
    opacity: 0.9;
  }
}

.modal-open {
  .modal-backdrop {
    @include media-breakpoint-down(sm) {
      // NOTE: Fixes overlay gap during scroll down on Mobile touch devices.
      min-height: 1024px;
    }
  }
}

// Modal - Common
//
// Global Avery Modal that utilizes Bootstrap as the base.
//
// **HOW TO USE:** Reference the Markup below. Update the `data-target`, `aria-labelledby`, and `id` attributes according to your modal. Having a `<button>` (Launch demo modal) is optional, it was only used to launch the modal.
//
// You can change the modals size with one of the following classes. Place them next to the `modal-avry` class
//
// | Classes               | Description                |
// | --------------------- | -------------------------- |
// | `.modal-avry--sm`     | 300px                      |
// |                       | 500px (default)            |
// | `.modal-avry--lg`     | 700px                      |
// | `.modal-avry--xl`     | 900px                      |
//
// Markup:
// <!-- Triggers (button, href, span, or div) -->
// <button type="button" class="btn btn-avry" data-toggle="modal" data-target="#smallModal">
//   Small Modal
// </button>
// <a href="#" onclick="event.preventDefault()" class="btn btn-avry" data-toggle="modal" data-target="#mediumModal">
//   Medium Modal (default)
// </a>
// <span class="btn btn-avry" data-toggle="modal" data-target="#largeModal">
//   Large Modal
// </span>
// <div class="btn btn-avry" data-toggle="modal" data-target="#extraLargeModal">
//   Extra Large Modal
// </div>
// <!-- Small Modal -->
// <div class="modal modal-avry modal-avry--sm" id="smallModal" role="dialog" aria-labelledby="smallModalTitle" aria-hidden="true">
//   <div class="modal-dialog" role="document">
//     <div class="modal-content">
//       <!-- Modal Header -->
//       <div class="modal-header">
//         <h5 class="modal-title user-no-select" id="smallModalTitle">
//           Small Modal Example
//         </h5>
//         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
//       </div>
//       <!-- Modal Body -->
//       <div class="modal-body">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//         Quidem dolores architecto, nam qui, magnam ipsam omnis
//         aut magni eos! Doloremque eum non esse sed totam temporibus
//         ipsa accusantium nesciunt perferendis.
//       </div>
//     </div>
//   </div>
// </div>
// <!-- Medium Modal -->
// <div class="modal modal-avry" id="mediumModal" role="dialog" aria-labelledby="mediumModalTitle" aria-hidden="true">
//   <div class="modal-dialog" role="document">
//     <div class="modal-content">
//       <!-- Modal Header -->
//       <div class="modal-header">
//         <h5 class="modal-title user-no-select" id="mediumModalTitle">
//           Medium Modal Example
//         </h5>
//         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
//       </div>
//       <!-- Modal Body -->
//       <div class="modal-body">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//         Quidem dolores architecto, nam qui, magnam ipsam omnis
//         aut magni eos! Doloremque eum non esse sed totam temporibus
//         ipsa accusantium nesciunt perferendis.
//       </div>
//     </div>
//   </div>
// </div>
// <!-- Large Modal -->
// <div class="modal modal-avry modal-avry--lg" id="largeModal" role="dialog" aria-labelledby="largeModalTitle" aria-hidden="true">
//   <div class="modal-dialog" role="document">
//     <div class="modal-content">
//       <!-- Modal Header -->
//       <div class="modal-header">
//         <h5 class="modal-title user-no-select" id="largeModalTitle">
//           Large Modal Example
//         </h5>
//         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
//       </div>
//       <!-- Modal Body -->
//       <div class="modal-body">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//         Quidem dolores architecto, nam qui, magnam ipsam omnis
//         aut magni eos! Doloremque eum non esse sed totam temporibus
//         ipsa accusantium nesciunt perferendis.
//       </div>
//     </div>
//   </div>
// </div>
// <!-- Extra Large Modal -->
// <div class="modal modal-avry modal-avry--xl" id="extraLargeModal" role="dialog" aria-labelledby="extraLargeModalTitle" aria-hidden="true">
//   <div class="modal-dialog" role="document">
//     <div class="modal-content">
//       <!-- Modal Header -->
//       <div class="modal-header">
//         <h5 class="modal-title user-no-select" id="extraLargeModalTitle">
//           Extra Large Modal Example
//         </h5>
//         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
//       </div>
//       <!-- Modal Body -->
//       <div class="modal-body">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//         Quidem dolores architecto, nam qui, magnam ipsam omnis
//         aut magni eos! Doloremque eum non esse sed totam temporibus
//         ipsa accusantium nesciunt perferendis.
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide modal.common

.fade {

}

.modal.modal-avry {
  .modal-details {
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 0 rem($grid-gutter-width);
    }

    label {
      // TODO: Use actual Bold font
      font-weight: bold;
    }
  }

  & .modal-dialog {
    transition: none !important; // NOTE: disabled modal transition.

    @include media-breakpoint-only(sm) {
      margin-left: auto;
      margin-right: auto;
    }

    // TODO: When working with show and hide animations with the modal, preferrable don't use location based animations.
    //       Suggest to use fade in or fade out.
    @include media-breakpoint-up(md) {
      max-height: 100vh;
      min-width: 500px; // Set a min width because when the .modal-dialog is set to position: absolute, it looses its default width.
      margin: 0 auto;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .modal-content {
    @include border-radius(3px);
  }

  &.modal--top-border {
    .modal-content {
      border-top: solid rem(4px) $bright-bold-blue;

      body[data-avry-mode='industrial'] & {
        border-top-color: $industrial-blue;
      }
    }
  }

  &.modal--transparent {
    .modal-content {
      background: transparent;
      border-radius: 0;
      border-width: 0;
      overflow: hidden;
    }
  }

  .form-group.form-group-modal-avry {
    max-height: 60px;
    margin: rem(16) 0 rem(10);
    position: relative;

    .edit-icon {
      padding: 0 rem(6) 0 0;
      display: block;
      position: absolute;
      top: rem(4);
      right: rem(8);
      z-index: 15;

      .fa {
        font-size: rem(18);
      }
    }

    textarea.modal-text-name-change {
      max-height: 60px;
      width: 100%;
      font-size: rem(20);
      line-height: 1.5;
      padding: rem(5) rem(32) rem(5) rem(5);
      background: $light-gray;
      border: rem(1) solid $light-gray;
      border: 0;
      overflow: auto;
      resize: none;
      position: relative;
      z-index: 10;

      &:focus {
        border: rem(1) solid $base-border-color;
        padding: rem(4);
        background: $main-white;
        z-index: 20;
      }
    }

    .fa {
      font-size: rem(18);
    }
  }

  .modal-text {
    &.text-details {
      p {
        margin: 0;
      }
    }
  }

  .revised-details {
    padding-top: rem(6);
    font-size: rem(12);
  }

  /*
     Modal Header
     ========================================================================== */
  .modal-header {
    border: none;
    position: relative;
    z-index: 51;
    align-items: flex-start;
    border-bottom: 1px solid $base-border-color;
  }

  /*
     Modal Body
     ========================================================================== */
  .modal-body {
    padding-top: 0;
  }

  /*
     Modal Footer
     ========================================================================== */
  .modal-footer {
    border: none;
  }

  ul.modal-links {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 rem(20);
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.modal-dialog {
  .modal-avry--sm & {
    min-width: auto !important;
    @include media-breakpoint-up(sm) {
      max-width: 310px !important;
      width: 310px !important;
    }
  }
  .modal-avry--lg & {
    @include media-breakpoint-up(sm) {
      max-width: 500px !important;
      width: 500px !important;
    }
    @include media-breakpoint-up(md) {
      max-width: 700px !important;
      width: 700px !important;
    }
  }
  .modal-avry--xl & {
    @include media-breakpoint-up(sm) {
      max-width: 500px !important;
      width: 500px !important;
    }
    @include media-breakpoint-up(md) {
      max-width: 700px !important;
      width: 700px !important;
    }
    @include media-breakpoint-up(lg) {
      max-width: 900px !important;
      width: 900px !important;
    }
  }
}

.modal-header__close {
  background-color: transparent;
  border: 0;
  padding: rem(5) rem(10);
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: rem(16);

  &:focus {
    outline: 2px solid -webkit-focus-ring-color;
  }
}

.modal-avry--upload {
  .modal-dialog {
    max-width: 1000px !important;

    @include media-breakpoint-up(md) {
      width: 1000px !important;
    }
  }
  .modal-header {
    padding: 0;
    border-width: 0 !important;
  }
  .modal-header__close {
    top: 10px;
    right: 12px;
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    background-color: transparent !important;
    border-radius: 0;
    border-width: 0 !important;
    overflow: hidden;
  }
}
