/* ==========================================================================
   COMMON GHS MODALS
   ========================================================================== */

.modal.modal-avry.modal-ghs {
  .modal-dialog {
    max-width: none;


    @include media-breakpoint-up(md) {
      min-width: 720px;
    }

    @include media-breakpoint-up(md) {
      max-width: 1000px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 900px;
    }
  }

  // Image Wrap Utilities Override
  .img-wrap {
    @include media-breakpoint-only(md) {
      float: none !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

/* ==========================================================================
   PIGMENT VS DYE BASED INKS
   ========================================================================== */

.modal.modal-avry.modal-ghs-pigment-dye {
  .spec-sheet {
    @include media-breakpoint-down(sm) {
      overflow-x: scroll;
    }
  }

  .table-avry table {
    width: auto;

    td {
      text-align: center;
    }
  }
}

/* ==========================================================================
   SAMPLE REQUEST
   ========================================================================== */

.modal.modal-avry.modal-ghs-sample-request {
  // Video
  .sample-request-video {
    width: 100%;
    height: 250px;
  }

  // Forms
  // TODO: .form-control-label is deprecated and is replaced by `.col-form-label`.
  // Will leave it here until `.form-control-label` is removed in dotCMS (most likely
  // where it is still used).
  .col-form-label, .form-control-label {
    @include media-breakpoint-up(md) {
      font-size: rem(13);
      line-height: 1.5;
    }
  }
}

