@use "sass:math";

/* ==========================================================================
   TILE TEMPLATE FILTER ITEM (LIST & GRID COMMON)

   NOTE: These may also be referred to as 'Cards'.
   ========================================================================== */

.tile-template-filter-item .tile-container {
  @include tile-container-common();

  height: 445px;

  // Main Text Area that appears in Card Items
  .tile-text {
    &.text-main-long {
      $text-main-long-height: 40px;

      // Used 'max-height' vs simply 'height' so that 'text-main-long' can have flexible height.
      height: $text-main-long-height;
      // TODO: Use actual font
      font-weight: normal;
      margin: 0 0 rem(3);

      .text-truncate-multiline {
        max-height: $text-main-long-height;
        margin-right: 0;
      }
    }

    &.text-main-long-bold {
      font-size: rem(18);
      // TODO: Use actual Bold font
      font-weight: bold;
      margin-bottom: rem(4);

      .text-truncate {
        margin: 0;
      }
    }

    &.text-details {
      .detail-content {
        p {
          margin-bottom: rem(0);
          line-height: 1.6;
        }
      }
    }

    &.text-details.text-item-number {
      font-size: .95rem; // directly made it rem per designer's request.
      line-height: 1.2;

      .text-truncate {
        margin: 0;
      }
    }

    &.text-details.text-template-size p {
      font-size: rem(15);
      font-weight: bold;
    }
  }
}

/* ==========================================================================
   TILE TEMPLATE FILTER ITEM (Grid) ONLY - Utilizes Bootstrap's Card Component
   ========================================================================== */

//-// Template Filter Tile - Grid
//-//
//-// A Grid Tile used in the Template Filter page for 'Grid' view.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// **NOTE:** Grid items are **NOT** display for breakpoints **SM** and below.
//-//
//-// Markup:
//-// <div class="tile-template-filter-item tile-item tile-grid-item" style="max-width: 208px; margin: 0 auto;">
//-//   <a class="router-link" href="https://www.avery.com/templates/5160">
//-//     <div class="card tile-container">
//-//       <div class="card-header">
//-//         <div class="flexible-img flexible-img-vertical">
//-//           <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_225/web/templates/line-art/5160">
//-//         </div>
//-//       </div>
//-//       <div class="card-body tile-block">
//-//         <div class="card-text tile-text text-main-long">
//-//           <p class="text-truncate-multiline">Easy Peel Address Labels</p>
//-//         </div>
//-//         <div class="card-text tile-text text-details text-item-number">
//-//           <p class="text-truncate">Template <span class="bold-text">5160</span></p>
//-//         </div>
//-//         <div class="card-text tile-text text-details text-template-size">
//-//           <div class="detail-content">
//-//             <p class="text-truncate">1" x 2-5/8"</p>
//-//           </div>
//-//         </div>
//-//         <div class="card-text tile-text text-details text-template-details">
//-//           <div class="detail-content">
//-//             <p class="text-truncate">White</p>
//-//             <p class="text-truncate">
//-//               30 per Sheet
//-//             </p>
//-//           </div>
//-//         </div>
//-//       </div>
//-//       <div class="card-footer">
//-//         <div class="available-in">
//-//           <div class="row">
//-//             <div class="col-12">
//-//               <span class="available-in-text">
//-//                 <span>Available in:</span>
//-//                 <img src="https://www.avery.com/assets/svg/weprint_logo_blue.svg" style="width: 70px;">
//-//               </span>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </a>
//-// </div>
//-//
//-// styleguide tiles.template-filter-grid

// FIXME: Deprecated - Remove `.tile-template-filter-item.tile-grid-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-template-filter-item.tile-grid-item .tile-container {
  @include tile-container-grid-common();

  .card-body.tile-block {
    @include media-breakpoint-up(md) {
      height: 180px;
    }
  }

  .card-footer {
    background-color: transparent;
  }
}

/* ==========================================================================
   TILE TEMPLATE FILTER ITEM (List) ONLY - Utilizes Bootstrap's Row
   ========================================================================== */

//-// Template Filter Tile - List
//-//
//-// A List Tile used in the Template Filter page for 'List' view.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div class="container mx-auto">
//-//   <div class="row">
//-//     <div class="col-12">
//-//       <div class="tile-template-filter-item tile-item tile-list-item">
//-//         <a class="router-link" href="https://www.avery.com/templates/5160">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_225/web/templates/line-art/5160">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-lg-10 full-height gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block full-height">
//-//                   <div class="row">
//-//                     <div class="col-12">
//-//                     </div>
//-//                   </div>
//-//                   <div class="row row-list-details full-height align-items-lg-center">
//-//                     <div class="col-12 col-lg-5">
//-//                       <div class="tile-text text-main-long">
//-//                         <p class="text-truncate-multiline">Easy Peel Address Labels</p>
//-//                       </div>
//-//                       <div class="tile-text text-details text-item-number">
//-//                         <p class="text-truncate">Template <span class="bold-text">5160</span></p>
//-//                       </div>
//-//                       <div class="tile-text text-details text-template-size">
//-//                         <div class="detail-content">
//-//                           <p class="text-truncate">1" x 2-5/8"</p>
//-//                         </div>
//-//                       </div>
//-//                     </div>
//-//                     <div class="col-12 col-lg-3">
//-//                       <div class="tile-text text-details text-template-details">
//-//                         <div class="detail-content">
//-//                           <p class="text-truncate">White</p>
//-//                           <p class="text-truncate">
//-//                             30 per Sheet
//-//                           </p>
//-//                         </div>
//-//                       </div>
//-//                     </div>
//-//                     <div class="col-12 col-lg-4 mt-auto mt-lg-0">
//-//                       <div class="tile-list-actions available-in-content full-height">
//-//                         <span class="available-in-label">Available in:&nbsp;</span>
//-//                         <img class="we-print-svg-logo" src="https://www.avery.com/assets/svg/weprint_logo_blue.svg">
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.template-filter-list

// FIXME: Deprecated - Remove `.tile-template-filter-item.tile-list-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-template-filter-item.tile-list-item .tile-container {
  @include tile-container-list-common();
  @include tile-filter-container-list-common();

  height: 170px;

  .row.row-list-details {
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-content: flex-start;

      .col-12 {
        flex: 0 0 auto;
      }
    }
  }

  .tile-block  {
    padding-left: rem(math.div($grid-gutter-width, 2));
  }

  .tile-text {
    &.text-main-long {
      font-size: rem(16);
    }

    &.text-template-details {
      @include media-breakpoint-up(lg) {
        margin-top: rem(48);
      }
    }

    &.text-details {
      .detail-content {
        @include media-breakpoint-down(md) {
          p {
            line-height: 1.2;
          }
        }
      }
    }
  }

  .available-in-content {
    .available-in-label {
      display: inline-block;
      margin: 0 0 rem(10);
      font-size: rem(13);
      line-height: 1.5;

      @include media-breakpoint-down(xs) {
        margin: 0 rem(10) 0 0;
      }
      @include media-breakpoint-only(md) {
        margin: 0 rem(10) 0 0;
      }
    }

    .we-print-svg-logo {
      @include media-breakpoint-down(md) {
        position: relative;
        top: -1px;
      }
    }
  }

  .tile-list-actions {
    background: transparent;

    @include media-breakpoint-up(lg) {
      margin-top: rem(35);
    }

    @include media-breakpoint-down(md) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 0;

      .available-in-label {
        margin: 0;
        line-height: 1.1;
      }
    }

    @include media-breakpoint-down(xs) {
      .available-in-label {
        flex: 0 0 auto;
        font-size: rem(13);
      }
    }
    @include media-breakpoint-only(md) {
      align-items: flex-start;
    }
  }

  .we-print-svg-logo {
    height: 100%;
    width: 100%;
    max-height: 30px;
    max-width: 90px;

    @include media-breakpoint-down(md) {
      max-height: none;
      max-width: 65px;
    }
  }
}

/* ==========================================================================
   TILE TEMPLATE FILTER AD ITEM - Utilizes Bootstrap
   ========================================================================== */

.tile-template-filter-item.tile-template-ad-item {
  width: 100%;
}

/* ==========================================================================
   SEARCH RESULTS TEMPLATE FILTER TILE OVERRIDES (COMMON)

   NOTE: modifications to the product filter tile to meet search result
   page specific styles.
   ========================================================================== */

.tile-search-result-item.tile-template-filter-item .tile-container {
  .tile-text.text-details.text-template-size p {
    font-size: rem(13);
    font-weight: normal;
  }
}

/* ==========================================================================
   SEARCH RESULTS TEMPLATE FILTER TILE OVERRIDES (LIST) ONLY

   NOTE: modifications to the product filter tile to meet search result
   page specific styles.
   ========================================================================== */

.tile-search-result-item.tile-template-filter-item.tile-list-item .tile-container {
  .row-list-details {
    height: 165px;

    @include media-breakpoint-up(lg) {
      height: 130px;
    }
  }

  .tile-text {
    &.text-template-details {
      margin-top: rem(48);
    }
  }

  .tile-list-actions {
    background: none;
  }
}
