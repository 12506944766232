// TODO: Document these tiles when final styles have been delivered..

/* ==========================================================================
   TILE BLANK LABELS FILTER ITEM (LIST & GRID COMMON)
   ========================================================================== */

.tile-blank-labels-filter-item .tile-container {
  @include tile-container-common();

  .tile-text {
    &, .detail-content p {
      margin-bottom: rem(8);
    }

    &.text-shape {
      margin-bottom: rem(8);
    }

    &.text-main-long {
      margin: 0;

      .text-truncate-multiline {
        margin-bottom: rem(8);
      }
    }
  }

  .btn.btn-avry {
    width: 100%;
    max-width: 180px;
  }
}

/* ==========================================================================
   TILE BLANK LABELS FILTER ITEM (Grid) ONLY - Utilizes Bootstrap's Card Component
   ========================================================================== */

//-// Blank Labels Filter Tile - Grid
//-//
//-// A Grid Tile used in the Blank Labels Filter page for 'Grid' view.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// **NOTE:** Grid items are **NOT** display for breakpoints **SM** and below. But there are exceptions such as this tile being displayed in a swiper carousel.
//-//
//-// Markup:
//-// <div class="tile-blank-labels-filter-item tile-item tile-grid-item tile-on-action-btn" style="max-width: 208px; margin: 0 auto;">
//-//   <a class="router-link" href="/blank/labels/94254">
//-//     <div class="card tile-container">
//-//       <div class="card-header">
//-//         <div class="flexible-img flexible-img-vertical">
//-//           <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_700/web/blank-labels/products/94254" alt='Rectangle 4-3/4" x 3-3/4"'>
//-//         </div>
//-//       </div>
//-//       <div class="card-body tile-block">
//-//         <div class="tile-text text-shape"> Rectangle </div>
//-//         <div class="card-text tile-text text-main-long">
//-//           <p class="text-truncate-multiline"> 4-3/4" x 3-3/4"</p>
//-//         </div>
//-//         <div class="card-text tile-text text-details">
//-//           <div class="detail-content">
//-//             <p>24 Labels Per Sheet</p>
//-//             <p>Print-to-the-Edge</p>
//-//           </div>
//-//         </div>
//-//       </div>
//-//       <div class="card-footer">
//-//         <div class="purchase-action">
//-//           <div class="row">
//-//             <div class="col text-center"><button class="btn btn-avry"> Select
//-//               </button></div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </a>
//-// </div>
//-//
//-// styleguide tiles.blank-labels-filter-grid

// FIXME: Deprecated - Remove `.tile-blank-labels-filter-item.tile-grid-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-blank-labels-filter-item.tile-grid-item .tile-container {
  @include tile-container-grid-common();

  padding-bottom: 0;

  .flexible-img {
    @include media-breakpoint-down(sm) {
      padding: rem(20);
    }
  }

  .card-body.tile-block {
    height: 130px;
    text-align: center;
  }

  .text-truncate-multiline {
    text-align: center;
  }

  .card-footer {
    height: auto;
    background: none;
    padding-top: rem(20);
    padding-bottom: rem(20);
    position: relative;
  }
}

/* ==========================================================================
   TILE BLANK LABELS FILTER ITEM (List) ONLY - Utilizes Bootstrap's Row
   ========================================================================== */

//-// Blank Labels Filter Tile - List
//-//
//-// A List Tile used in the Blank Labels Filter page for 'List' view.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div class="container container-fluid-sm-down mx-auto">
//-//   <div class="row">
//-//     <div class="col-12">
//-//       <div class="tile-blank-labels-filter-item tile-item tile-list-item tile-on-action-btn">
//-//         <a class="router-link" href="/blank/labels/94254">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-lg-4 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_700/web/blank-labels/products/94254" alt="Rectangle 4-3/4&quot; x 3-3/4&quot;">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-lg-8 full-height gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block">
//-//                   <div class="tile-text text-shape"> Rectangle </div>
//-//                   <div class="tile-text text-main-long">
//-//                     <p class="text-truncate-multiline">4-3/4" x 3-3/4"</p>
//-//                   </div>
//-//                   <div class="tile-text text-details">
//-//                     <div class="detail-content">
//-//                       <p>24 Labels Per Sheet</p>
//-//                       <p>Print-to-the-Edge</p>
//-//                       <button class="btn btn-avry">Select</button>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.blank-labels-filter-list

// FIXME: Deprecated - Remove `.tile-blank-labels-filter-item.tile-grid-item .tile-container` and all its nested styles once verified not/no longer used.
// NOTE: List view has been removed in Blank Label 2.0+ Filter Page(s)
.tile-blank-labels-filter-item.tile-list-item .tile-container {
  @include tile-container-list-common();
  @include tile-filter-container-list-common();

  height: 215px;

  .flexible-img.flexible-img-horizontal {
    padding-left: rem(20);
    padding-right: rem(20);
  }

  .tile-block p {
    margin-bottom: rem(8);
  }

  .btn.btn-avry {
    margin-top: rem(16);
  }
}
