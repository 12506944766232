/* ==========================================================================
   Text Colors
   ========================================================================== */

// Text Colors
//
// Classes that will change the color of text.
//
// .text-base-color - Default/Base color of Text
// .text-main-white - White Colored Tex
// .text-base-gray - Base Gray Colored Text
// .text-bold-gray - Bold Gray Colored Text
// .text-sub-gray - Sub Gray Colored Text
// .text-charcoal - Charcoal Colored Text
// .text-bright-red - Bright Red Colored Text
// .text-base-green - Base Green Colored Text
// .text-color-ind-gray - Text Color - Industrial Gray
// .text-color-ind-yellow - Text Color - Industrial Yellow
// .text-default-color-*-up - Default Text Color from a breakpoint upwards
// .text-default-color-*-down - Default Text Color from a breakpoint downwards
//
// Markup:
// <h3 class="m-0 {{modifier_class}}">The quick brown fox jumps over the lazy dog</h3>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide typography.b-colors

// Text Base
.text-base-color {
  color: $base-dark !important;
}
// Text White
.text-main-white {
  color: $main-white !important;
}
// Text Base Gray
.text-base-gray {
  color: $base-gray !important;
}
// Text Bold Gray
.text-bold-gray {
  color: $bold-gray !important;
}
// Text Sub Gray
.text-sub-gray {
  color: $sub-gray !important;
}
// Text Charcoal
.text-charcoal {
  color: $charcoal-black !important;
}
// Text Hyper Blue
.text-hyper-blue {
  color: $hyper-blue !important;
}
// Text Bright Red
.text-bright-red {
  color: $bright-red !important;
}
// Text Bright Blue
.text-bright-blue {
  color: $bright-bold-blue !important;
}
// Text Base Green
.text-base-green {
  color: $base-green !important;
}
// text-muted Bootstrap Override
.text-muted {
  color: $sub-gray !important;
}
// Text Color - Industrial Gray
.text-color-ind-gray {
  color: $industrial-gray !important;
}
// Text Color - Industrial Yellow
.text-color-ind-yellow {
  color: $industrial-yellow !important;
}

// Text Initial (Default)
@each $bp in map-keys($grid-breakpoints) {
  .text-default-color-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      color: initial !important;
    }
  }
  .text-default-color-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      color: initial !important;
    }
  }
}
