/* ==========================================================================
   BUTTONS
   ========================================================================== */

// Avery Button Radius - this value is what makes the buttons rounded.
$avry-btn-border-radius: rem(25);

/* Industrial Buttons: */
// Radius - this value is what makes the buttons rounded.
$avry-industrial-btn-border-radius: rem(3);
// Border Size
$avry-industrial-border-size: 2px;

/* ==========================================================================
   BORDERS
   ========================================================================== */

$base-border-color: $base-gray;

/* ==========================================================================
   DIVIDERS
   ========================================================================== */

$divider-weight: rem(1);
$divider-color: $base-gray;
$divider-top-space: rem(10);
$divider-bottom-space: rem(10);

/* ==========================================================================
   GUTTER
   ========================================================================== */

$gutter-base: $grid-gutter-width; // Value is from a Bootstrap variable
$gutter-flush-mobile: (
  xs: 0,
  sm: 0,
) !default;

/* ==========================================================================
   CARDS
   ========================================================================== */

$card-bottom-margin: $card-spacer-y; // Value is from a Bootstrap variable

/* ==========================================================================
   TILE COMMON VARIABLES
   ========================================================================== */

$tile-border-radius: rem(3);
$footer-space: 40px;

/* ==========================================================================
   RESPONSIVE VARIABLES
   ========================================================================== */

/*
     Breakpoints Values per Breakpoint
     ========================================================================== */

// Custom Breakpoints with for SM and UP
$sm-up-grid-breakpoints: map-remove($grid-breakpoints, xs);

// Custom Breakpoints for Mobile Only
$mobile-grid-breakpoints: map-remove($grid-breakpoints, md, lg, xl);

// Custom Breakpoints for Desktop Only
$desktop-grid-breakpoints: map-remove($grid-breakpoints, xs, sm);
