/* ==========================================================================
   BREADCRUMB
   ========================================================================== */

// Breadcrumb
//
// This is to be used with Bootstrap's Breadcrumb Component.
//
// Markup:
// <nav class="breadcrumb d-none d-md-block">
//   <a class="breadcrumb-item" href="#">Avery</a>
//   <a class="breadcrumb-item" href="#">Breadcrumb</a>
//   <a class="breadcrumb-item" href="#">Breadcrumb Page</a>
// </nav>
//
// Styleguide header.breadcrumb

.breadcrumb-back {
  display: block;
  margin-right: rem(20);
  float: left;

  &:before {
    font-family: avery-fonticon;
    content: "\e908";
    margin-right: rem(7);
    vertical-align: top;

    @include media-breakpoint-up(md) {
      content: "\e92c";
    }
  }
}

.breadcrumb {
  margin: 12px 0;
  height: 14px;
  padding: 0;

  .breadcrumb-item {
    font-size: 12px;
    line-height: .8;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &:last-child {
      color: #000;
      font-weight: 700;
    }

    + .breadcrumb-item::before {
      font-family: avery-fonticon;
      content: "\e90b" !important;
      font-size: 10px;
      margin-top: 1px;
    }
  }
}
