// Skeleton
//
// New simplified skeleton uses pre existing font sizes to determine size with slight adjustments for margins

//
// Markup:
// <div class="row">
//  <div class="col-12 col-md-4">
//    class="sk-block img-box ratio__4-3"
//    <div class="sk-block img-box ratio__4-3 mb-3"></div>
//    <div class="sk-block sk-text h1"></div>
//    <div class="sk-block sk-text sk-text--md"></div>
//    <div class="sk-block sk-text mb-3" style="width: 60%"></div>
//    <div class="row">
//      <div class="col mb-2">
//        <div class="sk-block sk-cta cta cta--primary"></div>
//      </div>
//      <div class="col mb-2">
//        <div class="sk-block sk-cta cta cta--primary"></div>
//      </div>
//    </div>
//  </div>
//  <div class="col-12 col-md-4">
//    class="sk-image img-box ratio__4-3"
//    <div class="sk-image img-box ratio__4-3 mb-3"></div>
//    <div class="sk-block sk-text h1"></div>
//    <div class="sk-block sk-text sk-text--md"></div>
//    <div class="sk-block sk-text mb-3" style="width: 60%"></div>
//    <div class="row">
//      <div class="col mb-2">
//        <div class="sk-block sk-cta cta cta--primary"></div>
//      </div>
//      <div class="col mb-2">
//        <div class="sk-block sk-cta cta cta--primary"></div>
//      </div>
//    </div>
//  </div>
//  <div class="col-12 col-md-4">
//    class="sk-block sk-image img-box ratio__4-3"
//    <div class="sk-block sk-image img-box ratio__4-3 mb-3"></div>
//    <div class="sk-block sk-text h1"></div>
//    <div class="sk-block sk-text sk-text--md"></div>
//    <div class="sk-block sk-text mb-3" style="width: 60%"></div>
//    <div class="row">
//      <div class="col mb-2">
//        <div class="sk-block sk-cta cta cta--primary"></div>
//      </div>
//      <div class="col mb-2">
//        <div class="sk-block sk-cta cta cta--primary"></div>
//      </div>
//    </div>
//  </div>
// </div>
//
// Styleguide skeleton

.sk-block {
  background-color: $light-gray;
  border-radius: rem(3);
  background-size: 400% 400%;


  .sk-block {
    background: $gray-medium;
  }
  .sk-cta {
    border-color: $gray-medium !important;
    color: $gray-medium !important;
  }
  .sk-text {
    color: $gray-medium !important;
  }
}

.sk-image {
  background-image: url('https://www.avery.com/app/images/logos/avery_ind_placeholder.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.sk-block,
.sk-image {
  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in;
}
@keyframes opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.sk-text,
.sk-cta {
  &::before {
    content: 'loading';
  }
}

.sk-cta {
  border-color: $light-gray;
  color: $light-gray;
}

.sk-text {
  color: $light-gray;
  display: inline-block;
  line-height: 1.18 !important;
  width: 100%;
}

.sk-text--mb {
  margin-bottom: 2px;
}
