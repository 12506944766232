/* ==========================================================================
   FILTER PAGE(S) mobile filter content

   NOTE: Pages that have the filter page structure, (such as: Product Filter page, Template Filter Page).
   ========================================================================== */


.mobile-filters {
  .filter-container.filter-container-mobile {
    .filter-set {
      margin-bottom: rem(10);
    }

    .card {
      max-width: none;
    }
  }

  .filter-apply {
    padding: rem(16) 0;

    h4 {
      float: left;
    }

    .filter-header-menu {
      float: right;

      .filter-clear-all {
        color: $base-dark !important;
        text-decoration: underline;
      }

      @include media-breakpoint-down(sm) {
        .btn {
          margin-left: rem(20);
        }
      }
    }
  }

  .mobile-menu-content {
    margin-top: rem(16);
  }
}

/* ==========================================================================
   MOBILE FILTERS MODAL STYLES
   ========================================================================== */

.modal.modal-avry.filter-mobile-menu {
  .modal-dialog {
    margin: 0;

    @include media-breakpoint-only(sm) {
      max-width: none;
    }
  }

  .modal-content{
    border: 0;
    border-radius: 0;
    background: none;

    @include media-breakpoint-only(sm) {
      width: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
