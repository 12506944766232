// Bootstrap overrides
//
// Copy variables from `node_modules/bootstrap/scss/_variables.scss` file into
// this file to override default values without modifying source files.

/* ==========================================================================
   Body Settings
   ========================================================================== */

$body-color: $base-dark !default; // Main Body Text Color

/* ==========================================================================
   Breakpoints
   ========================================================================== */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px
) !default;

/* ==========================================================================
   Grid containers
   ========================================================================== */

$container-max-widths: (
  sm: 540px,
  md: 769px,
  lg: 960px,
  xl: 1140px
) !default;

/* ==========================================================================
   Font Settings
   ========================================================================== */

$font-family-base: 'Roboto', Helvetica, Arial, sans-serif !default;
$font-size-base: 0.8125rem !default; // 13px

/* ==========================================================================
   Header Sizes
   ========================================================================== */

$h1-font-size: 2.25rem !default; // 36px
$h2-font-size: 1.875rem !default; // 30px
$h3-font-size: 1.5rem !default; // 24px
$h4-font-size: 1.125rem !default; // 18px
$h5-font-size: 0.875rem !default; // 14px
$h6-font-size: 0.75rem !default; // 12px - Not Provided

$headings-font-weight:   bold !default;

/* ==========================================================================
   Grid Gutters
   ========================================================================== */

$grid-gutter-width: 20px !default;

/* ==========================================================================
   Bread Crumbs
   ========================================================================== */

$breadcrumb-padding-y: 10px !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider: "\f054";

/* ==========================================================================
   Links
   ========================================================================== */

$link-color: $body-color;

/* ==========================================================================
   HR Rule
   ========================================================================== */

$hr-border-color: $base-gray !default;

/* ==========================================================================
   BORDER
   ========================================================================== */

$border-color: $base-gray !default;

/* ==========================================================================
   Buttons
   ========================================================================== */

// Button Padding and Line Height (Bootstrap 4 beta)
$input-btn-padding-y:       .5rem !default;
$input-btn-padding-x:       .75rem !default;
$input-btn-line-height:     1.25 !default;

$input-btn-padding-y-sm:    .25rem !default;
$input-btn-padding-x-sm:    .5rem !default;
$input-btn-line-height-sm:  1.5 !default;

$input-btn-padding-y-lg:    .5rem !default;
$input-btn-padding-x-lg:    1rem !default;
$input-btn-line-height-lg:  1.5 !default;

// Button Transition (Off)
$btn-transition: none;

// Button Outline (on :focus)
$btn-focus-box-shadow: none;

/* ==========================================================================
   FORM CONTROL
   ========================================================================== */

$input-border-color: $bold-gray;

// Form validation
$form-feedback-invalid-color: $bright-red !default;
