@import "./industrial_buttons";

/* ==========================================================================
   MAGENTO CART OVERRIDES - FOR INDUSTRIAL MODE
   ========================================================================== */

// Magento body background overrides
&[class*="checkout"],
&[class*="interstitial"] {
  background-color: $light-gray !important;
}

// Body separator element
.body-separator {
  border-top: 5px solid $industrial-medium-gray;
}

// Makes avery.com's `.button-action` class like the industrial `.solid-red` class.
.btn.btn-avry{
  &.btn-action {
    @extend .solid-red;
  }
  &.btn-action-blue {
    @extend .solid-blue;
  }
}

// Hides unnecessary `.caret` for industrial mode
.btn.btn-block.dropdown-toggle .caret {
  display: none;
}

// Interstitial and Checkout Header/Steps
.interstitial-steps,
.opc-steps {
  background-color: $industrial-blue;
  border-radius: 3px;
  color: $main-white;

  .fa-stack {
    color: $industrial-blue;
  }

  > strong,
  .opc-edit,
  .fa-question-circle {
    color: $main-white;
  }
}

// Minimal Header and Footer Overrides (Interstitial Page and Checkout Page)
&[class*="checkout-onepage-index"],
&[class*="interstitial"] {
  .omni-search-bar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    opacity: 0;

    h4 {
      margin-bottom: 0 !important;
    }

    > .row {
      align-items: center !important;
    }

    .header-nav__logo {
      height: auto;
      width: 200px;
      margin-bottom: 0 !important;
    }
  }

  // Interstitial Footer Override
  .footer-copywrite {
    background: $main-black;
    border-top: 1px solid $main-black;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &, & a {
      color: $main-white;
    }

    > .container .row {
      align-items: center !important;
    }

    .footer-copywrite__logo {
      width: 150px;
    }
  }
}
