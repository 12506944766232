/* ==========================================================================
   ARROWED BREADCRUMB
   ========================================================================== */


// Arrowed Breadcrumb
//
// This is used for step navigation and can have as many items as needed. Can use `<a>` or `<div>`, can include images, and text must be in spans for vertical alignment. Items will wrap if there isn't enough room.
//
// Markup:
// <div class="arrowcrumb">
//   <a href="#" class="arrowcrumb__control arrowcrumb__historic"><span>Start</span></a>
//   <a href="#" class="arrowcrumb__control arrowcrumb__selected"><span>Step 2</span></a>
//   <a href="#" class="arrowcrumb__control"><span>Step 3</span></a>
//   <a href="#" class="arrowcrumb__control"><span>Final <br />Step</span></a>
// </div>
//
// Styleguide header.z-arrowed-breadcrumb

.arrowcrumb {
  display: flex;
  flex-wrap: wrap;
  font-size: rem(14);
  line-height: 1;
  list-style: none;
  margin: rem(15) rem(0);
  overflow: hidden;
  padding: 0 rem(16) 0 0;
  @include media-breakpoint-up(md) {
    font-size: rem(16);
  }
}

.arrowcrumb__control {
  align-items: center;
  background: $gray-medium;
  color: $sub-gray;
  cursor: not-allowed;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  height: rem(38);
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-bottom: rem(4);
  padding: 0 0 0 rem(14);
  position: relative;
  text-decoration: none;

  &:first-child {
    padding-left: rem(6);
  }

  &:after {
    content: " ";
    border-top: rem(19) solid transparent;
    border-bottom: rem(19) solid transparent;
    border-left: rem(8) solid $gray-medium;
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    right: rem(-8);
    top: 0;
    z-index: 2;
  }

  &:before {
    content: " ";
    border-top: rem(26) solid transparent;
    border-bottom: rem(26) solid transparent;
    border-left: rem(11) solid $main-white;
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    top: rem(-7);
    right:rem(-11);
    z-index: 1;
  }

  &:hover, &:focus {
    color: $sub-gray;
    text-decoration: none;
  }

  img {
    max-height: rem(32);
  }
}
.arrowcrumb__selected {
  background-color: $bright-bold-blue;
  color: $main-white;
  cursor: default;

  body[data-avry-mode="industrial"] & {
    background-color: $industrial-blue;
  }

  &:hover, &:focus {
    color: $main-white;
    text-decoration: none;
  }

  &:after {
    border-left-color: $bright-bold-blue;

    body[data-avry-mode="industrial"] & {
      border-left-color: $bright-bold-blue;
    }
  }
}

.arrowcrumb__historic {
  background-color: $sub-gray;
  color: $main-white;
  cursor: pointer;

  &:hover, &:focus {
    background: #062462;
    color: $main-white;
    text-decoration: inherit;

    body[data-avry-mode="industrial"] & {
      background-color: $sub-gray;
    }
    &:after {
      border-left-color: #062462;

      body[data-avry-mode="industrial"] & {
        border-left-color: $sub-gray;
      }
    }
  }

  &:after {
    border-left-color: $sub-gray;
  }
}
