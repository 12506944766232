// Youtube Video Modal
//
// A simple responsive implementation for youtube videos. Click Button to see Sample youtube video.
//
// Markup:
// <!-- Generic Bootstrap Button to trigger Modal -->
// <div id="sample-youtube-video-modal" class="cta cta--primary cta--lg mx-3 my-4" data-toggle="modal" data-target="#sample-yt-video-modal">
//   Click! To open Sample Youtube Video
// </div>
// <!-- Generic Boostrap Modal -->
// <div class="modal modal-avry modal-avry--lg" id="sample-yt-video-modal" role="dialog" aria-labelledby="sampleYTVideoModal" aria-hidden="true">
//   <div class="modal-dialog" role="document">
//     <div class="modal-content">
//       <div class="modal-header">
//         <div class="h3 modal-title user-no-select" id="sample-yt-video-modal-header">Sample Youtube Video Modal</div>
//         <button aria-label="Close" class="close fnt-sze-16" data-dismiss="modal" type="button">
//           <i aria-hidden="true" class="fonticon fonticon--close"></i>
//         </button>
//       </div>
//       <div class="modal-body p-3">
//         <!-- Content that is in an ACG -->
//         <div class="yt-video-modal text-center">
//           <iframe src="https://www.youtube.com/embed/3r_TI6YoD7k?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide modal.youtube-video-modal
.yt-video-modal {
  position: relative;
  width: 100%;
}
.yt-video-modal:before {
  content: "";
  display: block;
  padding-top: 60%;
}
.yt-video-modal iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
