/* ==========================================================================
   BOTTOM HEADER INFO
   ========================================================================== */

//-// Bottom Header Info
//-//
//-// Information located at the very bottom of header area. Usually containing the breadcrumb, contact us information, etc.
//-//
//-// Markup:
//-// <div class="bottom-header-info">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="row">
//-//       <div class="col">
//-//         <nav class="breadcrumb">
//-//           <a class="breadcrumb-item" href="#">Avery</a>
//-//           <a class="breadcrumb-item" href="#">Breadcrumb</a>
//-//           <a class="breadcrumb-item" href="#">Breadcrumb Page</a>
//-//         </nav>
//-//       </div>
//-//       <div class="col col-auto">
//-//         <div class="contact-us-header-info">
//-//           <p class="call-info">Questions? Call Us (800) 462-8379</p>
//-//           <p class="hours-info">Mon-Fri 6am – 5pm, Closed Sat and Sun</p>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide header.bottom-header-info

// FIXME: Remove `..bottom-header-info` once verified not/no longer used.
.bottom-header-info {
  margin-bottom: rem(20);
}

/* ==========================================================================
   CONTACT US HEADER INFO
   ========================================================================== */

//-// Contact Us Header Info
//-//
//-// Global Contact Us Header Info, Seen throughout common pages.
//-//
//-// <span class="text-bright-red">Deprecated:</span> Do not use this class, it is being replaced by the updated CSC
//-//
//-// Markup:
//-// <div class="contact-us-header-info">
//-//   <p class="call-info">Questions? Call Us (800) 462-8379</p>
//-//   <p class="hours-info">Mon-Fri 6:30am – 5pm, Sat 6:30am – 3pm PT</p>
//-// </div>
//-//
//-// styleguide header.contact-us-header-info

// FIXME: Remove `.bottom-header-info` and all its nested styles once verified not/no longer used.
.contact-us-header-info {
  @include media-breakpoint-up(md) {
    margin-top: 10px;
    text-align: right;
  }

  .call-info,
  .hours-info {
    margin: 0;
  }

  .call-info {
    font-size: rem(15);

    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }
  }
}

// General Top Main Header
//
// A section that displays the page title (preferably the `<h1>`) with a background image. This is the global version of the [Industrial Top Main Header](https://dev-cms.avery.com/styleguide/section-industrial-header.html#kssref-industrial-header-2-top-main-header).
//
// Markup:
// <section class="avry-top-main-header">
//   <div class="container container-fluid-sm-down">
//     <div class="header-content text-center">
//       <h1>Pictogram Labels</h1>
//     </div>
//   </div>
// </section>
//
// styleguide header.top-main-header

.avry-top-main-header {
  max-width: 5000px;
  height: 160px;
  margin: 0 auto;
  background-image: url('https://img.avery.com/f_auto,c_scale,w_768/web/products/t2c-banner-generic-mobile');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  color: $main-white;

  @include media-breakpoint-up(md) {
    height: 200px;
    background-image: url('https://img.avery.com/f_auto,c_scale,w_1200/web/products/t2c-banner-generic');
  }

  & > .container {
    height: 100%;
  }

  .header-content {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  }
}

// KSP
//
// A KSP section, often grouped with the header. Uses css Grid to overlap items in SM & XS size and fade in and out each one. There must be three items for the ksp to work. On MD breakpoint the first item in the list is hidden.
//
// Markup:
// <section class="avry-ksp bg-white border-top border-bottom bdr-mediumgray mb-0">
//   <div class="container container-fluid-sm-down">
//     <div class="fade__wrapper">
//       <div class="fade__object f1">
//         <span class="fnt-sze-14 font-weight-bold lh-1-1 text-uppercase"><i class="fa fa-check pr-2" aria-hidden="true"></i>No Minimum Orders</span>
//       </div>
//       <div class="fade__object f2">
//         <span class="fnt-sze-14 font-weight-bold lh-1-1 text-uppercase"><i class="fa fa-check pr-2" aria-hidden="true"></i>Free Templaets & Designs</span>
//       </div>
//       <div class="fade__object f3">
//         <span class="fnt-sze-14 font-weight-bold lh-1-1 text-uppercase"><i class="fa fa-check pr-2" aria-hidden="true"></i>Free Shipping Over $50</span>
//       </div>
//     </div>
//   </div>
// </section>
//
// Styleguide header.ksp

.fade__wrapper {
  align-items: center;
  -ms-grid-column-align: center;
  -ms-flex-line-pack: justify;
  column-gap: 5px;
  display: grid;
  display: -ms-grid;
  height: 25px;
  grid-template-columns: repeat(3, auto);
  -ms-grid-columns: repeat(3, auto);
  grid-template-rows: 1fr;
  -ms-grid-rows: 1fr;
}
.fade__object {
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-pack: center;
  grid-row: 1;
  text-align: center;
}
.f1 {
  grid-column: 2 / 3;
  -ms-grid-column: 2 / 3;
}
.f2 {
  grid-column: 2 / 3;
  -ms-grid-column: 2 / 3;
}
.f3 {
  grid-column: 2 / 3;
  -ms-grid-column: 2 / 3;
}
@media screen and (min-width: 768px) {
  .fade__wrapper {
    grid-template-columns: repeat(2, auto);
    -ms-grid-columns: repeat(2, auto);
  }
  .f1 {
    display: none;
  }
  .f2 {
    grid-column: 1 / 2;
    -ms-grid-column: 1 / 2;
  }
  .f3 {
    grid-column: 2 / 2;
    -ms-grid-column: 2 / 2;
  }
}
@media screen and (min-width: 992px) {
  .fade__wrapper {
    grid-template-columns: repeat(3, auto);
    -ms-grid-columns: repeat(3, auto);
  }
  .f1 {
    display: block;
    display: -ms-flexbox;
    grid-column: 1 / 2;
    -ms-grid-column: 1 / 2;
  }
  .f2 {
    grid-column: 2 / 3;
    -ms-grid-column: 2 / 3;
  }
  .f3 {
    grid-column: 3 / 3;
    -ms-grid-column: 3 / 3;
  }
}
@media (max-width: 767px) {
  .fade__object {
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 12s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 6s;
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    43% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    // change this number for fade in speed
    15% {
      opacity: 1;
    }
    // change this number for fade out speed
    25% {
      opacity: 1;
    }
    // don't change this number
    40% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  // delays are reversed so that it starts in the faded position
  .f2 {
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
  }
  .f3 {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  }
}

// CSC Hours
//
// A dedicated CSC Hours section.
//
// Markup:
// <section class="avry-csc">
//   <div class="container container-fluid-sm-down">
//     <div class="row justify-content-center text-uppercase fnt-sze-12">
//       <div class="col-4 col-sm-auto">
//         <span class="font-weight-bold">
//           Call Us:
//         </span>
//         <span class="d-block d-sm-inline">
//           (888) 888-8888
//         </span>
//       </div>
//       <div class="col-8 col-sm-auto">
//         <span class="font-weight-bold">
//           Office Hours:
//         </span>
//         <span class="d-none d-sm-inline d-md-none">Mon to Fri 6am – 5pm pt, Sat 10am – 2pm PT</span>
//         <span class="d-block d-sm-none d-md-inline">Monday to Friday 6am – 5pm pt, Saturday 10am – 2pm PT</span>
//       </div>
//     </div>
//   </div>
// </section>
//
// Styleguide header.csc

// TODO: The class names '.avy-inds-ksp' and '.avy-inds-csc' can be phased out because these styles are no longer Industrial Specific. Suggest a regression test
.avry-ksp,
.avry-csc,
.avy-inds-ksp,
.avy-inds-csc {
  height: 48px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

// TODO: The class names '.avy-inds-csc' can be phased out because these styles are no longer Industrial Specific. Suggest a regression test
.avry-csc,
.avy-inds-csc {
  @include media-breakpoint-only(xs) {
    > .container {
      padding: 0 20px;

      > .row {
        margin: 0;
      }

      [class*='col'] {
        padding: 0;
      }
    }
  }
}

.cls-header {
  min-height: rem(62);

  @include media-breakpoint-down(sm) {
    min-height: rem(50);
  }

  @include media-breakpoint-down(xs) {
    min-height: rem(44);
  }
}