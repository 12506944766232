@use "sass:math";

// Common Accordion classes shared accross different accordion types.

// IMPORTANT: Add 'accordion-avry-bs' to the root accordion container if the accordion is using jQuery. NOT needed if the accordion is using an Angular accordion implementation

/* ==========================================================================
   Panel Icon
   ========================================================================== */

.accordion-avry-bs .collapsed .panel-icon, // For bootstrap only
.panel-icon // For ng-bootstrap
{
  $icon-dimensions: 10px;

  display: block;
  width: $icon-dimensions;
  height: $icon-dimensions;
  margin: rem(4) 0;
  position: relative;
  float: right;

  &:before,
  &:after {
    content: '';
    background-color: $base-dark;
    position: absolute;
    transition: 150ms all ease-out;
  }

  &:before {
    height: $icon-dimensions;
    width: 2px;
    top: 0;
    left: math.div($icon-dimensions, 2) - 1px;
    transform: rotate(0deg);
  }

  &:after {
    height: 2px;
    width: $icon-dimensions;
    top: (math.div($icon-dimensions, 2)) - 1px;
    left: 0;
  }
}

/* ==========================================================================
   Panel Icon - Active State
   ========================================================================== */

.accordion-avry-bs .card-header a:not(.collapsed) .panel-icon, // For bootstrap only
.accordion-avry .card-header button:not([aria-expanded="false"]) .panel-icon // For ng-bootstrap
{
  &:before {
    transform: rotate(90deg);
  }
}

.acdn__header {
  margin-bottom: 12px;
}
.acdn {
  .acdn__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $light-gray;
    box-shadow: none;
    border-width: 0;
    padding: 12px;
    width: 100%;

    &[aria-expanded='true'] {
      .fonticon--down {
        transform: rotate(180deg);
        transition: width 1s;
      }
    }
  }
}
.acdn__panel {
  display: none;

  &[aria-hidden='true'] {
    display: block;
  }
}
.acdn__panel__button-icon {
  display: inline-block;
  background-color: #105bd2;
  border-radius: 15px;
  flex: 0 0 auto;
  height: 30px;
  margin: 0.25rem 1rem 0.25rem 0;
  position: relative;
  width: 30px;

  &:before,
  &:after {
    content: '';
    background-color: #ffffff;
    position: absolute;
    transition: 150ms all ease-out;
  }
  &:before {
    background-color: #ffffff;
    height: 10px;
    left: 14px;
    top: 10px;
    width: 2px;
    [aria-expanded='true'] & {
      transform: rotate(90deg);
    }
  }
  &:after {
    height: 2px;
    width: 10px;
    top: 14px;
    left: 10px;
    background-color: #ffffff;
  }
}
