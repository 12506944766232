// Holiday
//
// General styles used in Holiday
//
// Styleguide holiday

// Background Color Utilities
//
// Add these utility classes to add background colors to your element(s).
//
// NOTE: These colors are based on the Avery Holiday color palette.
//
// .bg-aqua-green - Aqua Green Background
// .bg-aqua-teal - Aqua Teal Background
// .bg-aquamarine - Aquamarine Background
// .bg-burgundy - Burgundy Background
// .bg-cool-blue - Cool Blue Background
// .bg-cool-blue - Cool Blue Background
// .bg-cool-gray - Cool Gray Background
// .bg-dark-tan - Dark Tan Background
// .bg-forest-green - Forest Green Background
// .bg-hunter-green - Hunter Green Background
// .bg-indian-red - Indian Red Background
// .bg-mint-green - Mint Green Background
// .bg-navy-blue - Navy Blue Background
// .bg-peach - Peach Background
// .bg-ruby-red - Ruby Red Background
// .bg-sky-blue - Sky Blue Background
// .bg-steel-blue - Steel Blue Background
// .bg-sweet-rose - Sweet Rose Background
//
// Markup:
// <div class="p-3 my-2 w-100 {{modifier_class}}" style="color: #FFA31F;">Simply add this `.{{modifier_class}}` class to your element.</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide holiday.background-colors

.bg-aqua-green {
  background-color: $aqua-green !important;
}
.bg-aqua-teal {
  background-color: $aqua-teal !important;
}
.bg-aquamarine {
  background-color: $aquamarine !important;
}
.bg-burgundy {
  background-color: $burgundy !important;
}
.bg-cool-blue {
  background-color: $cool-blue !important;
}
.bg-cool-blue {
  background-color: $cool-blue !important;
}
.bg-cool-gray {
  background-color: $cool-gray !important;
}
.bg-dark-tan {
  background-color: $dark-tan !important;
}
.bg-forest-green {
  background-color: $forest-green !important;
}
.bg-hunter-green {
  background-color: $hunter-green !important;
}
.bg-indian-red {
  background-color: $indian-red !important;
}
.bg-mint-green {
  background-color: $mint-green !important;
}
.bg-navy-blue {
  background-color: $navy-blue !important;
}
.bg-peach {
  background-color: $peach !important;
}
.bg-ruby-red {
  background-color: $ruby-red !important;
}
.bg-sky-blue {
  background-color: $sky-blue !important;
}
.bg-steel-blue {
  background-color: $steel-blue !important;
}
.bg-sweet-rose {
  background-color: $sweet-rose !important;
}

// Text Color Utilities
//
// Holiday text color classes that will change the color of text.
//
// .text-navy-blue - Navy Blue color for Text
//
// Markup:
// <h3 class="m-0 {{modifier_class}}">The quick brown fox jumps over the lazy dog</h3>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide holiday.text-colors

.text-navy-blue {
  color: $navy-blue !important;
}
