// TODO: move these mixins to proper mixin file
@mixin btn-action-solid($color, $button-color, $hover-color) {
  background-color: $button-color;
  border: rem($avry-industrial-border-size) solid $button-color;
  color: $color;

  // Focus State
  // FIXME: Apply focus state style once we have it..
  &:focus {
    background-color: $hover-color;
    border: rem($avry-industrial-border-size) solid $hover-color;
  }

  // Hover State
  &:hover {
    background-color: $hover-color;
    border: rem($avry-industrial-border-size) solid $hover-color;
    color: $color;
  }

  // Disabled State
  &.disabled,
  &:disabled {
    background-color: $hover-color;
  }
}

@mixin btn-action-outline($color, $border-color, $button-background: '') {
  // For outline buttons that have a custom background color.
  @if $button-background != "" {
    background: rgba($button-background, 0.2);
  }

  border: rem($avry-industrial-border-size) solid $border-color;
  color: $color;
}
