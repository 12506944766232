// TODO: Document these tiles when final styles have been delivered..
// TODO: Refactor the upcoming 'blank-labels filter' tiles to use these styles.

// FIXME: Deprecated - Remove `.tile-filter-item` and all its nested styles once verified not/no longer used.
.tile-filter-item {
  @include tile-container-common();
}

// FIXME: Deprecated - Remove `.tile-filter-item.tile-grid-item` and all its nested styles once verified not/no longer used.
.tile-filter-item.tile-grid-item {
  @include tile-container-grid-common();

  padding: 0;

  .tile-text {
    &.text-main-long {
      $text-height: 20px;
      height: $text-height;
      margin-bottom: rem(15);

      .text-truncate-multiline {
        max-height: $text-height;
        margin: 0;
      }
    }

    &.text-details {
      $text-height: 40px;
      height: $text-height;
      margin-bottom: rem(15);

      .text-truncate-multiline {
        max-height: $text-height;
        margin: 0;
      }
    }
  }
}

// FIXME: Deprecated - Remove `.tile-filter-item.tile-list-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-filter-item.tile-list-item .tile-container {
  height: 215px;

  @include tile-container-list-common();
  @include tile-filter-container-list-common();
}
