// Text Icons
//
// Utility classes to add icons to text that aren't links.
//
// .text-style--bullet - Adds a bullet to the left
// .text-style--check - Adds a check mark to the left
// .text-style--check-bull - Adds a checked bullet to the left
// .text-style--check-circle - Adds a checked circle to the left
// .text-style--times - Adds an X to the left
// .text-style--bullet.text-icon-left--red - makes an icon red seperate from the text
// .text-style--bullet.text-icon-left--green - makes an icon green seperate from the text
// .text-style--bullet.text-icon-left--blue - makes an icon blue seperate from the text
// .text-link--right-arrow.text-icon-right--red - makes an icon red seperate from the text
// .text-link--right-arrow.text-icon-right--green - makes an icon green seperate from the text
// .text-link--right-arrow.text-bright-red.text-icon-right--blue - makes an icon blue seperate from the text
//
// Markup:
// <a class="{{modifier_class}}">Sample Text Link</a>
//
// hidedefaultstyling: true
//
// Styleguide typography.b-text-icons

.text-icon-right--red {
  &:after {
    color: $bright-red !important;
  }
}

.text-icon-left--red {
  &:before {
    color: $bright-red !important;
  }
}

.text-icon-right--green {
  &:after {
    color: $bright-green !important;
  }
}

.text-icon-left--green {
  &:before {
    color: $bright-green !important;
  }
}

.text-icon-right--blue {
  &:after {
    color: $primary-blue !important;

    body[data-avry-mode='industrial'] & {
      color: $industrial-blue !important;
    }
  }
}

.text-icon-left--blue {
  &:before {
    color: $primary-blue !important;

    body[data-avry-mode='industrial'] & {
      color: $industrial-blue !important;
    }
  }
}

.text-style--bullet {
  @extend %icon-left;

  &:before {
    content: '\e929' !important;
  }
}

.text-style--check {
  @extend %icon-left;

  &:before {
    content: '\e924' !important;
  }
}

.text-style--check-bull {
  @extend %icon-left;

  &:before {
    content: '\e92b' !important;
  }
}

.text-style--check-circle {
  @extend %icon-left;

  &:before {
    content: '\e92a' !important;
  }
}

.text-style--times {
  @extend %icon-left;

  &:before {
    content: '\e910' !important;
  }
}

li {
  &.text-style--bullet,
  &.text-style--check,
  &.text-style--check-bull,
  &.text-style--check-circle,
  &.text-style--times {
    position: relative;
    padding-left: 24px;

    &:before {
      position: absolute;
      left: 0;
    }
  }
}
