// WePrint
//
// styleguide weprint

/* ==========================================================================
   WEPRINT INFO SECTION
   ========================================================================== */

//-// WePrint Info Section
//-//
//-// Base We Print Section. Seen on the Template Start Page and Some Landing Page(s).
//-//
//-// Markup:
//-// <section class="avery-we-print-info-section">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="avery-we-print-info">
//-//       <div class="avery-we-print-logo-container">
//-//         <img src="//stg-cms.avery.com/app/images/logos/AVY_WePrint_Logo.svg" style="max-width: 375px;" />
//-//       </div><!-- /.avery-we-print-logo-container -->
//-//       <div class="we-print-info-details">
//-//         <div class="row align-items-center justify-content-center">
//-//           <div class="col-12 col-md-6 text-right d-none d-md-block">
//-//             <img class="we-print-info-img" style="max-width: 250px;" src="https://stg-cms.avery.com/app/images/dpo/hp_weprint-icons.png" alt="" />
//-//           </div>
//-//           <div class="col-12 col-md-6 text-left">
//-//             <ul class="templates-info-check-list text-left">
//-//               <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Custom printing made easy</li>
//-//               <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Low minimum order quantities</li>
//-//               <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Free, easy-to-use templates and designs</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//       </div>
//-//       <a href="http://www.avery.com/weprint/">
//-//         <button class="btn btn-avry btn-action-blue">Let us print for you</button>
//-//       </a>
//-//     </div><!-- /.avery-we-print-info -->
//-//   </div>
//-// </section>
//-//
//-// Styleguide weprint.weprint-info

//-// WePrint Info Promo Section
//-//
//-// Contains Base We Print Section. Includes some promo specific styles. Seen on Industrial Page(s).
//-//
//-// Markup:
//-// <section class="avery-we-print-info-section avery-we-print-promo mt-5">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="avery-we-print-info">
//-//       <div class="avery-we-print-logo-container">
//-//         <img src="//stg-cms.avery.com/app/images/logos/AVY_WePrint_Logo.svg" style="max-width: 375px;" />
//-//       </div>
//-//       <div class="we-print-info-details">
//-//         <div class="row align-items-center justify-content-center">
//-//           <div class="col-12">
//-//             <div class="promo-code">
//-//               Get 10% off* Promo Code: GHS10
//-//             </div>
//-//           </div>
//-//           <div class="col-12 col-md-6 text-md-right">
//-//             <img class="we-print-info-img" style="max-width: 250px;" src="https://stg-cms.avery.com/app/images/dpo/hp_weprint-icons.png" alt="" />
//-//           </div>
//-//           <div class="col-12 col-md-6 text-left">
//-//             <ul class="templates-info-check-list text-left">
//-//               <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Custom printing made easy</li>
//-//               <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Low minimum order quantities</li>
//-//               <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> Free, easy-to-use templates and designs</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//       </div>
//-//       <a href="http://www.avery.com/weprint/">
//-//         <button class="btn btn-avry btn-action-blue">Let us print for you</button>
//-//       </a>
//-//       <p class="offer-details">
//-//         * Offer expires 6/30/2017. Offer is only valid on orders shipping within the United States and Puerto Rico and does not apply to Canada. Limit one promotional code per customer. Discount will be applied to product subtotal, and will not apply to shipping, handling or tax. Additional charges may apply for shipping, tax and/or processing unless otherwise noted. Discount applies to Avery WePrint purchases only and does not apply to retail packaged products sold on this website. Discount will not be applied to any previous transactions and cannot be combined with any other promotional offer. Avery may change, modify or cancel this promotion at any time without notice. Offer subject to change.
//-//       </p>
//-//     </div>
//-//   </div>
//-// </section>
//-//
//-// Styleguide weprint.weprint-promo

// FIXME: Deprecated - Remove `.avery-we-print-info-section` and all its nested styles once verified not/no longer used.
.avery-we-print-info-section {
  padding-top: rem(70);
  padding-bottom: rem(80);
  text-align: center;

  .we-print-info-details {
    margin-bottom: rem(20);
  }

  &.avery-we-print-promo {
    background-color: #f4fafc; // NOTE: color specific to the promo

    .promo-code {
      max-width: 375px;
      margin: 0 auto rem(10);
      background-color: rgba($gray-medium, 0.8);
      border-radius: rem(5);
      padding: rem(5);
    }

    .offer-details {
      max-width: 890px;
      margin: rem(60) auto 0;
      font-size: rem(13);
    }
  }
}

/* ==========================================================================
   ADPO DETAILS SECTION
   ========================================================================== */

// ADPO Details Section
//
// DPO/ADPO section found in Product and Template detail pages.
//
// Markup:
// <section class="adpo-detail-section top-divider">
//   <div class="template-info-details template-info-dpo">
//     <div class="row">
//       <div class="col-12 text-center d-md-none">
//         <h2 class="ado-main-header">Avery Design &amp; Print</h2>
//       </div>
//       <div class="col-12 col-md-6 text-center text-md-right">
//         <div class="flexible-img justify-content-center justify-content-md-end">
//           <img class="img-fluid img-fluid-height template-info-img" src="https://img.avery.com/f_auto,q_auto,c_scale,w_515/web/templates/tdp-dpo-banner-holiday">
//         </div>
//       </div>
//       <div class="col-12 col-md-6">
//         <h2 class="ado-main-header d-none d-md-block">Avery Design &amp; Print</h2>
//         <ul class="templates-info-check-list">
//           <li><i class="fa fa-circle" aria-hidden="true"></i> Free unlimited usage, no download required</li>
//           <li><i class="fa fa-circle" aria-hidden="true"></i> Thousands of free customizable templates</li>
//           <!-- Show this if product IS a DIFY product -->
//           <li><i class="fa fa-circle" aria-hidden="true"></i> Print it yourself or let us print it for you</li>
//           <!-- Show this if product is NOT a DIFY product -->
//           <!-- <li *ngIf="!product.difyProductNumber"><i class="fa fa-circle" aria-hidden="true"></i> Personalize &amp; print on demand</li> -->
//         </ul>
//       </div>
//     </div>
//   </div>
//   <div class="template-info-actions">
//     <div class="row text-center">
//       <!-- NOTE: Add '.offset-md-2' class if product is NOT a DIFY product -->
//       <div class="col-12 col-md-4">
//         <div class="option-box template-detail-option-box">
//           <div class="row">
//             <div class="col-4 col-sm-5 col-md-12">
//               <div class="option-box-img">
//                 <img class="tile-img" src="https://www.avery.com/assets/svg/templates/template-details/design-own.svg" />
//               </div>
//             </div>
//             <div class="col-8 col-sm-7 col-md-12 text-left text-md-center">
//               <h4>Start Designing</h4>
//               <p>Start with a blank or professional design</p>
//               <a class="btn btn-avry btn-action">
//                 Start Designing
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="col-12 col-md-4">
//         <div class="option-box template-detail-option-box">
//           <div class="row">
//             <div class="col-4 col-sm-5 col-md-12">
//               <div class="option-box-img">
//                 <img class="tile-img" src="https://www.avery.com/assets/svg/templates/template-details/upload.svg" />
//               </div>
//             </div>
//             <div class="col-8 col-sm-7 col-md-12 text-left text-md-center">
//               <h4>Upload a Design</h4>
//               <p>jpg, png, pdf, psd, ai &amp; more</p>
//               <a class="btn btn-avry btn-action">
//                 Upload a Design
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//       <!-- NOTE: Show this Option if product IS a DIFY product -->
//       <div class="col-12 col-md-4">
//         <div class="option-box template-detail-option-box">
//           <div class="row">
//             <div class="col-4 col-sm-5 col-md-12">
//               <div class="option-box-img">
//                 <img class="tile-img" src="https://img.avery.com/w_1200/web/templates/tdp-icon-weprint-let-us-print-it-for-you.svg" />
//               </div>
//             </div>
//             <div class="col-8 col-sm-7 col-md-12 text-left text-md-center">
//               <h4>Let Us Print for You</h4>
//               <p>Custom printing by Avery WePrint</p>
//               <a class="btn btn-avry">
//                 Let Us Print for You
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
//
// Styleguide weprint.adpo
.adpo-detail-section {
  padding: rem(25) 0;

  @include media-breakpoint-up(md) {
    padding: rem(45) 0;
  }

  h2.ado-main-header {
    font-size: 30px;
    margin-bottom: 2rem;

    @include media-breakpoint-only(md) {
      margin-bottom: 0;
      font-size: 38px;
    }

    @include media-breakpoint-only(lg) {
      font-size: 48px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 55px;
    }
  }

  .template-info-img {
    width: 100%;
    max-width: 500px;
  }

  .template-info-details {
    @include media-breakpoint-up(md) {
      margin-bottom: rem(16);
    }

    ul.templates-info-check-list {
      font-size: 20px;
      padding: rem(14) 0;

      @include media-breakpoint-only(sm) {
        max-width: 400px;
        font-size: 18px;
      }

      @include media-breakpoint-only(md) {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      li {
        margin: 0;

        i {
          font-size: 13px;
          line-height: 2.6;
          padding-left: 20px;
          padding-right: 10px;

          @include media-breakpoint-only(md) {
            line-height: 2.2;
          }

          @include media-breakpoint-up(lg) {
            line-height: 3;
          }
        }
      }
    }

    .row {
      @include media-breakpoint-up(md) {
        align-items: center;
      }
    }
  }

  .template-detail-option-box {
    @include media-breakpoint-down(sm) {
      margin-bottom: rem(40);
    }

    .option-box-img {
      height: 80px;

      .tile-img {
        height: 75px;
        max-width: 75px;
      }
      img.tile-img {
        @include media-breakpoint-only(xs) {
          max-width: 65px;
        }
        @include media-breakpoint-only(sm) {
          max-width: 75px;
        }
      }
    }

    .btn.btn-avry {
      min-width: 150px;

      @include media-breakpoint-only(xl) {
        min-width: 200px;
      }
    }
  }
}

/* ==========================================================================
   WEPRINT KSP Section
   ========================================================================== */

//-// WePrint KSP Section
//-//
//-// KSPs displayed in the WePrint pages.
//-//
//-// NOTE: This element must be wrapped in a `<div class="container container-fluid-sm-down"></div>` element.
//-//
//-// Markup:
//-// <div class="avry-weprint-ksp-section">
//-//   <div class="row">
//-//     <div class="col-12 col-md-4">
//-//       <div class="ksp-item">
//-//         <div class="ksp-icon">
//-//           <img class="img-fluid" src="https://stg-cms.avery.com/app/images/ksp/ksp_01.svg" />
//-//         </div>
//-//         <div class="ksp-details"><strong>Exceptional Print Quality</strong> on Premium Paper</div>
//-//       </div>
//-//     </div>
//-//     <div class="col-12 col-md-4">
//-//       <div class="ksp-item">
//-//         <div class="ksp-icon">
//-//           <img class="img-fluid" src="https://stg-cms.avery.com/app/images/ksp/ksp_02.svg" />
//-//         </div>
//-//         <div class="ksp-details"><strong>Low minimum order</strong> quantities. No set up fees.</div>
//-//       </div>
//-//     </div>
//-//     <div class="col-12 col-md-4">
//-//       <div class="ksp-item">
//-//         <div class="ksp-icon">
//-//           <img class="img-fluid" src="https://stg-cms.avery.com/app/images/ksp/ksp_03.svg" />
//-//         </div>
//-//         <div class="ksp-details">Ships within <strong>3 business days</strong> after proof approval.</div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// Styleguide weprint.weprint-ksp-section

// FIXME: Deprecated - Remove `.avry-weprint-ksp-section` and all its nested styles once verified not/no longer used.
.avry-weprint-ksp-section {
  background: $washed-blue;
  border-radius: $tile-border-radius;
  padding: rem(6) rem(40);

  @include media-breakpoint-up(md) {
    padding-top: rem(9);
    padding-bottom: rem(9);
  }

  .ksp-item {
    display: flex;
    height: 100%;
    align-items: center;
    padding: rem(10) 0;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .ksp-icon {
    width: 60px;
    height: 60px;
    margin-right: rem(20);
    flex-shrink: 0;
  }
}

.avry-weprint-ksp-section-calculator {
  background: transparent;
  padding: 0 rem(10);

  .ksp-item {
    margin-bottom: rem(16);
  }
}

/* ==========================================================================
   PRICING CHART (LOCATED IN ACCORDION)
   ========================================================================== */

.material-pricing-chart {
  .material-header {
    @include media-breakpoint-up(md) {
      min-height: 75px;
    }
  }

  .material-pricing-container {
    table {
      margin-bottom: rem(32);

      thead, tbody {
        display: block;
      }
      tbody {
        max-height: 400px;
        border-bottom: 2px solid $base-border-color;
        overflow: auto;
        -webkit-overflow-scrolling: touch; // NOTE: this extra feature is only available on Chrome... For now...
      }
      thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      thead {
        width: 96%;
      }
    }
  }
}

/* ==========================================================================
   SEO SECTION
   ========================================================================== */
.avry-weprint-seo-section {
  background-color: $faint-gray;
  padding: 3rem;
  margin: 3rem 0;

  @include media-breakpoint-up(md) {
    margin: 4rem;
  }

  p {
    margin: 0;
  }
}

/* ==========================================================================
   PRODUCT FEATURES
   ========================================================================== */
.avry-product-features {
  max-width: 860px;
  margin: 0 auto;
  text-align: center;
  
  .divider-line {
    margin: rem(15) 0 rem(30);
  }

  .feature-outline {
    text-align: left;

    ul {
      padding: 0 rem(10) 0 rem(45);
    }

    li {
      margin: rem(10) 0;
    }
  }
}

/* ==========================================================================
   WePrint Footnotes
   ========================================================================== */
.avry-footnote {
  color: $bold-gray;
}
