// Industrial Variables:
// NOTE: $font-size-base is a bootstrap variable. It is overridden for `industrial` mode.
$font-size-base: 16px;

// Industrial Mixins:
@import 'industrial/mixins/mixins_industrial_buttons';

body[data-avry-mode='industrial'] {
  // Base Font
  font-family: 'urw-din', 'Roboto', Helvetica, Arial, sans-serif;
  @include font-size($font-size-base);

  /**
   *
   * Includes
   *
   */
  @import 'industrial/industrial_accordion';
  @import 'industrial/industrial_buttons';
  @import 'industrial/industrial_dotcms';
  @import 'industrial/industrial_header';
  @import 'industrial/industrial_magento-cart';
  @import 'industrial/industrial_swiper';
  @import 'industrial/industrial_type';
  @import 'industrial/industrial_utilities';
}
