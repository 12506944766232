@use "sass:math";

/* ==========================================================================
   FILTER BASE HEADER
   ========================================================================== */

// Filter Base Header
//
// Base filter header used to filter collection of items.
//
// Markup:
// <header class="filter-base-header">
//   <div class="container">
//     <div class="row align-items-md-end">
//       <div class="col-12">
//         <div class="results-header">
//           <div class="modify-list-actions header-content">
//             <div class="row align-items-center">
//               <div class="col-6 col-md-4">
//                 <div class="results-show-qty">
//                   <span class="label-qty-showing">Showing 1-9 of 200</span>
//                 </div><!-- /.results-show-qty -->
//               </div>
//               <div class="col-6 col-md-4 text-right text-md-center">
//                 <div class="results-sort-by">
//                   <div class="dropdown dropdown-avry d-inline-block">
//                     <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                       <strong>SORT BY</strong> Featured
//                     </button>
//                     <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
//                       <a class="dropdown-item" href="#">Action</a>
//                       <a class="dropdown-item" href="#">Another action</a>
//                       <a class="dropdown-item" href="#">Something else here</a>
//                     </div>
//                   </div>
//                 </div><!-- /.results-sort-by -->
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div><!-- /.container -->
// </header><!-- /.filter-base-header -->
//
// styleguide filters.filter-base-header

.filter-base-header {
  padding: rem(30) 0 rem(10);

  @include media-breakpoint-up(md) {
    padding: rem(40) 0 rem(10);
  }
}

/* ==========================================================================
   FILTER PAGE(S) header content

   NOTE: Pages that have the filter page structure, (such as: Product Filter page, Template Filter Page, Search Results Page).
   ========================================================================== */

// Filter Header with Title
//
// This is the base filter header used in Filter Pages (i.e. Product Filter Page, Template Filter Page, Search Results Page etc.) in the Angular App.
//
// **IMPORTANT:** When using the Markup snippet below, you might notice that the Filter Header takes up the whole space of the page. Simply wrap the element inside a `<div class="container container-fluid-sm-down"> ... </div>` to be as wide as the page container.
//
// **NOTE:** For the dropdown menu in  Angular based applications, please use the *ng-bootstrap* component (https://ng-bootstrap.github.io/#/components/dropdown).
//
// Markup:
// <header class="filter-results-header">
//   <div class="row align-items-md-end">
//     <div class="col-12">
//       <div class="results-header">
//         <div class="top-header" class="header-content">
//           <h1>Products</h1>
//         </div><!-- /.top-header -->
//         <div class="modify-list-actions header-content">
//           <div class="row align-items-center">
//             <div class="col-3 col-md-4">
//               <div class="results-show-qty">
//                 <span class="label-qty-showing d-none d-md-block">Showing 1-9 of 200</span>
//                 <!-- Mobile Only [Start] -->
//                 <span class="d-md-none">200 items</span>
//                 <!-- Mobile Only [END] -->
//               </div><!-- /.results-show-qty -->
//             </div>
//             <div class="col-9 col-md-6">
//               <div class="results-sort-by text-right text-md-center text-lg-right">
//                 <!-- Mobile Only [Start] -->
//                 <div class="btn btn-filter d-md-none">
//                   FILTER
//                   <span *ngIf="filterCount > 0">(10)</span>
//                 </div><!-- /.btn btn-filter -->
//                 <!-- Mobile Only [END] -->
//                 <!-- <span class="d-none d-md-block">Sort By</span> -->
//                 <!-- Note: Bootstrap 4 drowpdown -->
//                 <div class="dropdown dropdown-avry d-inline-block">
//                   <div ngbDropdown class="d-inline-block">
//                     <button class="btn dropdown-toggle" id="dropdownMenu1" ngbDropdownToggle><strong>SORT BY</strong> Price</button>
//                     <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
//                       <button class="dropdown-item">Action</button>
//                       <button class="dropdown-item">Another Action</button>
//                       <button class="dropdown-item">Something else is here</button>
//                     </div>
//                   </div>
//                 </div>
//               </div><!-- /.results-sort-by -->
//             </div>
//             <div class="col-md-2 d-none d-md-block">
//               <div class="grid-and-list-toggle text-right">
//                 <div class="view-toggle view-toggle-grid d-inline-block active">
//                   <i class="fa fa-th-large" aria-hidden="true"></i>
//                 </div>
//                 <div class="view-toggle view-toggle-row d-inline-block">
//                   <i class="fa fa-list" aria-hidden="true"></i>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </header><!-- /.filter-results-header -->
//
// styleguide filters.filter-results-header-title

.filter-results-header {
  .header-content {
    // Desktop
    margin: 0 0 rem(math.div($grid-gutter-width, 2));
    padding: 0;

    @include media-breakpoint-down(sm) {
      // Mobile
      margin: 0 0 rem(math.div($grid-gutter-width, 2));
      padding: 0 rem(math.div($grid-gutter-width, 3));
    }
  }

  .btn-filter {
    cursor: pointer;
  }
}
