// Industrial Type
//
// Styleguide industrial-type

/* ==========================================================================
   INDUSTRIAL HEADER
   ========================================================================== */

// Industrial Header
//
// Header Styles for industrial, uses the base `URW DIN` font. Notice that `h1 and h2` are the only set in `All Caps`.
//
// Styles will apply when the `<h1>`~`<h6>` tag are used or by using the following classes:
//
// .h1 - Font Size: **36px**, Font Weight: **700 (Bold)**, Line Height: **1.17**
// .h2 - Font Size: **30px**, Font Weight: **700 (Bold)**, Line Height: **1.2**
// .h3 - Font Size: **24px**, Font Weight: **700 (Bold)**, Line Height: **1**
// .h4 - Font Size: **18px**, Font Weight: **700 (Bold)**, Line Height: **1.11**
// .h5 - Font Size: **16px**, Font Weight: **700 (Bold)**, Line Height: **1**
// .h6 - Font Size: **14px**, Font Weight: **700 (Bold)**, Line Height: **1**
//
// Markup:
// <div class="m-0 {{modifier_class}}">An Industrial Header</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide industrial-type.headers

// FIXME: Once Industrial is merged into Avery.com, apply the h5/h6 size changes in the bootstrap configuration (<theme>/src/theme/scss/bootstrap/_custom.scss)
// NOTE: The reason why it is not done now is because it will affect avery.com h5/h6 font sizes.
h1,
.h1 {
  font-size: rem(24);
  letter-spacing: rem(0.2);
  line-height: 1.17;

  @include media-breakpoint-only(sm) {
    font-size: rem(33);
    letter-spacing: rem(0.25);
    line-height: 1.2;
  }

  @include media-breakpoint-up(md) {
    font-size: rem(36);
    letter-spacing: rem(0.3);
    line-height: 1.17;
  }
}
h2,
.h2 {
  font-size: rem(20);
  letter-spacing: rem(0.13);
  line-height: 1.2;

  @include media-breakpoint-only(sm) {
    font-size: rem(24);
    letter-spacing: rem(0.16);
    line-height: 1.17;
  }

  @include media-breakpoint-up(md) {
    font-size: rem(30);
    letter-spacing: rem(0.2);
    line-height: 1.2;
  }
}
h3,
.h3 {
  font-size: rem(24);
  letter-spacing: rem(0.27);
  line-height: 1;
}
h4,
.h4 {
  font-size: rem(18);
  letter-spacing: rem(0.6);
  line-height: 1.11;
}
h5,
.h5 {
  font-size: rem(16);
  letter-spacing: rem(0.66);
  line-height: 1;
}
h6,
.h6 {
  font-size: rem(14);
  letter-spacing: rem(0.68);
  line-height: 1;
}

/* ==========================================================================
   INDUSTRIAL LINKS
   ========================================================================== */

.text-link,
a.text-link:not([href]):not([tabindex]) {
  color: $industrial-blue !important;

  &:hover {
    color: $industrial-hover-blue !important;
  }
}

// deprecated, use text-link--right-arrow instead
.icon-link-right-arrow {
  > a:after {
    color: $industrial-blue !important;;
  }

  > a:hover:after {
    color: $industrial-hover-blue !important;
  }
}
