// Alert
//
// Note the double dash for modifyer classes
//
// .alert--info - Important information
// .alert--success - You did it!
// .alert--warning - Not an error
// .alert--error - Something went wrong
//
// Markup:
// <div class="alert {{modifier_class}}" role="alert">
//   <strong>Alert</strong> - message letting the user know what has changed/if action is needed based on user action.
// </div>
//
// Styleguide alert

.alert {
  background-color: #f3f2ed;
  border: 1px solid #d2d2cf;
  border-radius: rem(3);
  font-size: rem(14);
  letter-spacing: rem(0.47);
  line-height: 1.11;
  padding: rem(16);
  position: relative;
  min-width: 300px;

  &:before {
    display: inline-block;
    content: '';
    font-family: 'avery-fonticon' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    left: rem(12);
    line-height: 1;
    position: absolute;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  }

  :last-child {
    margin: 0;
    padding: 0;
  }
}
.alert--checkmark {
  color: #07a200;
  width: 20px;  
  height: 20px;
}
.alert--info,
.alert--success,
.alert--warning,
.alert--error {
  color: #454545;
}

.alert--info {
  background-color: #d9edf7;
  border: 1px solid #bcdff1;

  &:before {
    content: '\e92f';
  }
}
.alert--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.alert--success {
  background-color: #f3fff2;
  border: 1px solid #7cde78;
}
.alert--close-button {
  background: none;
  border: none;
}
.alert-close-icon {
  width: 20px;
  height: 20px;
  color: #454545;
  stroke-width: 2px;
  opacity: 1 !important;
  
  &:hover {
    color: rgb(69 69 69 / 0.8);
  }
}
.alert--warning {
  background-color: #faeabf;
  border: 1px solid #d4bf87;

  &:before {
    content: '\e925';
    color: #f0bf0a;
  }
}
.alert--error {
  background-color: #f0bfc6;
  border: 1px solid #d28b96;

  &:before {
    content: '\e925';
    color: #b90c0c;
  }
}

// TODO: remove the following once replaced in the code

.alert.alert-success-avry {
  // Utilizes Bootstrap's 'alert-variant' mixin
  // alert warning bg, border color, text color;
  @include alert-variant($mint-green, $mint-green, $base-dark);
}

.alert.alert-warning-avry {
  // Utilizes Bootstrap's 'alert-variant' mixin
  // alert warning bg, border color, text color;
  @include alert-variant($pastel-yellow, $pastel-yellow, $base-dark);
}

.alert.alert-dark-avry {
  // Utilizes Bootstrap's 'alert-variant' mixin
  // alert warning bg, border color, text color;
  @include alert-variant($gray-medium, $gray-medium, $main-white);
}
