/* ==========================================================================
   TEMPLATE PAGES MIXINS
   ========================================================================== */

/*
   TEMPLATE START PAGE MIXINS
   ========================================================================== */
@mixin set-content-width-mobile() {
  @include media-breakpoint-only(sm) {
    max-width: 365px;
    margin-left: auto;
    margin-right: auto;
  }
}
