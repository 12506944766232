/* ==========================================================================
   TILE ITEM & TILE RELATED COMMON STYLES

   NOTE: any classes that relates specificially to tiles and are shared amoung all tile types will go in this file.
   ========================================================================== */

.tile-item {
  a:focus,
  a:hover {
    text-decoration: none;
  }

  .tile-container {
    @include tile-container-common();
  }

  .tile-text {
    overflow: hidden;

    &.text-details {
      .text-truncate-multiline {
        font-size: rem(13);
        line-height: 1.5;
      }
    }
  }

  .available-in-text {
    font-size: rem(13);
    line-height: 1.5;

    img {
      margin-top: -5px;
    }
  }
}

.tile-item-group {
  @include tile-item-group();
}

.tile-shadow-style {
  &:hover {
    .tile-img {
      @include tile-container-hover-style();
    }
  }

  // Thumbail
  .tile-img {
    @include tile-container-style();
  }
}

// Adds layer over thumbnail to add the cursor.
//
// NOTE: This fixes SVG 'no pointer cursor' issue.
.tile-img-cursor {
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

/* ==========================================================================
   SUB CATEGORY TILE GROUP

   NOTE: Group container that contains tiles.
   ========================================================================== */

//-// Sub-Category Tile Group
//-//
//-// A group container that contains tiles. This will applicable for 3 column and 4 column layout tiles.
//-//
//-// Markup:
//-// <section class="sub-category-tile-group">
//-//   <div class="container">
//-//     <div class="section-header">
//-//       <h2>Sub Category Group Title</h2>
//-//       <p class="sub-header">Sub header goes here - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo velit, ea quam vero quos? Ad voluptates, reprehenderit sequi porro explicabo error, voluptatum quam repudiandae optio magnam doloremque libero ab accusantium?</p>
//-//     </div>
//-//     <div class="row">
//-//        <div class="col-6 col-md-3">
//-//          <a href="./ideas-main-sub.html">
//-//            <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//              <div class="tile-img">
//-//                <picture>
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" media="(max-width: 768px)">
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(min-width: 769px)">
//-//                  <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                </picture>
//-//              </div>
//-//              <div class="tile-text tile-item-label">
//-//                <span>Sample</span>
//-//              </div>
//-//            </div>
//-//          </a>
//-//        </div>
//-//        <div class="col-6 col-md-3">
//-//          <a href="./ideas-main-sub.html">
//-//            <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//              <div class="tile-img">
//-//                <picture>
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(max-width: 768px)">
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(min-width: 769px)">
//-//                  <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                </picture>
//-//              </div>
//-//              <div class="tile-text tile-item-label">
//-//                <span>Sample</span>
//-//              </div>
//-//            </div>
//-//          </a>
//-//        </div>
//-//        <div class="col-6 col-md-3">
//-//          <a href="./ideas-main-sub.html">
//-//            <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//              <div class="tile-img">
//-//                <picture>
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(max-width: 768px)">
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(min-width: 769px)">
//-//                  <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                </picture>
//-//              </div>
//-//              <div class="tile-text tile-item-label">
//-//                <span>Sample</span>
//-//              </div>
//-//            </div>
//-//          </a>
//-//        </div>
//-//        <div class="col-6 col-md-3">
//-//          <a href="./ideas-main-sub.html">
//-//            <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//              <div class="tile-img">
//-//                <picture>
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(max-width: 768px)">
//-//                  <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" media="(min-width: 769px)">
//-//                  <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                </picture>
//-//              </div>
//-//              <div class="tile-text tile-item-label">
//-//                <span>Sample</span>
//-//              </div>
//-//            </div>
//-//          </a>
//-//        </div>
//-//     </div>
//-//   </div>
//-// </section>
//-//
//-// Styleguide tiles.sub-category-tile-group

//-// Sub-Category Tile Group w/ Background Color
//-//
//-// A variation of the `.sub-category-tile-group` with an addition of a colored background. Add color classes or inline styles with the `sub-category-tile-group` class.
//-//
//-// Markup:
//-// <section class="sub-category-tile-group bg-gray">
//-//   <div class="container">
//-//     <div class="row">
//-//       <div class="col-12 col-md-4">
//-//         <a href="https://accounts.avery.com/">
//-//           <div class="tile-item tile-category-thumb-label tile-cropped-col-3">
//-//             <div class="tile-img tile-img-cursor">
//-//               <picture>
//-//                 <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(max-width: 768px)">
//-//                 <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(min-width: 769px)">
//-//                 <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//               </picture>
//-//             </div>
//-//             <div class="tile-text tile-item-label text-truncate">
//-//               <span>Sample</span>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//       <div class="col-12 col-md-4">
//-//         <a href="https://accounts.avery.com/">
//-//           <div class="tile-item tile-category-thumb-label tile-cropped-col-3">
//-//             <div class="tile-img tile-img-cursor">
//-//               <picture>
//-//                 <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(max-width: 768px)">
//-//                 <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(min-width: 769px)">
//-//                 <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" alt="">
//-//               </picture>
//-//             </div>
//-//             <div class="tile-text tile-item-label text-truncate">
//-//               <span>Sample</span>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//       <div class="col-12 col-md-4">
//-//         <a href="https://accounts.avery.com/">
//-//           <div class="tile-item tile-category-thumb-label tile-cropped-col-3">
//-//             <div class="tile-img tile-img-cursor">
//-//               <picture>
//-//                 <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(max-width: 768px)">
//-//                 <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" media="(min-width: 769px)">
//-//                 <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" alt="">
//-//               </picture>
//-//             </div>
//-//             <div class="tile-text tile-item-label text-truncate">
//-//               <span>Sample</span>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </section>
//-//
//-// Styleguide tiles.sub-category-tile-group-with-backgorund-color

// FIXME: Deprecated - Remove `.sub-category-tile-group` and all its nested styles once verified not/no longer used.
.sub-category-tile-group {
  padding: rem(40) 0;
  text-align: center;

  a:hover {
    text-decoration: none;
  }

  .btn.btn-sub-category-tile-group {
    margin-top: rem(30);
  }
}

/* ==========================================================================
   SUB CATEGORY TILE GROUP - EXTERNAL HEADER

   NOTE: A Group container that contains tiles with a header outside of the group container.
   ========================================================================== */

//-// Sub-Category Tile Group - External Header
//-//
//-// A Group container that contains tiles with a header outside of the group container.
//-//
//-// Markup:
//-// <section class="sub-category-tile-group sub-category-external-header-tile-group industrial-landing-section">
//-//   <div class="tile-group-external-header section-header">
//-//     <h2>Featured Categories</h2>
//-//   </div>
//-//   <div class="tile-group-external-content bg-gray">
//-//     <div class="container container-fluid-sm-down">
//-//       <div class="row">
//-//         <div class="col-12 col-sm-6 col-md-3">
//-//           <a href="https://accounts.avery.com/">
//-//             <div class="tile-item tile-category-thumb-label tile-cropped-col-4">
//-//               <div class="tile-img tile-img-cursor">
//-//                 <picture>
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" media="(max-width: 768px)">
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(min-width: 769px)">
//-//                   <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                 </picture>
//-//               </div>
//-//               <div class="tile-text tile-item-label text-truncate">
//-//                 <span>Ultra Duty TM GHS Chemical Labels</span>
//-//               </div>
//-//             </div>
//-//           </a>
//-//         </div>
//-//         <div class="col-12 col-sm-6 col-md-3">
//-//           <a href="https://accounts.avery.com/">
//-//             <div class="tile-item tile-category-thumb-label tile-cropped-col-4">
//-//               <div class="tile-img tile-img-cursor">
//-//                 <picture>
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" media="(max-width: 768px)">
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(min-width: 769px)">
//-//                   <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                 </picture>
//-//               </div>
//-//               <div class="tile-text tile-item-label text-truncate">
//-//                 <span>Easy AlignTM Self-Laminating ID Labels</span>
//-//               </div>
//-//             </div>
//-//           </a>
//-//         </div>
//-//         <div class="col-12 col-sm-6 col-md-3">
//-//           <a href="https://accounts.avery.com/">
//-//             <div class="tile-item tile-category-thumb-label tile-cropped-col-4">
//-//               <div class="tile-img tile-img-cursor">
//-//                 <picture>
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_06.jpg" media="(max-width: 768px)">
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(min-width: 769px)">
//-//                   <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                 </picture>
//-//               </div>
//-//               <div class="tile-text tile-item-label text-truncate">
//-//                 <span>Durable ID Labels with TrueBlock® Technology</span>
//-//               </div>
//-//             </div>
//-//           </a>
//-//         </div>
//-//         <div class="col-12 col-sm-6 col-md-3">
//-//           <a href="https://accounts.avery.com/">
//-//             <div class="tile-item tile-category-thumb-label tile-cropped-col-4">
//-//               <div class="tile-img tile-img-cursor">
//-//                 <picture>
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(max-width: 768px)">
//-//                   <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" media="(min-width: 769px)">
//-//                   <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_05.jpg" alt="">
//-//                 </picture>
//-//               </div>
//-//               <div class="tile-text tile-item-label text-truncate">
//-//                 <span>Durable ID Labels with TrueBlock® Technology</span>
//-//               </div>
//-//             </div>
//-//           </a>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </section>
//-//
//-// Styleguide tiles.sub-category-tile-group-external-header

// FIXME: Deprecated - Remove `.sub-category-tile-group` and all its nested styles once verified not/no longer used.
.sub-category-tile-group.sub-category-external-header-tile-group {
  .tile-group-external-content {
    padding: rem(40) 0 0;
  }
}

/* ==========================================================================
   SUB CATEGORY TILE AND TEXT CONTENT GROUP - LEFT and RIGHT TEXT

   NOTE: Group container that contains tiles to the left and/or right.
   ========================================================================== */

//-// Sub-Category Tile and Text Group - Left Text
//-//
//-// A combination tile group with a text paragraph to the left and tiles to the right.
//-//
//-// **NOTE:** The tiles of this component turns into tiles for mobile version. Your would need to include the `Swiper` javascript plugin for this to work in Mobile.
//-//
//-// Markup:
//-//  <div class="sub-category-tile-text-left-group bg-light-powder-blue">
//-//    <div class="container container-fluid-sm-down">
//-//      <div class="section-header">
//-//        <h2>Featured Design</h2>
//-//        <p class="sub-header">Sub header goes here - Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
//-//      </div>
//-//      <div class="row">
//-//        <div class="col-12 col-md-4 text-center text-md-left">
//-//          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum ullam, iusto necessitatibus in ducimus quasi nemo quis amet id voluptate facilis quos quod modi, consectetur esse repellat enim veniam doloremque. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum ullam, iusto necessitatibus in ducimus quasi nemo quis amet id voluptate facilis quos quod modi, consectetur esse repellat enim veniam doloremque.</p>
//-//          <button class="btn btn-avry">View All</button>
//-//        </div>
//-//        <!-- NOTE: Show this for Desktop Only -->
//-//        <div class="col-12 col-md-8 d-none d-md-block">
//-//          <div class="row">
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_09.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Halloween</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Thanksgiving</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Holiday</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Corporate Gifts</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//          </div>
//-//        </div>
//-//      </div>
//-//      <!-- NOTE: Show this for Mobile only - Uses Swiper Javascript Plugin -->
//-//      <div class="col-12 d-md-none p-0">
//-//        <div class="swiper-carousel-container swiper-carousel-avry swiper-carousel-avry-gallery">
//-//          <div class="swiper-carousel-content text-center">
//-//            <div class="swiper-set">
//-//              <!-- Slider main container -->
//-//              <div class="swiper-container swiper-02">
//-//                <!-- Additional required wrapper -->
//-//                <div class="swiper-wrapper">
//-//                  <!-- Slides -->
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Halloween</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Thanksgiving</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Holiday</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Corporate Gifts</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                </div>
//-//                <div class="swiper-pagination swiper-pagination-02 d-md-none"></div>
//-//              </div>
//-//              <div class="swiper-button-prev swiper-button-prev-02 d-none d-md-block"></div>
//-//              <div class="swiper-button-next swiper-button-next-02 d-none d-md-block"></div>
//-//            </div>
//-//          </div>
//-//        </div>
//-//      </div>
//-//      <div class="text-center text-md-right mt-4">
//-//        <span class="icon-link-right-arrow">
//-//          <a href="https://accounts.avery.com/" class="text-link">View All Printable Holiday Designs</a>
//-//        </span>
//-//      </div>
//-//    </div>
//-//  </div>
//-//
//-// Styleguide tiles.sub-category-tile-text-left-group

//-// Sub-Category Tile and Text Group - Right Text
//-//
//-// A combination tile group with a text paragraph to the left and tiles to the left.
//-//
//-// **NOTE:** The tiles of this component turns into tiles for mobile version. Your would need to include the `Swiper` javascript plugin for this to work in Mobile.
//-//
//-// Markup:
//-//  <div class="sub-category-tile-text-right-group bg-light-orange">
//-//    <div class="container container-fluid-sm-down">
//-//      <div class="section-header">
//-//        <h2>Featured Design</h2>
//-//        <p class="sub-header">Sub header goes here - Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
//-//      </div>
//-//      <div class="row">
//-//        <!-- NOTE: Show this for Desktop Only -->
//-//        <div class="col-12 col-md-8 d-none d-md-block">
//-//          <div class="row">
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_09.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Halloween</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Thanksgiving</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Holiday</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//            <div class="col-3">
//-//              <div class="tile-item tile-thumb-label">
//-//                <div class="tile-img">
//-//                  <picture>
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(max-width: 768px)">
//-//                    <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" media="(min-width: 769px)">
//-//                    <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" alt="">
//-//                  </picture>
//-//                </div>
//-//                <div class="tile-text tile-item-label">
//-//                  <span>Corporate Gifts</span>
//-//                </div>
//-//              </div>
//-//            </div>
//-//          </div>
//-//        </div>
//-//        <div class="col-12 col-md-4 text-center text-md-left">
//-//          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum ullam, iusto necessitatibus in ducimus quasi nemo quis amet id voluptate facilis quos quod modi, consectetur esse repellat enim veniam doloremque. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum ullam, iusto necessitatibus in ducimus quasi nemo quis amet id voluptate facilis quos quod modi, consectetur esse repellat enim veniam doloremque.</p>
//-//          <button class="btn btn-avry">View All</button>
//-//       </div>
//-//      </div>
//-//      <!-- NOTE: Show this for Mobile only - Uses Swiper Javascript Plugin -->
//-//      <div class="col-12 d-md-none p-0">
//-//        <div class="swiper-carousel-container swiper-carousel-avry swiper-carousel-avry-gallery">
//-//          <div class="swiper-carousel-content text-center">
//-//            <div class="swiper-set">
//-//              <!-- Slider main container -->
//-//              <div class="swiper-container swiper-02">
//-//                <!-- Additional required wrapper -->
//-//                <div class="swiper-wrapper">
//-//                  <!-- Slides -->
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Halloween</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_11.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Thanksgiving</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_12.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Holiday</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                  <div class="swiper-slide">
//-//                    <a href="https://accounts.avery.com/">
//-//                      <div class="tile-item tile-thumb-label tile-cropped-col-4">
//-//                        <div class="tile-img">
//-//                          <picture>
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_13.jpg" media="(max-width: 768px)">
//-//                            <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" media="(min-width: 769px)">
//-//                            <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_16.jpg" alt="">
//-//                          </picture>
//-//                        </div>
//-//                        <div class="tile-text tile-item-label">
//-//                          <span>Corporate Gifts</span>
//-//                        </div>
//-//                      </div>
//-//                    </a>
//-//                  </div>
//-//                </div>
//-//                <div class="swiper-pagination swiper-pagination-02 d-md-none"></div>
//-//              </div>
//-//              <div class="swiper-button-prev swiper-button-prev-02 d-none d-md-block"></div>
//-//              <div class="swiper-button-next swiper-button-next-02 d-none d-md-block"></div>
//-//            </div>
//-//          </div>
//-//        </div>
//-//      </div>
//-//      <div class="text-center text-md-right mt-4">
//-//        <span class="icon-link-right-arrow">
//-//          <a href="https://accounts.avery.com/" class="text-link">View All Printable Holiday Designs</a>
//-//        </span>
//-//      </div>
//-//    </div>
//-//  </div>
//-//
//-// Styleguide tiles.sub-category-tile-text-right-group

// FIXME: Deprecated - Remove `.sub-category-tile-text-left-group, .sub-category-tile-text-right-group` and all its nested styles once verified not/no longer used.
.sub-category-tile-text-left-group,
.sub-category-tile-text-right-group {
  padding: rem(40) 0;

  .swiper-slide {
    height: auto;
  }

  .btn.btn-avry {
    min-width: 140px;
  }
}

/* ==========================================================================
   TILE - CROPPED SHORT AND Aligned in the Middle
   ========================================================================== */

//-// Utility - Cropped Thumbnail
//-//
//-// These classes crops the thumbnail image and center aligns it in the parent `.tile-img` container.
//-//
//-// **TIP:** For better results, it is suggested to use square images so that the cropping effect is balanced. It is suggested to **NOT** use rectangle images with these tile utility classes.
//-//
//-// **HOW TO USE:** Add the class(es) with the tile's `.tile-item` class.
//-//
//-// | Classes               | Description                |
//-// | --------------------- | -------------------------- |
//-// | `.tile-cropped-col-2` | Ideally tiles in 2 columns |
//-// | `.tile-cropped-col-3` | Ideally tiles in 3 columns |
//-// | `.tile-cropped-col-4` | Ideally tiles in 4 columns |
//-//
//-// Markup:
//-// <div class="container-fluid">
//-//   <!-- Example - tile-cropped-col-2 -->
//-//   <strong>Example - tile-cropped-col-2</strong>
//-//   <div class="row">
//-//     <div class="col-12 col-md-6">
//-//       <div class="tile-item tile-info-description tile-cropped-col-2">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_01.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_02.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid img-fluid-height" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_02.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <h4 class="tile-info-description-label text-truncate d-md-none">Templates</h4>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tristique.</p>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-//   <!-- Example - tile-cropped-col-3 -->
//-//   <strong>Example - tile-cropped-col-3</strong>
//-//   <div class="row">
//-//     <div class="col-12 col-md-4">
//-//       <div class="tile-item tile-info-description tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_03.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid img-fluid-height" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <h4 class="tile-info-description-label text-truncate d-md-none">Templates</h4>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tristique.</p>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-//   <!-- Example - tile-cropped-col-4 -->
//-//   <strong>Example - tile-cropped-col-4</strong>
//-//   <div class="row">
//-//     <div class="col-12 col-md-3">
//-//       <div class="tile-item tile-info-description tile-cropped-col-3">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_07.jpg" media="(max-width: 768px)">
//-//             <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" media="(min-width: 769px)">
//-//             <img class="img-fluid img-fluid-height" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_08.jpg" alt="">
//-//           </picture>
//-//         </div>
//-//         <div class="tile-block">
//-//           <h4 class="tile-info-description-label text-truncate d-md-none">Templates</h4>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tristique.</p>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.utility-cropped-thumbnail

// FIXME: Deprecated - Remove `%tile-cropped` and all its nested styles once verified not/no longer used.
%tile-cropped {
  // NOTE: This was setup to be extendable because before there were different 'tile-crop' rules.
  //       But in the current iteration, it was simplified to be applicable to
  text-align: center;
  overflow: hidden;
  position: relative;

  img {
    margin: 0;

    @include media-breakpoint-up(md) {
      margin: -100% 0;
    }
  }
}

// FIXME: Deprecated - Remove `.tile-item.tile-cropped-col-2, .tile-item.tile-cropped-col-3, .tile-item.tile-cropped-col-4` and all its nested styles once verified not/no longer used.
.tile-item.tile-cropped-col-2,
.tile-item.tile-cropped-col-3,
.tile-item.tile-cropped-col-4 {
  .tile-img {
    @extend %tile-cropped;
  }
}

// FIXME: Deprecated - Remove `.tile-item.tile-cropped-col-2` and all its nested styles once verified not/no longer used.
.tile-item.tile-cropped-col-2 {
  @each $bp in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($bp) {
      @include tile-cropped-img-container($bp, 2); // Cropped Tile for 2 column layout.
    }
  }
}

// FIXME: Deprecated - Remove `.tile-item.tile-cropped-col-3` and all its nested styles once verified not/no longer used.
.tile-item.tile-cropped-col-3 {
  @each $bp in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($bp) {
      @include tile-cropped-img-container($bp, 3); // Cropped Tile for 3 column layout.
    }
  }
}

// FIXME: Deprecated - Remove `.tile-item.tile-cropped-col-4` and all its nested styles once verified not/no longer used.
.tile-item.tile-cropped-col-4 {
  @each $bp in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($bp) {
      @include tile-cropped-img-container($bp, 4); // Cropped Tile for 3 column layout.
    }
  }
}

/* ==========================================================================
   SUB CATEGORY TILE GROUP MENU
   ========================================================================== */

//-// Sub-Category Tile Group Menu
//-//
//-// A Tile Group specifically used as a tile menu.
//-//
//-// markup:
//-// <div class="sub-category-tile-group-menu sub-category-tile-group bg-powder-blue">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="row">
//-//       <div class="col-6 col-md-3">
//-//         <a href="/help/template-support">
//-//           <div class="tile-item tile-thumb-label">
//-//             <div class="tile-img">
//-//               <img src="https://www.avery.com/dA/175db5378b/template_support.svg?language_id=1" alt="" class="img-fluid">
//-//             </div>
//-//             <div class="tile-text tile-item-label">
//-//               <p class="text-truncate-multiline"> Template Support </p>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//       <div class="col-6 col-md-3">
//-//         <a href="/help/order-support">
//-//           <div class="tile-item tile-thumb-label">
//-//             <div class="tile-img">
//-//               <img src="https://img.avery.com/image/upload/v1501801921/web/help/icon-help-order-support.svg" alt="" class="img-fluid">
//-//             </div>
//-//             <div class="tile-text tile-item-label">
//-//               <p class="text-truncate-multiline"> Order Support </p>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//       <div class="col-6 col-md-3">
//-//         <a href="/help/product-support/">
//-//           <div class="tile-item tile-thumb-label">
//-//             <div class="tile-img">
//-//               <img src="https://www.avery.com/dA/89c4cd6b5d/product_support.svg?language_id=1" alt="" class="img-fluid">
//-//             </div>
//-//             <div class="tile-text tile-item-label">
//-//               <p class="text-truncate-multiline"> Product Support </p>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//       <div class="col-6 col-md-3">
//-//         <a href="/help/account-support/">
//-//           <div class="tile-item tile-thumb-label">
//-//             <div class="tile-img">
//-//               <img src="https://www.avery.com/dA/47ec78257d/my_account_support.svg?language_id=1" alt="" class="img-fluid">
//-//             </div>
//-//             <div class="tile-text tile-item-label">
//-//               <p class="text-truncate-multiline"> Account Support </p>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
// styleguide tiles.sub-category-tile-group-menu

// FIXME: Deprecated - Remove `.sub-category-tile-group-menu` and all its nested styles once verified not/no longer used.
.sub-category-tile-group-menu {
  .container {
    max-width: 800px;
  }

  .tile-item {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .tile-img {
    padding: rem(20) rem(30);

    img {
      height: 80px;
    }
  }
}

/* ==========================================================================
   UTILITY - TILE ON HOVER - ACTION BUTTON
   ========================================================================== */

//-// Utility - Tile On Hover - Action Button
//-//
//-// A tile utility class that makes the button into the red Action button when mouse is hovered over the tile.
//-//
//-// NOTE: To use, add the `.tile-on-action-btn` class as sibiling to the `.tile-item` class.
//-//
//-// markup:
//-// <div class="container container-fluid-sm-down">
//-//   <div class="row">
//-//     <div class="col-12 col-md-4">
//-//       <a class="text-decoration-none" href="">
//-//         <div class="tile-item tile-info-description-img-spaced tile-weprint-img-spaced tile-on-action-btn tile-cropped-col-3">
//-//           <div class="tile-img tile-img-cursor">
//-//             <picture>
//-//               <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_09.jpg" media="(max-width: 768px)">
//-//               <source srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" media="(min-width: 769px)">
//-//               <img class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_10.jpg" alt="">
//-//             </picture>
//-//           </div>
//-//           <div class="tile-block">
//-//             <div class="tile-text text-main-long">
//-//               <p class="text-truncate">Rectangle Labels</p>
//-//             </div>
//-//             <div class="tile-text text-details">
//-//               <p class="text-truncate-multiline">Make your products, promotions and personal events stand out in a crowd.</p>
//-//             </div>
//-//             <div class="tile-text tile-action">
//-//               <div class="row align-items-center">
//-//                 <div class="col-12 col-md-6">
//-//                   <span class="font-italic">Starting at only &nbsp;</span>
//-//                   <strong>$7.00</strong>
//-//                 </div>
//-//                 <div class="col-12 col-md-6">
//-//                   <button class="btn btn-avry">Start Now</button>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </a>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.utility-on-action-btn

// FIXME: Deprecated - Remove `.tile-on-action-btn` and all its nested styles once verified not/no longer used.
.tile-on-action-btn {
  &:hover {
    .btn.btn-avry {
      @include btn-action(#fdf3f3, $main-white, #c5001d, #99001d);
    }
  }
}

/* ==========================================================================
   SUB-CATEGORY TILE GROUP - FILTERS SUMMARY
   ========================================================================== */

// Sub-Category Tile Group - Filters Summary
//
// A filter summary button set for any tile group that needs it.
//
// markup:
// <section class="sub-category-tile-group-filters-summary bg-bold-gray p-2">
//   <div class="container container-fluid-sm-down">
//     <div class="row">
//       <div class="col-12">
//         <div class="filter-summary-set">
//           <button class="btn btn-filter-summary btn-filter-summary-no-close">Address &amp; Shipping Labels</button>
//           <button class="btn btn-filter-summary btn-filter-summary-no-close">Beverage Labels</button>
//           <button class="btn btn-filter-summary btn-filter-summary-no-close">Food Labels</button>
//           <button class="btn btn-filter-summary btn-filter-summary-no-close">Health &amp; Beauty Labels</button>
//           <button class="btn btn-filter-summary btn-filter-summary-no-close">Personal Labels</button>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
//
// styleguide tiles.sub-category-tile-group-filters-summary
.sub-category-tile-group-filters-summary {
  &.animated {
    min-height: 60px;
    opacity: 0.05;
  }
  &.animated.open {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
}
