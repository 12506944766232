/* ==========================================================================
   TILE NEWS ITEM
   ========================================================================== */

//-// News Item Tile
//-//
//-// Tile used for News Article Results
//-//
//-// Markup:
//-// <div class="row">
//-//   <div class="col-12 col-md-3">
//-//     <a class="text-decoration-none" href="https://accounts.avery.com/">
//-//       <div class="tile-item tile-news-result tile-cropped-col-4 tile-cropped-sm-down">
//-//         <div class="tile-img tile-img-cursor">
//-//           <picture>
//-//             <source srcset="https://lorempixel.com/200/200/food/" media="(max-width: 768px)">
//-//             <source srcset="https://lorempixel.com/600/600/food/" media="(min-width: 769px)">
//-//             <img class="img-fluid img-fluid-height" srcset="https://lorempixel.com/600/600/food/" alt="">
//-//           </picture>
//-//         </div><!-- /.flexible-img -->
//-//         <div class="tile-block">
//-//           <div class="tile-text text-main-long">
//-//             <p class="text-truncate-multiline">Easing the Transition to GHS Label Compliance-- Easing the Transition to GHS Label Compliance</p>
//-//           </div>
//-//           <div class="tile-text text-main-short">
//-//             <p class="text-truncate">November 21, 2016</p>
//-//           </div>
//-//           <div class="tile-text text-details">
//-//             <p class="text-truncate-multiline">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati vero quasi beatae sed repellendus alias, aspernatur illum neque cumque explicabo, delectus, similique fuga incidunt asperiores, nisi non! Quos, nobis, unde.</p>
//-//           </div>
//-//           <div class="tile-text text-link">
//-//             Read More
//-//           </div>
//-//         </div><!-- /.tile-block -->
//-//       </div>
//-//     </a>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.news-item

// FIXME: Deprecated - Remove `.tile-news-result` and all its nested styles once verified not/no longer used.
.tile-news-result {
  @include tile-thumb-label();

  // Custom tile cropped sizes for mobile
  //
  // TODO: revisit this in global tile-cropped-* level
  &[class*="tile-cropped"] {
    @include media-breakpoint-down(sm) {
      .tile-img {
        max-height: 168px;
        line-height: 168px;

        img {
          width: auto;
          max-height: 168px;
          padding: rem(10);
        }
      }
    }
  }

  .tile-text {
    $text-section-spacing: rem(6);

    font-size: rem(13);
    line-height: 1.5;

    &.text-main-long {
      $text-main-long-height: 40px;

      height: $text-main-long-height;
      margin-bottom: $text-section-spacing;

      .text-truncate-multiline {
        max-height: $text-main-long-height;
      }
    }

    &.text-main-short {
      min-height: 20px;
      margin-bottom: $text-section-spacing;
      text-transform: uppercase;
      color: $sub-gray;
    }

    &.text-details {
      $text-details-height: 60px;
      height: $text-details-height;
      margin-bottom: $text-section-spacing;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      .text-truncate-multiline {
        @include media-breakpoint-down(sm) {
          max-height: $text-details-height;
        }
      }
    }
  }

  .text-truncate, .text-truncate-multiline {
    text-align: left;
    padding: 0 rem(10) 0 0;
    margin: 0;
  }

  .tile-block {
    padding: rem(10);
    overflow: hidden;

    @include media-breakpoint-up(md) {
      padding: rem(10) rem(10) rem(20);
    }
  }
}

