// Typography
//
// styleguide typography

// Avery Form Partials
@import 'typography/typography_font_size';
@import 'typography/typography_headers';
@import 'typography/typography_line_height';
@import 'typography/typography_text_colors';
@import 'typography/typography_text_icons';
@import 'typography/typography_text_links';

/* ==========================================================================
   TEXT-UTILITIES
   ========================================================================== */

// Text Utilities
//
// Text Transform Classes not available with the current version of Bootstrap being used.
//
// .text-transform-none - A class that overrides text transform styles.
// .whte-spc-nrml - A class that overrides white-space.
//
// Markup:
// &nbsp;
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide typography.1

.text-transform-none {
  text-transform: none !important;
}

.whte-spc-nrml {
  white-space: normal !important;
}

// FIXME: will be deprecated. All font will be based 16px.
.revised-details {
  font-size: rem(13);
}

.text-crossed-out {
  text-decoration: line-through;
  color: $washed-gray;
}

.text-main-short {
  // TODO: Commented out 'sm' and up rule because there is no size for XS.
  // @include media-breakpoint-up(sm) {
  font-size: rem(26);
  // }
}

.text-main-long {
  font-size: rem(16);
  line-height: 1.2;
  // TODO: Use/Import actual bold font and not use 'font-weight'.
  font-weight: 600;
}

.text-details {
  // NOT Part of MVS
  // .detail-label {
  // TODO: Use/Import actual bold font and not use 'font-weight'.
  // font-weight: bold;
  // }

  .detail-content {
    font-size: rem(13);
    font-weight: 300;
    line-height: 1.5;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      white-space: normal;
    }
  }
}

// Truncate Text By Fade
.text-truncate-fade {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    width: 60%;
    height: 20px;
    text-align: right;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.98) 50%);
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

// Truncate Text Multiline Ellipsis
.text-truncate-multiline {
  overflow: hidden;
  position: relative;
  max-height: 3.6em;
  text-align: left;
  margin-right: -1em;
  padding-right: 1em;
}
.text-truncate-multiline:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}
.text-truncate-multiline:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

.text-nowrap {
  white-space: nowrap;
}

/* ==========================================================================
   Deprecated
   ========================================================================== */

// FIXME: deprecate, use BS4 bold `.font-weight-bold` class (https://getbootstrap.com/docs/4.1/utilities/text/#font-weight-and-italics)
.bold-text {
  font-weight: bold;
}
// FIXME: deprecate, use BS4 un-bold `.font-weight-normal` class (https://getbootstrap.com/docs/4.1/utilities/text/#font-weight-and-italics)
.unbold-text {
  font-weight: 300 !important;
}
// FIXME: deprecate, use flexbox and NOT this `.text-content-compact` class.
.text-content-compact {
  text-align: left;
  @include media-breakpoint-only(xs) {
    width: 75%;
  }
  @include media-breakpoint-only(sm) {
    width: 50%;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
  }
}
// FIXME: Deprecated, use the `fnt-sze-*` classes [https://dev-cms.avery.com/styleguide/section-typography.html#kssref-typography-font-size-utilities](https://dev-cms.avery.com/styleguide/section-typography.html#kssref-typography-font-size-utilities).
//
.font-size-16 {
  font-size: 16px !important;
}
// FIXME: deprecate this class and use a more general "text decoration none" class...
// LEARN MORE: https://getbootstrap.com/docs/4.3/utilities/text/#text-decoration
.link-no-underline {
  text-decoration: none !important;
}
