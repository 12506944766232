@use "sass:math";

/* ==========================================================================
   GLOBAL ACCORDION
   ========================================================================== */

// Global Accordion
//
// Common Accordion Header. Commonly seen in dotCMS page(s).
//
// NOTE: The Global Accordion is styled by the `.accordion-theme-1` utility class.
//
// markup:
// <div class="accordion-global-set accordion-avry accordion-theme-1 accordion-avry-bs">
//   <div class="container container-fluid-sm-down">
//     <header class="accordion-head top-divider d-none d-md-block">
//       <h4 class="accordion-header">Most Popular Questions</h4>
//     </header>
//     <div class="accordion-body">
//       <div class="card">
//         <div class="card-header" role="tab" id="headingOne">
//           <h5 class="mb-0">
//             <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
//               <span class="panel-head-text">Question 1 - Lorem labore amet aliquip esse velit adipisicing adipisicing deserunt deserunt. Do laboris consequat sint sint in ex.</span>
//               <span class="panel-icon"></span>
//             </a>
//           </h5>
//         </div>
//         <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
//           <div class="card-body">
//             Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
//           </div>
//         </div>
//       </div>
//       <div class="card">
//         <div class="card-header" role="tab" id="headingTwo">
//           <h5 class="mb-0">
//             <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
//               <span class="panel-head-text">Question 2</span>
//               <span class="panel-icon"></span>
//             </a>
//           </h5>
//         </div>
//         <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo">
//           <div class="card-body">
//             Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
//           </div>
//         </div>
//       </div>
//       <div class="card">
//         <div class="card-header" role="tab" id="headingThree">
//           <h5 class="mb-0">
//             <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
//               <span class="panel-head-text">Question 3</span>
//               <span class="panel-icon"></span>
//             </a>
//           </h5>
//         </div>
//         <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree">
//           <div class="card-body">
//             Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide accordion.global-set

.accordion-global-set {
  margin-bottom: rem(16);

  .accordion-header {
    margin: 0;
    padding: rem(24) 0;
  }

  .accordion-body {
    .card {
      border-radius: 0;
      border: 0;

      &:first-child {
        border-top: solid $divider-weight $divider-color;
      }
    }

    .card-header {
      background: none;
      border-radius: 0;
      border-top: none;
      border-bottom: solid $divider-weight $divider-color;
      border-left: none;
      border-right: none;
      padding: 0;

      // NOTE:
      // `& > button` is for Angular's <ng-bootstrap>
      // `h5 > a` is for general bootstrap
      & > button,
      h5 > a {
        display: block;
        padding: ($card-spacer-y + rem(10)) $card-spacer-x;
        line-height: 1.3;

        @include media-breakpoint-down(sm) {
          padding: ($card-spacer-y + rem(10)) $card-spacer-x ($card-spacer-y + rem(10)) 0;
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .card-body {
      border-bottom: solid $divider-weight $divider-color;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

// Global Accordion Theme - Theme 1
.accordion-theme-1 {
  &,
  & .collapsed {
    .accordion-body {
      .card-header {
        // NOTE:
        // `& > button` is for <ng-bootstrap>
        // `h5 > a` is for general bootstrap
        & > button,
        h5 > a {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          padding: rem(14) $card-spacer-x;
          background-color: $brownish-gray;
        }
      }
    }

    .panel-head-text {
      width: 100%;
      margin: 0;
      text-align: left;
    }

    .panel-icon {
      $icon-container-size: 30px;
      $icon-dimensions: 10px;

      flex: 0 0 auto;
      width: $icon-container-size;
      height: $icon-container-size;
      margin: rem(4) rem(16) rem(4) 0;
      background-color: $primary-blue;
      border-radius: (math.div($icon-container-size, 2));

      &:before {
        height: $icon-dimensions;
        width: 2px;
        top: (math.div($icon-container-size, 3));
        left: (math.div($icon-container-size, 2)) - 1px;
        background-color: $main-white;
      }

      &:after {
        height: 2px;
        width: $icon-dimensions;
        top: (math.div($icon-container-size, 2)) - 1px;
        left: (math.div($icon-container-size, 3));
        background-color: $main-white;
      }
    }
  }
}
