//-// Section
//-//
//-// styleguide section

/* ==========================================================================
   COMMON SECTION

   NOTE: Common section is similar to 'SUB CATEGORY TILE GROUP' except this
        contains general information, while 'SUB CATEGORY TILE GROUP'
        contains tiles.
   ========================================================================== */

//-// Section - Content Group
//-//
//-// Common section is similar to [Sub Category Group Title](section-tiles.html#kssref-tiles-sub-category-tile-group) except this contains general information, while [Sub Category Group Title](section-tiles.html#kssref-tiles-sub-category-tile-group) contains tiles.
//-//
//-// markup:
//-// <div class="section-content-group bg-light-gray">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="row">
//-//       <div class="col-12">
//-//         <div class="description-block-full" style="background-color: #e7ddff">
//-//           <div class="description-block-img-bg text-center text-md-left">
//-//             <picture>
//-//               <source srcset="https://stg-cms.avery.com/static-ui/weprint-2/assets/img/landing-description-block-full-bg.jpg" media="(min-width: 768px) and (max-width: 992px)">
//-//               <source srcset="https://stg-cms.avery.com/static-ui/weprint-2/assets/img/landing-description-block-full-bg.jpg" media="(min-width: 993px)">
//-//               <img class="d-none d-md-block" src="https://stg-cms.avery.com/static-ui/weprint-2/assets/img/landing-description-block-full-bg.jpg">
//-//             </picture>
//-//             <div class="row">
//-//               <div class="col-12 col-md-6 offset-md-6">
//-//                 <div class="description-block-img d-md-none">
//-//                   <img src="https://stg-cms.avery.com/static-ui/weprint-2/assets/img/landing-description-block-full-bg.jpg" alt="">
//-//                 </div>
//-//                 <div class="description-block-text px-3 px-md-0">
//-//                   <div class="description-text description-text-details text-main-long">
//-//                     <p class="text-truncate h4">We're here to help</p>
//-//                   </div>
//-//                   <div class="description-text description-text-details">
//-//                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
//-//                       dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales.</p>
//-//                   </div>
//-//                   <div>
//-//                     <button class="btn btn-avry mb-3">Call (800) 462-8379</button>
//-//                   </div>
//-//                   <div>
//-//                     <button class="btn btn-avry mb-3">Live Chat</button>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//       <div class="col-12 my-4">
//-//         <a href="">
//-//           <img class="img-fluid" src="https://stg-cms.avery.com/static-ui/weprint-2/assets/img/weprint-ad.png" />
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide section.section-content-group

// FIXME: Deprecated - Remove `.section-content-group` and all its nested styles once verified not/no longer used.
.section-content-group {
  padding: rem(40);
  text-align: center;
}

/* ==========================================================================
   COMMON SECTION - BG LIGHT POWDER BLUE

   NOTE: A common section that styles the background and top border
   ========================================================================== */

   // FIXME: Deprecated - Remove `.section-bg-light-powder-blue` and all its nested styles once verified not/no longer used.
.section-bg-light-powder-blue {
  background-color: $light-powder-blue;
  border-top: rem(5) solid $deep-powder-blue;
}
