/* ==========================================================================
   UI SWITCH - Specific Styles

   NOTE: This file modifies base 'ng2-ui-switch' Component Styles (https://www.npmjs.com/package/ng2-ui-switch)
   ========================================================================== */

.switch.switch-small.checked {
  background-color: $bright-green !important;
}

/*
   Switch
   ========================================================================== */
// TODO: Commented out for possibly later use

// .switch {
// background-color: yellow !important;

// &.disabled {
//   background-color: red !important;
// }
// }

// Switch Slider Custom Styles
//
// NOTE: Angular 2 UI Switch Module only has 3 sizes (small, medium, and large).
// TODO: Commented out for possibly later use

// .switch {
//   // Small
//   &.switch-small {
//     small {
//       width: 15px !important;
//       height: 15px !important;
//       top: 2px !important;
//       left: 2px !important;
//     }
//   }
//   &.switch-small.checked {
//     small {
//       left: 15px !important;
//     }
//   }

//   // Medium
//   &.switch-medium {
//     small {
//       width: 25px !important;
//       height: 25px !important;
//       top: 2px !important;
//       left: 3px !important;
//     }
//   }
//   &.switch-medium.checked {
//     small {
//       left: 22px !important;
//     }
//   }

//   // Large
//   &.switch-large {
//     small {
//       width: 35px !important;
//       height: 35px !important;
//       top: 2px !important;
//       left: 3px !important;
//     }
//   }
//   &.switch-large.checked {
//     small {
//       left: 28px !important;
//     }
//   }
// }

/*
   Switch Labels
   ========================================================================== */
.switch-label {
  display: inline-block;
  font-weight: bold;
  margin-left: rem(10);
  color: $sub-gray;
}

.disabled-label {
  color: $base-gray;
}
