/* ==========================================================================
   AVERY COLORS

   NOTE: The colors are imported here because colors are needed for Bootstrap 4 custom configuration.
   ========================================================================== */

@import "./avery/colors";

/* ==========================================================================
   BOOTSTRAP 4

   NOTE: This is the Bootstrap Variable Configuration file
   Per Bootstrap, Copy variables from `node_modules/bootstrap/scss/_variables.scss` file into
   `src/scss/bootstrap/_custom.scss` to override default values without modifying Bootstrap's source files.
   ========================================================================== */

@import "./bootstrap/custom";

// Ignore Bootstraps deprecated warnings
$ignoreWarning: true;

// Imports a 'clean' version of bootstrap from node_modules
// @import "./../node_modules/bootstrap/scss/bootstrap";

// Imports a 'cherry picked' version of Bootstrap from this local directory
//
// NOTE: make sure the contents of this partial matches "node_modules/bootstrap/scss/bootstrap", especially during bootstrap upgrades.
@import "./bootstrap/bootstrap-includes";

/* ==========================================================================
   MAIN AVERY STYLES
   ========================================================================== */

// Imports Functions
@import "./avery/functions";

// Imports Global Variables
@import "./avery/variables";

// Imports Mixins
@import "./avery/mixins";

// Imports Avery.com Global Styles
@import "./avery/global";
