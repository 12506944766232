/* ==========================================================================
   AVERY SIGN-IN MODAL - Specific Styles
   ========================================================================== */

.modal.modal-avry.sign-in-modal {
  .modal-dialog {
    width: 100%;
    min-width: 0;
    max-width: none;

    @include media-breakpoint-only(sm) {
      max-width: 576px;
    }

    @include media-breakpoint-only(md) {
      max-width: 800px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }
  }
}

