/* ==========================================================================
   TEMPLATES INFO CHECK LIST STYLES
   ========================================================================== */

ul.templates-info-check-list {
  @include set-content-width-mobile();
  padding: 0;
  list-style: none;
  line-height: 2;

  li {
    display: flex;
    margin: rem(8) 0;
  }

  .fa.fa-check-circle-o {
    font-size: rem(24);
    margin-right: rem(16);
  }
}

/* ==========================================================================
   TEMPLATES THUMBNAIL IMAGES
   ========================================================================== */

// FIXME: deprecate, determine if this class is still used in dotCMS.
// .template-thumbnail-img {
//   width: 100%;
//   max-width: 100%;
//   max-height: 100%;
//   padding: rem(10);
// }

/* ==========================================================================
   TEMPLATES WE PRINT COMMON
   ========================================================================== */

.avery-we-print-logo-container {
  margin-bottom: rem(20);

  // NOTE: The WePrint logo is a bit off, added left position to offset and make it look more 'centered'.
  @include media-breakpoint-up(md) {
    position: relative;
    left: rem(10);
  }
}

