/* ==========================================================================
   LINE HEIGHT
   ========================================================================== */

// Line Height
//
// Utility to add/modify line-height a specific. Can be applied to any text styles.
//
// .lh-normal - Sets line height to **normal**
// .lh-0-8 - Sets line height to **0.8**
// .lh-0-9 - Sets line height to **0.9**
// .lh-1-0 - Sets line height to **1**
// .lh-1-1 - Sets line height to **1.1**
// .lh-1-2 - Sets line height to **1.2**
// .lh-1-3 - Sets line height to **1.3**
// .lh-1-4 - Sets line height to **1.4**
// .lh-1-7 - Sets line height to **1.7**
// .lh-1-8 - Sets line height to **1.8**
//
// Markup:
// <div class="{{modifier_class}}">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque viverra tristique massa et ultrices. Ut vitae leo ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque ut porta enim, ac suscipit arcu. Quisque ultricies at turpis eget feugiat. Curabitur vel porttitor ex. Suspendisse vel placerat erat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In vestibulum diam eu velit lacinia, a varius nulla aliquam. Mauris sit amet ex quis dui euismod rutrum eget nec nibh. Etiam suscipit metus nec eros hendrerit consequat. Curabitur risus lorem, rhoncus ut metus eget, accumsan convallis mi.</div>
//
// hidemarkupsnippet: true
//
// Styleguide typography.a-line-height

.lh-normal {
  line-height: normal !important;
}
.lh-0-8 {
  line-height: 0.8 !important;
}
.lh-0-9 {
  line-height: 0.9 !important;
}
.lh-1-0 {
  line-height: 1 !important;
}
.lh-1-1 {
  line-height: 1.1 !important;
}
.lh-1-2 {
  line-height: 1.2 !important;
}
.lh-1-3 {
  line-height: 1.3 !important;
}
.lh-1-4 {
  line-height: 1.4 !important;
}
.lh-1-7 {
  line-height: 1.7 !important;
}
.lh-1-8 {
  line-height: 1.8 !important;
}
// TODO: Determine if line-height 1.5 is needed... 1.5 is already default.
// .lh-1-5 {
//   line-height: 1.5;
// }
