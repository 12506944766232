// This file contains hot fixes and browser bug workarounds.

/* ==========================================================================
   FLEX BOX - REVERSE DIRECTION FIX

   NOTE: There is a bug with `flex-direction: column-reverse` specifically on Safari browsers.

   MORE_INFO: https://stackoverflow.com/questions/29986668/flex-wrap-not-working-as-expected-in-safari
   ========================================================================== */

.flex-column-reverse {
  > [class*="col-"] {
    flex: 1;
  }
}
