@use "sass:math";

/* ==========================================================================
   MY ACCOUNT - COMMON HEADER/HEADER AREA STYLES
   ========================================================================== */

#my-account {
  .top-header-container {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .header-content {
    @include section-container-spacing();

    .btn.btn-avry {
      @include media-breakpoint-down(sm) {
        min-width: rem(150);
      }
    }
  }
}

#upload-project {
  background-color: $washed-blue;
  margin: 0 0 rem(math.div($grid-gutter-width, 2));

  .btn.btn-avry {
    @include media-breakpoint-up(md) {
      min-width: 200px;
    }
  }
}
