// Tooltips
//
// Tooltip parent elements must have the `.tooltip--control` class.
//
// Markup:
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip
//     <div class="tooltip">
//         <div class="form-group mb-0">
//           <label for="inputTemp">Tooltip centered both vertically and horizontally</label>
//           <input class="mb-2 form-control" id="inputTemp" type="email" placeholder="form in tooltip" />
//           <button class="cta cta--primary cta--blue" type="button">Submit</button>
//       </div>
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--right
//     <div class="tooltip tooltip--right">
//         Tooltip to the right
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--left
//     <div class="tooltip tooltip--left">
//         Tooltip to the left
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--top
//     <div class="tooltip tooltip--top">
//         Tooltip to the top
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--bottom
//     <div class="tooltip tooltip--bottom">
//         Tooltip to the bottom
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     Active
//     <div class="tooltip tooltip--left active">
//         Active Tooltip
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--blue
//     <div class="tooltip tooltip--right active tooltip--blue">
//        a Blue Tooltip Lorum ipsum dolor sit mari telsin a minata juk.
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--sm
//     <div class="tooltip tooltip--left active tooltip--sm">
//         Smaller Tooltip
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--red
//     <div class="tooltip tooltip--right active tooltip--red">
//         Red Tooltip
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--lg
//     <div class="tooltip tooltip--left active tooltip--lg">
//         larger Tooltip
//     </div>
//   </div>
// </div>
// <div class="mb-3 text-center">
//   <div class="d-inline text-link tooltip__control">
//     tooltip--white
//     <div class="tooltip tooltip--right tooltip--white active">
//         <div class="form-group mb-0">
//           <label for="inputTemp" class="sr-only">Tooltip centered both vertically and horizontally</label>
//           <input class="mb-2 form-control" id="inputTemp" type="text" placeholder="form in tooltip" />
//           <button class="cta cta--primary cta--blue w-100" type="button">Submit Apporval</button>
//         </div>
//     </div>
//   </div>
// </div>
//
// Styleguide Tooltips

.tooltip__control {
  position: relative;
  // un-comment only when testing
  // outline: 1px solid #ccc;

  &:hover > .tooltip {
    display: flex;
  }
  > .tooltip.active {
    display: flex;
  }
}

.tooltip {
  display: none;
  border-radius: 3px;
  font-size: 13px;
  justify-content: center;
  line-height: 1.3;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 888;
  max-width: 304px;
  inline-size: max-content;
  background-color: $charcoal-black;
  color: $main-white;
  padding: 9px 12px;

  &::after {
    content: '';
    height: 0;
    position: absolute;
    width: 0;
  }
  .form-control {
    color: $main-black;
  }

  &.tooltip--white::before {
    content: '';
    height: 0;
    position: absolute;
    width: 0;
  }

  &.tooltip--blue {
    background-color: $primary-blue;
    color: $main-white;

    [data-avry-mode='industrial'] & {
      background-color: $industrial-blue;
    }
  }
  &.tooltip--red {
    background-color: $bright-red;
    color: $main-white;
  }
  &.tooltip--white {
    background-color: $main-white;
    border: 1px solid $gray-medium;
    border-radius: 5px;
    color: $base-dark;
  }
}

// TOP ****************************
.tooltip--top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 10px;
  right: auto;
  top: auto;
  transform: translate(-50%, 0);

  &::after {
    border-bottom: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid $charcoal-black;
    bottom: -9px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &.tooltip--lg {
    margin-bottom: 13px;

    &::after {
      border-bottom: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 14px solid $charcoal-black;
      bottom: -13px;
    }
  }

  &.tooltip--sm {
    margin-bottom: 6px;

    &::after {
      border-bottom: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid $charcoal-black;
      bottom: -6px;
    }
  }

  &.tooltip--blue::after {
    border-top-color: $primary-blue;
    border-left-color: transparent;

    [data-avry-mode='industrial'] & {
      border-top-color: $industrial-blue;
    }
  }
  &.tooltip--red::after {
    border-top-color: $bright-red;
    border-left-color: transparent;
  }

  &.tooltip--white {
    margin-bottom: 12px;

    &::after {
      border-top-color: $main-white;
      border-left-color: transparent;
      bottom: -10px;
    }

    &::before {
      border-bottom: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 12px solid $gray-medium;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &.tooltip--lg {
      margin-bottom: 15px;

      &::after {
        bottom: -14px;
      }

      &::before {
        border-bottom: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 15px solid $gray-medium;
        bottom: -15px;
      }
    }

    &.tooltip--sm {
      margin-bottom: 8px;

      &::after {
        bottom: -7px;
      }

      &::before {
        border-bottom: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid $gray-medium;
        bottom: -9px;
      }
    }
  }
}

// RIGHT **************************
.tooltip--right {
  justify-content: left;
  left: 100%;
  margin-left: 6px;
  right: auto;
  transform: translate(0, -50%);

  &::after {
    border-bottom: 8px solid transparent;
    border-left: 0;
    border-right: 10px solid $charcoal-black;
    border-top: 8px solid transparent;
    left: -9px;
    top: 50%;
    transform: translate(0, -50%);
  }
  &.tooltip--lg {
    margin-left: 10px;

    &::after {
      border-bottom: 12px solid transparent;
      border-left: 0;
      border-right: 14px solid $charcoal-black;
      border-top: 12px solid transparent;
      left: -13px;
    }
  }

  &.tooltip--sm {
    margin-left: 3px;

    &::after {
      border-bottom: 5px solid transparent;
      border-left: 0;
      border-right: 7px solid $charcoal-black;
      border-top: 5px solid transparent;
      left: -6px;
    }
  }

  &.tooltip--blue::after {
    border-right-color: $primary-blue;

    [data-avry-mode='industrial'] & {
      border-right-color: $industrial-blue;
    }
  }
  &.tooltip--red::after {
    border-right-color: $bright-red;
  }

  &.tooltip--white {
    margin-left: 8px;

    &::after {
      border-right-color: $main-white;
      border-left-color: transparent;
      left: -10px;
    }

    &::before {
      border-bottom: 9px solid transparent;
      border-left: 0;
      border-right: 12px solid $gray-medium;
      border-top: 9px solid transparent;
      left: -12px;
      top: 50%;
      transform: translate(0, -50%);
    }
    &.tooltip--lg {
      margin-left: 12px;

      &::after {
        left: -14px;
      }

      &::before {
        border-bottom: 13px solid transparent;
        border-left: 0;
        border-right: 15px solid $gray-medium;
        border-top: 13px solid transparent;
        left: -15px;
      }
    }
    &.tooltip--sm {
      margin-left: 5px;

      &::after {
        left: -7px;
      }

      &::before {
        border-bottom: 5px solid transparent;
        border-left: 0;
        border-right: 8px solid $gray-medium;
        border-top: 5px solid transparent;
        left: -9px;
      }
    }
  }
}

// Bottom *************************
.tooltip--bottom {
  left: 50%;
  margin-right: 0;
  margin-top: 9px;
  right: auto;
  top: 100%;
  transform: translate(-50%, 0);

  &::after {
    border-bottom: 10px solid $charcoal-black;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 0;
    left: 50%;
    top: -9px;
    transform: translate(-50%, 0);
  }
  &.tooltip--lg {
    margin-top: 13px;

    &::after {
      border-bottom: 14px solid $charcoal-black;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 0;
      top: -13px;
    }
  }

  &.tooltip--sm {
    margin-top: 6px;

    &::after {
      border-bottom: 7px solid $charcoal-black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 0;
      top: -6px;
    }
  }

  &.tooltip--blue::after {
    border-bottom-color: $primary-blue;
    border-left-color: transparent;

    [data-avry-mode='industrial'] & {
      border-bottom-color: $industrial-blue;
    }
  }

  &.tooltip--red::after {
    border-bottom-color: $bright-red;
    border-left-color: transparent;
  }

  &.tooltip--white {
    margin-top: 11px;

    &::after {
      border-bottom-color: $main-white;
      border-left-color: transparent;
      top: -10px;
    }

    &::before {
      border-bottom: 12px solid $gray-medium;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 0;
      top: -12px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &.tooltip--lg {
      margin-top: 15px;

      &::after {
        top: -14px;
      }

      &::before {
        border-bottom: 15px solid $gray-medium;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 0;
        top: -15px;
      }
    }

    &.tooltip--sm {
      margin-top: 8px;

      &::after {
        top: -7px;
      }

      &::before {
        border-bottom: 8px solid $gray-medium;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 0;
        top: -9px;
      }
    }
  }
}

// Left ***************************
.tooltip--left {
  flex-direction: row-reverse;
  justify-content: right;
  left: auto;
  margin-right: 10px;
  right: 100%;
  transform: translate(0, -50%);

  &::after {
    content: '';
    border-bottom: 8px solid transparent;
    border-left: 10px solid $charcoal-black;
    border-right: 0;
    border-top: 8px solid transparent;
    height: 0;
    right: -9px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
  }
  &.tooltip--lg {
    margin-right: 13px;

    &::after {
      border-bottom: 12px solid transparent;
      border-left: 14px solid $charcoal-black;
      border-right: 0;
      border-top: 12px solid transparent;
      right: -13px;
    }
  }

  &.tooltip--sm {
    margin-right: 6px;

    &::after {
      border-bottom: 5px solid transparent;
      border-left: 7px solid $charcoal-black;
      border-right: 0;
      border-top: 5px solid transparent;
      right: -6px;
    }
  }

  &.tooltip--blue::after {
    border-left-color: $primary-blue;

    [data-avry-mode='industrial'] & {
      border-left-color: $industrial-blue;
    }
  }
  &.tooltip--red::after {
    border-left-color: $bright-red;
  }

  &.tooltip--white {
    margin-right: 12px;

    &::after {
      border-left-color: $main-white;
      border-right-color: transparent;
      right: -10px;
    }

    &::before {
      border-bottom: 9px solid transparent;
      border-left: 12px solid $gray-medium;
      border-right: 0;
      border-top: 9px solid transparent;
      right: -12px;
      top: 50%;
      transform: translate(0, -50%);
    }
    &.tooltip--lg {
      margin-right: 15px;

      &::after {
        right: -14px;
      }

      &::before {
        border-bottom: 13px solid transparent;
        border-left: 15px solid $gray-medium;
        border-right: 0;
        border-top: 13px solid transparent;
        right: -15px;
      }
    }
    &.tooltip--sm {
      margin-right: 8px;

      &::after {
        right: -7px;
      }

      &::before {
        border-bottom: 5px solid transparent;
        border-left: 8px solid $gray-medium;
        border-right: 0;
        border-top: 5px solid transparent;
        right: -9px;
      }
    }
  }
}

.tooltip--lg {
  padding: 17px 16px;
  font-size: 14px;
}

.tooltip--sm {
  padding: 2px 6px;
  font-size: 12px;

  &.tooltip--white {
    border-radius: 3px;
  }
}
