/* ==========================================================================
   FILTERS ACCORDION - ACCORDION PANELS

   NOTE: Modify's Bootstrap's Accordion Styles to be specific to Avery's accordion style.
   ========================================================================== */

// Filters Accordion Implementation
//
// **NOTE:** The implementation below is utilizing bootstrap's accordion functionality. This Modify's Bootstrap's Accordion Styles to be specific to Avery's accordion style. The Accordion(Collapse) is a bootstrap component with functionality provided by jQuery. But in the Angular app, *ng-bootstrap* is the library used for the Accordion. Angular's *ng-boostrap* does not utilize the `.collapse` class.
//
// **IMPORTANT:** The Angular App (ng-bootstrap) utilizes its own structure. Also NOTE that in the Angular App, expand and collapse animations are not supported.
//
// **IMPORTANT:** Add 'accordion-avry-bs' to the root accordion container if the accordion is using jQuery. ***NOT*** needed if the accordion is using an Angular accordion implementation
//
// markup:
// <div class="accordion-avry accordion-aside accordion-avry-bs accordion-filter-set">
//   <div class="filter-panel-card card">
//     <div class="card-header" role="tab" id="headingOne">
//       <h5 class="mb-0">
//         <a data-toggle="collapse" data-parent="#collapsable-filters-set" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed">
//           <span class="card-header-link">
//             Type
//             <span class="panel-icon"></span>
//           </span>
//         </a>
//       </h5>
//     </div>
//     <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false">
//       <div class="card-body">
//         <div class="panel-filter-list-container">
//           <ul class="panel-filter-list">
//             <li>
//               <div class="form-check filter-checkbox">
//                 <input class="form-check-input" type="checkbox">
//                 <label class="form-check-label" for="<id of corresponding input goes here>">Desk Style</label>
//               </div>
//             </li>
//             <li>
//               <div class="form-check filter-checkbox">
//                 <input class="form-check-input" type="checkbox">
//                 <label class="form-check-label" for="<id of corresponding input goes here>">Pen Style</label>
//               </div>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div class="filter-panel-card card">
//     <div class="card-header" role="tab" id="headingTwo">
//       <h5 class="mb-0">
//         <a data-toggle="collapse" data-parent="#collapsable-filters-set" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" class="collapsed">
//           <span class="card-header-link">
//             Color
//             <span class="panel-icon"></span>
//           </span>
//         </a>
//       </h5>
//     </div>
//     <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false">
//       <div class="card-body">
//         <div class="panel-filter-list-container">
//           <ul class="panel-filter-list">
//             <li>
//               <div class="form-check filter-checkbox">
//                 <input class="form-check-input" type="checkbox">
//                 <label class="form-check-label" for="<id of corresponding input goes here>">Permanent</label>
//               </div>
//             </li>
//             <li>
//               <div class="form-check filter-checkbox">
//                 <input class="form-check-input" type="checkbox">
//                 <label class="form-check-label" for="<id of corresponding input goes here>">Dry Erase</label>
//               </div>
//             </li>
//             <li>
//               <div class="form-check filter-checkbox">
//                 <input class="form-check-input" type="checkbox">
//                 <label class="form-check-label" for="<id of corresponding input goes here>">Washable</label>
//               </div>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// styleguide filters.accordion

.accordion-filter-set .card {
  // Panel Tab Section
  .card-header {
    border-radius: rem(5);
    margin-bottom: $card-bottom-margin;
  }

  // Panel Content Section
  .card-body {
    padding: rem(10);
    margin-top: -$card-bottom-margin;

    ul.panel-filter-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin: rem(10) 0;

        @include media-breakpoint-down(sm) {
          input[type='checkbox'] {
            transform: scale(1.5);
          }
        }
      }
    }

    // Panel Content List Truncate with Scroll
    .panel-filter-list-truncate {
      position: relative;

      ul.panel-filter-list {
        max-height: 585px;
        width: 100%;
        padding-bottom: rem(20);
        overflow-x: hidden;
        overflow-y: scroll;
      }

      &:after {
        @include gradient-y(rgba($main-white, 0.25), $main-white, 0%, 100%);
        content: '';
        width: calc(100% - 17px);
        height: rem(30);
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  // Panel Action Button
  .panel-action-button {
    text-align: center;
    padding: rem(30) 0;
  }

  label {
    margin-left: rem(10);
    line-height: 2;
    color: $sub-gray;
  }
}

// Accordion Header Disabled State
//
// Displays Header State when Disabled
//
// .disabled-panel - Disabled Panel State
//
// markup:
// <div class="accordion-avry accordion-aside accordion-filter-set">
//   <div class="filter-panel-card card {{modifier_class}}">
//     <div class="card-header">
//       <h5 class="mb-0">
//         <a aria-expanded="false" aria-controls="collapseOne" class="collapsed">
//           <span class="card-header-link">
//             Type
//             <span class="panel-icon"></span>
//           </span>
//         </a>
//       </h5>
//     </div>
//   </div>
// </div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide filters.accordion-state

.accordion-filter-set .card.disabled-panel {
  .card-header {
    background-color: $faint-gray;

    .card-header-link {
      color: $washed-gray;
    }

    .panel-icon:before,
    .panel-icon:after {
      background: $washed-gray;
    }
  }
}

/*
   ACCORDION HEADER NG-BOOTSTRAP - Class added inside 'ngbPanelTitle' in ng-bootstrap
   ========================================================================== */

.accordion-filter-set .card-header-link {
  display: block;
  padding: rem(12);
  border-radius: rem(5);
  background-color: $light-gray;
  text-decoration: none;
}

.accordion-filter-set .card-switch-panel {
  display: none;
}

/* ==========================================================================
   FILTER CATEGORIES
   ========================================================================== */

.filter-categories .card {
  .card-body {
    padding: 0;
  }
}

/* ==========================================================================
   FILTER & FORM CHECK - CHECKBOXES & RADIO
   ========================================================================== */

.accordion-filter-set .filter-checkbox,
.accordion-filter-set .form-check {
  padding-bottom: rem(8);

  input[type='checkbox']:disabled,
  input[type='checkbox']:disabled + label,
  input[type='radio']:disabled,
  input[type='radio']:disabled + label {
    opacity: 0.35;
  }

  input[type='checkbox'],
  input[type='radio'] {
    font-weight: normal;
  }

  input[type='checkbox']:checked,
  input[type='radio']:checked {
    min-width: rem(12);

    + label {
      font-weight: bold;
    }
  }
}
