@use "sass:math";

/* ==========================================================================
   MIXINS
   ========================================================================== */

// Section Container Spacing
//
// NOTE: This sets the left/right padding of a given section. Usually for 1 column page layouts (no sidebar). Examples used in Common Header in My Account Pages.
@mixin section-container-spacing() {
  padding: rem(math.div($grid-gutter-width, 2)) rem($grid-gutter-width);
}

// Truncated/Limited Text
@mixin truncate-text-limit($max-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: $max-height;
}

// Bottom Border for Cards
@mixin card-bottom-border() {
  border: 0;
  border-radius: 0;
  border-bottom: rem(1) solid $divider-color;
}

// Button Box Shadow (on Active State)
@mixin on-button-active($fill, $shadow, $border:"") {
  background-color: $fill;
  box-shadow: inset rem(4) rem(3) 0 $shadow;

  // Border is an optional argument.
  @if $border != "" {
    border-color: $border;
  }
}

// Global Dropshadow
@mixin global-drop-shadow($color: $bold-gray, $opacity: 0.40) {
  box-shadow: rem(1) rem(1) rem(10) rgba($color, $opacity);
}

// Action Buttons
@mixin btn-action($color-fill, $color-text, $color-hover, $color-active) {
  background-color: $color-fill;
  border: rem(1) solid $color-fill;
  color: $color-text;

  &:not(.disabled):active:focus, &:not(.disabled):active {
    @include on-button-active($color-hover, $color-active);
  }

  &:hover {
    background-color: $color-hover;
    border: rem(1) solid $color-hover;
    color: $main-white;
  }
}

// Button Text Move (on Active State)
//
// NOTE: $btn-padding-*[-size] are bootstrap variables.
@mixin on-button-text-active($size:"") {
  $padding-x: $input-btn-padding-x;
  $padding-y: $input-btn-padding-y;

  @if $size == "sm" {
    $padding-x: $input-btn-padding-x-sm;
    $padding-y: $input-btn-padding-y-sm;
  } @else if $size == "lg" {
    $padding-x: $input-btn-padding-x-lg;
    $padding-y: $input-btn-padding-y-lg;
  }

  // NOTE: had to convert to pixels 'px()' because rem values were giving spacing issues on Safari.
  $padding-top: px($padding-y + 0.1rem);
  $padding-right: px($padding-x - rem(1));
  $padding-left: px($padding-x + rem(1));
  $padding-bottom: px($padding-y - 0.1rem);

  padding: $padding-top $padding-right $padding-bottom $padding-left;
}

// Button Text Move For Circle Buttons (on Active State)
//
// NOTE: Arguments are pixel values
@mixin on-button-text-active-circle($padding-top, $side-padding, $padding-bottom) {
  $padding-circle-side: $side-padding;
  $padding-right: $padding-circle-side - 1px;
  $padding-left: $padding-circle-side + 1px;
  padding: rem($padding-top) rem($padding-right) rem($padding-bottom) rem($padding-left);
}

// Divider
//
// NOTE: Base Divider styles
@mixin divider-base() {
  padding-left: 0;
  padding-right: 0;

  hr {
    margin: rem($grid-gutter-width);

    @include media-breakpoint-up(md) {
      margin: rem(math.div($grid-gutter-width, 2));
    }
  }
}
