/* ==========================================================================
   FONT SIZE
   ========================================================================== */

// Font Size
//
// Utility to change font size.
//
// **NOTE:** Specific Font sizes will be created on a "need-to" basis.
//
// .fnt-sze-12 - Sets the font size to **12px**
// .fnt-sze-14 - Sets the font size to **14px**
// .fnt-sze-16 - Sets the font size to **16px**
// .fnt-sze-18 - Sets the font size to **18px**
// .fnt-sze-20 - Sets the font size to **20px**
// .fnt-sze-24 - Sets the font size to **24px**
//
// Markup:
// <div class="{{modifier_class}}">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
//
// hidemarkupsnippet: true
//
// Styleguide typography.a-font-size

.fnt-sze-12 {
  font-size: rem(12) !important;
}
.fnt-sze-14 {
  font-size: rem(14) !important;
}
.fnt-sze-16 {
  font-size: rem(16) !important;
}
.fnt-sze-18 {
  font-size: rem(18) !important;
}
.fnt-sze-20 {
  font-size: rem(20) !important;
}
.fnt-sze-22 {
  font-size: rem(22) !important;
}
.fnt-sze-24 {
  font-size: rem(24) !important;
}
