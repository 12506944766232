/*
   Horizontal Scroll For XS and SM

   NOTE: For Thumbnail gallery. The method 'productPage.makeMobileSlideThumbnails()' is needed to create dynamic thumnail container widths.
   ========================================================================== */
@include media-breakpoint-down(sm) {
  .thumbnails-group {
    position: relative;
    text-align: left;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; // Shows scroll bar for mobile (iphone).

    // Suggested shadow
    // &:before,
    // &:after {
    //   content: "";
    //   height: 74px;
    //   width: 15px;
    //   position: absolute;
    //   top: 0;
    //   z-index: 10;
    //   background: red;
    // }

    // &:before {
    //   left: 0;
    //   @include background(linear-gradient(to right, rgba(235,235,235,1) 0%, rgba(255,137,139,0) 100%));
    // }

    // &:after {
    //   right: 0;
    //   @include background(linear-gradient(to right, rgba(255,137,139,0) 0%, rgba(235,235,235,1) 100%));
    // }

    .thumbnail-track {
      position: absolute;
      top: 0;
      left: 0;

      .col-thumb {
        max-width: none;
        flex: none;
      }
    }
  }
}
