/* ==========================================================================
   ACCORDION ASIDE - COMMON STYLES (ACCORDION IN A SIDEBAR)
   ========================================================================== */

.accordion-avry.accordion-aside .card {
  max-width: 300px;
  border: 0;
  margin-bottom: 0;

  // Header Section
  .card-header {
    border: 0;
    padding: 0;
    background: none;

    a,
    button {
      width: 100%;
      padding: 0;
      border: none;
      text-align: left;
      color: $base-dark;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  // A global <ng-bootstrap> accordion panel with switch
  .bg-switch-panel,
  .bg-radio-panel {
    height: 0;
  }
}

/* ==========================================================================
   ACCORDION ASIDE - ROOT LEVEL (ACCORDION IN A SIDEBAR)

   Root Level means the base accordion in a side bar (aside).

   NOTE: Modify's Bootstrap's Accordion Styles to be specific to Avery's accordion style.
   SEEN ON: "Categories" Sidebar Filter Accordion, Help Page(s) Side Bar Accordion
   ========================================================================== */

.accordion-avry.accordion-aside-root {
  .card {
    // Header Section
    .card-header {
      .panel-icon {
        right: rem(12);
      }
    }

    // Content Section
    // FIXME: These styles are used in dotCMS. Remove 'card-block' when dotCMS is completely using Bootstrap 4 production.
    .card-body,
    .card-block {
      padding: 0;
    }
  }
}

/* ==========================================================================
   ACCORDION ASIDE - HEADER AND PANEL CONTENT
   ========================================================================== */

.accordion-aside-header,
.accordion-aside-root-header {
  padding-bottom: rem(16);
  margin-bottom: rem(20);
  border-bottom: solid $divider-weight $divider-color;
}

.accordion-aside-root-content {
  list-style: none;
  margin: 0 0 rem(20);
  padding: 0;

  li {
    font-size: rem(15);
    line-height: 1.8;
    cursor: pointer;

    &.active {
      // TODO: use actual bold font.
      font-weight: bold;
    }

    &:hover {
      color: $hyper-blue;
      cursor: pointer;
    }
  }
}
