/* ==========================================================================
   INDUSTRIAL BUTTONS - PRIMARY
   ========================================================================== */

//-// Industrial Buttons
//-//
//-// Base Industrial Button Styles.
//-//
//-// **NOTE:** These styles will potentially replace the current Avery.com button styles.
//-//
//-// styleguide industrial-buttons
// FIXME: Remove Industrial specific Buttons

//-// Primary - Default (Outline - Gray)
//-//
//-// An Industrial version of the `.btn-avery`. Avery.com may use this button style after Industrial 2.0 launch.
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.0-primary-button-gray-outline

// FIXME: Deprecated - Remove `.btn-avry` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry {
  // Button Padding Space
  $btn-avry-padding-x: rem(17);
  $btn-avry-padding-y: rem(8);
  $btn-avry-padding-x-lg: rem(26);
  $btn-avry-padding-y-lg: rem(9);
  $btn-avry-padding-x-sm: rem(26);
  $btn-avry-padding-y-sm: rem(6);

  // Button Text
  $btn-avry-default-font-size: 14px; // NOTE: Has to be pixel because the BS4 mixin requires that format.
  $btn-avry-default-line-height: 1.14;
  $btn-avry-default-letter-spacing: rem(0.58);
  $btn-avry-lg-font-size: 16px; // NOTE: Has to be pixel because the BS4 mixin requires that format.
  $btn-avry-lg-line-height: 1.25;
  $btn-avry-lg-letter-spacing: rem(0.67);
  $btn-avry-sm-font-size: 12px; // NOTE: Has to be pixel because the BS4 mixin requires that format.
  $btn-avry-sm-line-height: 1.17;
  $btn-avry-sm-letter-spacing: rem(0.5);

  /**
   * button-size
   *
   * @param {*} padding Y
   * @param {*} padding X
   * @param {*} Button Font Size
   * @param {*} Button Line Height
   * @param {*} Button Border Radius
   */
  @include button-size(
    $btn-avry-padding-y,
    $btn-avry-padding-x,
    $btn-avry-default-font-size,
    $btn-avry-default-line-height,
    $avry-industrial-btn-border-radius
  );
  @include transition($btn-transition);

  background-color: #fff;
  border: rem($avry-industrial-border-size) solid $industrial-gray;
  color: $industrial-gray;
  cursor: pointer;
  display: inline-block;
  font-weight: $btn-font-weight;
  position: relative;
  letter-spacing: $btn-avry-default-letter-spacing;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  p {
    margin: 0;
  }

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Button Selected, Hover State, and Focus State
  // TODO: No selected state for primary?
  // &.btn-selected, // TODO: This class is not valid in Industrial... confirm if it is `.selected`
  &:hover {
    background-color: #f1f1f1;
  }

  // Disabled State
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    background-color: #f1f1f1;
    opacity: 0.5;
    // From Bootstrap 4
    @include box-shadow(none);
  }

  // From Bootstrap 4
  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  // Large Button
  &.btn-lg {
    font-size: $btn-avry-lg-font-size;
    font-weight: bold;
    letter-spacing: $btn-avry-lg-letter-spacing;
    line-height: $btn-avry-lg-line-height;
    padding: $btn-avry-padding-y-lg $btn-avry-padding-x-lg;
  }

  // Small Button
  &.btn-sm {
    border-width: rem(1);
    font-size: $btn-avry-sm-font-size;
    letter-spacing: $btn-avry-sm-letter-spacing;
    line-height: $btn-avry-sm-line-height;
    padding: $btn-avry-padding-y-sm $btn-avry-padding-x-sm;
    text-transform: none;
  }

  // Make outline Buttons Transparent
  &.btn-outline-transparent {
    background: transparent !important;

    &:hover {
      opacity: 0.6 !important;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
// NOTE: From Bootstrap 4's (_buttons.scss)
// FIXME: Deprecated - Remove `a.btn-avry.disabled` and all its nested styles once verified not/no longer used.
a.btn-avry.disabled,
fieldset:disabled a.btn-avry {
  pointer-events: none;
}

//-// Primary - Solid - Gray
//-//
//-// Solid Gray Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry solid-gray {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.0-primary-button-solid-gray

// FIXME: Deprecated - Remove `.btn-avry.solid-gray` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.solid-gray {
  @include btn-action-solid($main-white, $industrial-gray, $industrial-hover-gray);
}

//-// Primary - Solid - Blue
//-//
//-// Solid Blue Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry solid-blue {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-solid-blue

// FIXME: Deprecated - Remove `.btn-avry.solid-blue` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.solid-blue {
  @include btn-action-solid($main-white, $industrial-blue, $industrial-hover-blue);
}

//-// Primary - Solid - Green
//-//
//-// Solid Green Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry solid-green {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-solid-green

// FIXME: Deprecated - Remove `.btn-avry.solid-green` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.solid-green {
  @include btn-action-solid($main-white, $industrial-green, $industrial-hover-green);
}

//-// Primary - Solid - Red
//-//
//-// Solid Red Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry solid-red {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-solid-red

// FIXME: Deprecated - Remove `.btn-avry.solid-red` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.solid-red {
  @include btn-action-solid($main-white, $industrial-red, $industrial-hover-red);
}

//-// Primary - Outline -Blue
//-//
//-// Outline Blue Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry outline-blue {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-outline-blue

// FIXME: Deprecated - Remove `.btn-avry.outline-blue` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.outline-blue {
  @include btn-action-outline($industrial-blue, $industrial-blue);
}

//-// Primary - Solid - Yellow
//-//
//-// Solid Yellow Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry solid-yellow {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-solid-yellow

// FIXME: Deprecated - Remove `.btn-avry.solid-yellow` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.solid-yellow {
  @include btn-action-solid($main-white, $industrial-yellow, $industrial-hover-yellow);
}

//-// Primary - Outline - Yellow
//-//
//-// Outline Yellow Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry outline-yellow {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-outline-yellow

// FIXME: Deprecated - Remove `.btn-avry.outline-yellow` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.outline-yellow {
  @include btn-action-outline($industrial-yellow, $industrial-yellow);
}

//-// Primary - Outline - Black
//-//
//-// Outline Black Button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry outline-black {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.primary-button-outline-black

// FIXME: Deprecated - Remove `.btn-avry.outline-black` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.outline-black {
  @include btn-action-outline($main-black, $main-black);
}

/* ==========================================================================
   INDUSTRIAL BUTTONS - SECONDARY
   ========================================================================== */

//-// Secondary - Default
//-//
//-// Base secondary button
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// .selected - Selected
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry secondary {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.secondary-0-button

// FIXME: Deprecated - Remove `.btn-avry.secondary` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.secondary {
  @include btn-action-outline($industrial-black, $industrial-medium-gray);
  // background-color: $main-white;

  // Selected
  &.selected {
    @include btn-action-outline($industrial-blue, $industrial-blue, $industrial-blue);
  }
}

//-// Secondary - Circle
//-//
//-// Creates a secondary circle button.
//-//
//-// .btn-lg - Large Size
//-// .selected - Selected
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry secondary rounded-circle {{modifier_class}}">XL</button>
//-//
//-// Styleguide industrial-buttons.secondary-button-circle

// FIXME: Deprecated - Remove `.btn-avry.rounded-circle` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.rounded-circle {
  padding: rem(11);

  &.btn-lg {
    padding: rem(16);
  }
}

/* ==========================================================================
   INDUSTRIAL BUTTONS - UTILITIES
   ========================================================================== */

//-// Utility - Side Left
//-//
//-// Pushes Button text to a side using padding, this prepares additional UI content to be placed on the right side (such as icons).
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// .selected - Selected
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry secondary side-left {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.utility-side-left

// FIXME: Deprecated - Remove `.btn-avry.side-left` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.side-left {
  // TODO: add official values once received from UX.
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 5);
  text-align: left;
}

//-// Utility - X-Right
//-//
//-// Adds an 'x' icon/text to the right of the button.
//-//
//-// **NOTE:** Pair this class with `.side-left` to have extra spacing on the right side to fit.
//-//
//-// .btn-sm - Small Size
//-// .btn-lg - Large Size
//-// :disabled - Disabled
//-// :active - Active State
//-// :hover - Hover State
//-// :focus - Focus State
//-//
//-// Markup:
//-// <button class="btn-avry secondary side-left x-right {{modifier_class}}">Button Text</button>
//-//
//-// Styleguide industrial-buttons.utility-x-right

// FIXME: Deprecated - Remove `.btn-avry.side-left.x-right` and all its nested styles once verified not/no longer used.
// NOTE: Use `.cta`
.btn-avry.side-left.x-right {
  &:after {
    font-family: avery-fonticon;
    content: '\e910';
    font-size: rem(10);
    display: inline-block;
    position: relative;
    // TODO: determine final intended spacing values.
    right: -(map-get($spacers, 5) - map-get($spacers, 2));
  }
}

.input-group {
  .btn.btn-avry {
    border-radius: rem(2);
  }
}
