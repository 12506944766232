//
// <section class="content-group-container">
//   <div class="content-group-block card-body">
//     <h3 class="content-group-title card-title">Login Information</h3>
//     <div class="content-group-details">
//       Hello Content
//     </div>
//   </div>
// </section>
//
.content-group-container {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
    max-width: none !important;
    padding-right: 0;
    padding-left: 0;
  }

  .content-group-block {
    background: $light-gray;

    @include section-container-spacing();
  }

  .content-group-title {
    color: $sub-gray; // substitute for #494949
  }

  .content-group-details {
    background: $main-white;
    border-radius: rem(5);
    padding: rem(30) rem(25);
  }

  .content-group-label {
    // TODO: Use actual Bold font
    font-weight: bold;
  }

  .content-group-detail-row {
    padding: rem(10);
  }
}
