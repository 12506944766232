// Industrial Header
//
// An ideal presentation of the sub-nav, top-main-header, and KSP grouped together.
//
// Markup:
// <div class="bottom-header-info m-0">
//   <div class="container container-fluid-sm-down">
//     <div class="row">
//       <div class="col">
//         <nav class="breadcrumb">
//           <a class="breadcrumb-item" href="#">Industrial</a>
//           <a class="breadcrumb-item" href="#">GHS &amp; Chemical Labels</a>
//           <a class="breadcrumb-item active-text-link">Pictogram</a>
//         </nav>
//       </div>
//     </div>
//   </div>
// </div>
// <section class="sub-menu-container bg-black border-bottom bdr-whte mb-0">
//   <div class="container container-fluid-sm-down">
//     <nav class="sub-menu smooth-scroll">
//       <div class="d-flex full-height align-items-center">
//         <a href="">
//           <span class="sub-menu-label h4 lh-0-9">GHS &amp; Chemical Labels</span>
//         </a>
//         <ul>
//           <li>
//             <a href="">Custom Labels</a>
//           </li>
//           <li>
//             <a href="">Blank Labels</a>
//           </li>
//           <li>
//             <a href="">GHS</a>
//           </li>
//           <li class="active">
//             <a href="">Pictogram</a>
//           </li>
//           <li>
//             <a href="">HMIS/NFPA</a>
//           </li>
//           <li>
//             <a href="">Hazardous Waste</a>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   </div>
// </section>
// <section class="avy-inds-top-main-header" style="">
//   <div class="container container-fluid-sm-down">
//     <div class="header-content text-center">
//       <h1 class="mb-4">Pictogram Labels</h1>
//       <p class="sh1">See safety. Think Avery.</p>
//     </div>
//   </div>
// </section>
// <section class="avy-inds-csc bg-inds-gray">
//   <div class="container container-fluid-sm-down">
//     <div class="row justify-content-center text-main-white text-uppercase fnt-sze-12">
//       <div class="col-auto">
//         <span class="font-weight-bold">
//           Call Us:
//         </span>
//         <span>
//           (888) 888-8888
//         </span>
//       </div>
//       <div class="col-8 col-sm-auto d-none d-sm-block">
//         <span class="font-weight-bold">
//           Office Hours:
//         </span>
//         <span class="d-none d-sm-inline d-md-none">
//           Mon to Fri 6am – 5pm pt, Sat 10am – 2pm PT
//         </span>
//         <span class="d-block d-sm-none d-md-inline">
//           Monday to Friday 6am – 5pm pt, Saturday 10am – 2pm PT
//         </span>
//       </div>
//     </div>
//   </div>
// </section>
// <section class="avry-ksp bg-white text-color-ind-gray border-top border-bottom bdr-mediumgray mb-0">
//   <div class="container container-fluid-sm-down">
//     <div class="row align-items-center text-center no-gutters">
//       <div class="col-12 col-sm-6 col-md-4">
//         <span class="fnt-sze-14 font-weight-bold lh-1-1 text-uppercase">
//           <i class="fa fa-check pr-2" aria-hidden="true"></i>
//           High Performance Materials
//         </span>
//       </div>
//       <div class="col-12 col-sm-6 col-md-4 d-none d-sm-block">
//         <span class="fnt-sze-14 font-weight-bold lh-1-1 text-uppercase">
//           <i class="fa fa-check pr-2" aria-hidden="true"></i>
//           Easy-to-Use Online Software
//         </span>
//       </div>
//       <div class="col-12 col-sm-6 col-md-4 d-none d-md-block">
//         <span class="fnt-sze-14 font-weight-bold lh-1-1 text-uppercase">
//           <i class="fa fa-check pr-2" aria-hidden="true"></i>
//           Low Investment &amp; Minimums
//         </span>
//       </div>
//     </div>
//   </div>
// </section>
//
// Styleguide industrial-header

// Breadcrumb
//
// An Industrial Breadcrumb.
//
// Markup:
// <div class="bottom-header-info m-0">
//   <div class="container container-fluid-sm-down">
//     <div class="row">
//       <div class="col">
//         <nav class="breadcrumb">
//           <a class="breadcrumb-item" href="#">Industrial</a>
//           <a class="breadcrumb-item" href="#">GHS &amp; Chemical Labels</a>
//           <a class="breadcrumb-item active-text-link">Pictogram</a>
//         </nav>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide industrial-header.0-breadcrumb

// Sub-Menu
//
// An Industrial Sub Menu Navigation.
//
// Markup:
// <section class="sub-menu-container bg-black border-bottom bdr-whte mb-0">
//   <div class="container container-fluid-sm-down">
//     <nav class="sub-menu smooth-scroll">
//       <div class="d-flex full-height align-items-center">
//         <a href="">
//           <span class="sub-menu-label h4 lh-0-9">GHS &amp; Chemical Labels</span>
//         </a>
//         <ul>
//           <li>
//             <a href="">Custom Labels</a>
//           </li>
//           <li>
//             <a href="">Blank Labels</a>
//           </li>
//           <li>
//             <a href="">GHS</a>
//           </li>
//           <li class="active">
//             <a href="">Pictogram</a>
//           </li>
//           <li>
//             <a href="">HMIS/NFPA</a>
//           </li>
//           <li>
//             <a href="">Hazardous Waste</a>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   </div>
// </section>
//
// Styleguide industrial-header.1-sub-menu

// Black Sub-Navigation
.sub-menu-container.bg-black {
  @include media-breakpoint-down(sm) {
    &:before {
      background: linear-gradient(to right, rgba($main-black,0) 0%, rgba($main-black,1) 100%) !important;
    }
  }
}

// Top Main Header
//
// A section that displays the page title (preferably the `<h1>`) with a background image.
//
// Markup:
// <section class="avy-inds-top-main-header" style="">
//   <div class="container container-fluid-sm-down">
//     <div class="header-content text-center">
//       <h1 class="mb-4">Pictogram Labels</h1>
//       <p class="sh1">See safety. Think Avery.</p>
//     </div>
//   </div>
// </section>
//
// Styleguide industrial-header.2-top-main-header

.avy-inds-top-main-header {
  max-width: 5000px;
  height: 160px;
  margin: 0 auto;
  // FIXME: Add final URL please..
  background-image: url('https://img.avery.com/f_auto,c_scale,w_768/web/industrial/products/ghs-labels/header-ghs-chemical-labels-mobile');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  color: $main-white;

  @include media-breakpoint-up(md) {
    height: 200px;
    background-image: url('https://img.avery.com/f_auto,c_scale,w_1200/web/industrial/products/ghs-labels/header-ghs-chemical-labels-desktop');
  }

  & > .container {
    height: 100%;
  }

  .header-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
