/* ==========================================================================
   TEXT LINKS
   ========================================================================== */

// Text Links
//
// By default, `<a>` tags do not have styles. Adding the `.text-link` class will apply a avery.com style to a link.
//
// .text-link - Applies the general style to the link.
// .text-link.active-text-link - Apply to links that are Active. Usually used in Breadcrumbs, Page Tab/Link, etc.
// .text-link.disabled-text-link - Apply to links to look Disabled. Usually used in Breadcrumbs.
// .text-decoration-none - Removes the underline of links. This is a native [Bootstrap class](https://getbootstrap.com/docs/4.3/utilities/text/#text-decoration)
// .text-link--right-arrow - Adds Arrow to the right
// .text-link--left-arrow - Adds Arrow to the left
// .text-link--right-info - Adds info icon to the Right
// .text-link--left-info - Adds info icon to the left
// .text-link--right-help - Adds info help to the Right
// .text-link--left-help - Adds info help to the left
//
// Markup:
// <a class="{{modifier_class}}">Sample Text Link</a>
//
// Styleguide typography.b-links

.text-link,
a.text-link:not([href]):not([tabindex]) {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #105BD2;
  cursor: pointer;
  display: inline;
  padding: 0;
  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: #094DB8;
  }

  body[data-avry-mode='industrial'] & {
    color: $industrial-blue;
    &:hover {
      color: $container-blue;
    }
  }
}

.active-text-link {
  color: $main-black !important;
  text-decoration: none !important;

  &:hover {
    color: $main-black !important;
    cursor: default !important;
  }
}

.disabled-text-link {
  color: $base-gray !important;
  text-decoration: none !important;

  &:hover {
    color: $base-gray !important;
    cursor: default !important;
  }
}

// FIXME: deprecate this class and use a more general "text decoration none" class...
// LEARN MORE: https://getbootstrap.com/docs/4.3/utilities/text/#text-decoration
.link-no-underline {
  text-decoration: none !important;
}

%icon-right {
  &:after {
    display: inline-block;
    font-family: avery-fonticon;
    line-height: 1;
    vertical-align: top;
    margin-left: rem(5);
    position: relative;
    top: rem(3);
  }
}

%icon-left {
  &:before {
    display: inline-block;
    font-family: avery-fonticon;
    line-height: 1;
    vertical-align: top;
    margin-right: rem(5);
    position: relative;
    top: rem(3);
  }
}

.text-link--right-arrow {
  @extend .text-link;
  @extend %icon-right;

  &:after {
    content: '\e92d';
  }
}

.text-link--left-arrow {
  @extend .text-link;
  @extend %icon-left;

  &:before {
    content: '\e92c';
  }
}

.text-link--right-info {
  @extend .text-link;
  @extend %icon-right;

  &:after {
    content: '\e92f';
  }
}

.text-link--left-info {
  @extend .text-link;
  @extend %icon-left;

  &:before {
    content: '\e92f';
  }
}

.text-link--right-help {
  @extend .text-link;
  @extend %icon-right;

  &:after {
    content: '\e92e';
  }
}

.text-link--left-help {
  @extend .text-link;
  @extend %icon-left;

  &:before {
    content: '\e92e';
  }
}

// Deprecated, use text-link--right-arrow instead
.icon-link-right-arrow {
  > a:after {
    font-family: avery-fonticon;
    content: '\e92d';
    display: inline-block;
    font-size: rem(18);
    line-height: 1.3;
    margin-left: rem(5);
    color: $bright-bold-blue;
    position: relative;
    top: rem(3);
  }

  > a:hover:after {
    color: $container-blue;
  }

  body[data-avry-mode='industrial'] & {
    color: $industrial-blue;
    &:hover {
      color: $container-blue;
    }
  }
}

// "Save for Later" & "Store Finder" link
// FIXME: These styles may no longer be used... Please Verify
.product-save-link,
.store-finder-link {
  padding: 0 rem(20);

  @include media-breakpoint-only(md) {
    padding: 0;
  }

  span {
    font-size: rem(15);
    color: $base-dark;
  }

  i {
    margin-right: rem(10);
    font-size: rem(24);
    color: $base-blue;
  }
}
