/* ==========================================================================
   MY ACCOUNT - Common NO PROJECTS Styles

   NOTE: Common styles in My Account Page(s) when no Projects, Files, etc. are not available or have not been created.
   ========================================================================== */

.my-account-no-saved-content {
  .top-header.header-content {
    @include section-container-spacing();
  }
}

.my-account-none-container {
  min-height: 245px;
  background: $light-gray;
  border-radius: rem(8);
  padding: rem(80) rem(20);
  margin-bottom: rem($grid-gutter-width);

  @include media-breakpoint-up(md) {
    min-height: 260px;
    padding: rem(150) rem(30);
  }

  .btn.btn-avry {
    min-width: 220px;
    margin-top: rem(20);

    @include media-breakpoint-up(md) {
      min-width: 250px;
    }
  }
}

.my-account-none-label {
  font-size: rem(30);
  line-height: 1.25;
  margin: 0;
  color: $deep-blue;

  @include media-breakpoint-up(md) {
    font-size: rem(35);
  }
}

.my-account-none-sub-label {
  font-size: rem(16);
  margin: 0;
  color: $deep-blue;
}
